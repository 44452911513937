import React from 'react'

import './font-awesome'

import Layout from '../layout/Layout'
import Routes from './routes'

const App = () => (
  <Layout>
    <Routes />
  </Layout>
)

export default App
