export const COMPANY_TYPES = [
  { value: 'general', label: 'General' },
  { value: 'vehicleRental', label: 'Vehicle Rental' },
  { value: 'transport', label: 'Transport' },
  { value: 'taxi', label: 'Taxi' }
]

// react-select uses it's own styling API:
// https://react-select.com/styles
export const selectStyles = {
  container: (provided) => ({
    ...provided,
    width: '100%',
    marginTop: '7px',
    marginBottom: '10px'
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: '5px',
    border: state.isDisabled
      ? '1px solid #F7F7F7'
      : state.isFocused ? '1px solid #F66C57' : '1px solid #B9B9B9',
    boxShadow: state.isFocused ? 0 : 0,
    '&:hover': {
      border: state.isFocused ? '1px solid #F66C57' : '1px solid #B9B9B9',
    }
  }),
  valueContainer: (provided) => ({
    ...provided,
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected
      ? '#F66C57'
      : state.isFocused
        ? '#F66C57'
        : null,
    color: state.isSelected
      ? '#ffffff'
      : state.isFocused
        ? '#ffffff'
        : '#000',
  })
}
