import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

import { Button } from '../common/'
import { cardown } from '../assets'
import { LIMIT } from '../root/action-types'
import useLimits from './useLimits'
import ModalWindow from './ModalWindow'

const title = {
  slots: 'You have used up all your free slots',
  time: 'Your free trial has now expired',
  unpaid: 'Payment is required to continue',
}

const CreditModal = () => {
  const { open, mode } = useSelector(store => store.layout.limitModal)
  const dispatch = useDispatch()
  const { push } = useHistory()
  useLimits()

  const handleClose = () => dispatch({ type: `${LIMIT}_CLOSE` })

  const handleContinue = () => {
    dispatch({ type: `${LIMIT}_CONTINUE` })
    push({ pathname: '/settings', hash: '#billing' })
    const scrollPane = document.getElementsByClassName('layout-content-pane')
    if (scrollPane && scrollPane.length > 0) scrollPane[0].scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <ModalWindow isOpen={open} closeModal={handleClose} className="credit-modal">
      <img src={cardown} alt="" />
      <div>
        <div className="modal-title">{title[mode]}</div>
        <div className="modal-subtext">To continue, please add your preferred payment details in the settings area.</div>
      </div>
      <Button onClick={handleContinue}>Continue</Button>
    </ModalWindow>
  )
}

export default CreditModal
