import React from 'react'
import styled from 'styled-components/macro'
import { AreaChart, Area, Tooltip } from 'recharts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { parseUKDate, useRefDimensions } from '../common/'
import { useChart, useChartMOT } from './useChart'
import SelectedDate from './SelectedDate'

const AverageWidget = props => {
  const data = useChart(props.field)
  const options = {
    changeTitle: "Change over 30 days",
    showDate: true,
    dataKey: "y"
  }
  const { ref, width } = useRefDimensions()

  return (
    <Styling ref={ref}>
      <RenderWidget
        {...props}
        {...data}
        {...options}
        width={width}
      />
    </Styling>
  )
}

export const AverageWidgetShow = props => {
  const data = useChartMOT(props.field)
  const options = {
    changeClasses: "nocolor",
    changeTitle: "Change between the last two MOTs",
    dataKey: "value"
  }

  return (
    <ShowStyling>
      <RenderWidget
        {...props}
        {...data}
        {...options}
      />
    </ShowStyling>
  )
}

const RenderWidget = props => (
  <>
    <h4>{props.title}</h4>
    <h1>{Math.floor(props.mean).toLocaleString()} {props.suffix}</h1>
    <div className="row">
      {props.showDate && <SelectedDate />}
      <PercentChange
        value={props.percentChange}
        title={props.changeTitle}
        classes={props.changeClasses}
      />
    </div>
    <AreaChart
      data={props.chart}
      width={props.width ? props.width - 30 : 340}
      height={50}
    >
      <Tooltip content={<CustomTooltip suffix={props.suffix} />} />
      <Area type="monotone" dataKey={props.dataKey} stroke={props.stroke} fill={props.fill} />
    </AreaChart>
  </>
)

const PercentChange = ({ value, title, classes }) => {
  if (!value) { return null }
  return (
    <PercentStyles className={`${value >= 0 ? 'up' : 'down'} ${classes || ''}`} title={title}>
      <FontAwesomeIcon icon={value >= 0 ? 'sort-up' : 'sort-down'} />
      <span>{Math.abs(value).toFixed(1)}%</span>
    </PercentStyles>
  )
}

const PercentStyles = styled.div`
  padding-left: 1rem;
  color: #4CB45B;
  &.up {
    color: crimson;
    svg {
      bottom: -0.2rem;
    }
  }
  &.nocolor { color: #696969 }
  cursor: help;
  svg {
    padding-right: 0.3rem;
    position: relative;
    bottom: 0.2rem;
  }
`

const CustomTooltip = ({ payload, suffix }) => {
  if (!payload || !payload[0]) { return null }
  return (
    <ToolStyles>
      <span>{parseUKDate(payload[0].payload.label).toLocaleDateString()}:</span>
      {`${payload[0].value} ${suffix}`}
    </ToolStyles>
  )
}

const ToolStyles = styled.div`
  background: white;
  padding: 1rem;
  border: 2px solid navajowhite;
  top: 50px;
  position: relative;
  span {
    padding-right: 1rem;
    font-weight: bold;
  }
`

const Styling = styled.div`
  background: white;
  padding: 1rem;
  flex: 1;
  box-shadow: 0 3px 6px rgba(0,0,0,0.05);
  overflow: hidden;
  h4 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
  }
  h1 {
    color: #633C76;
    margin-top: .5rem;
    margin-bottom: 0;
    font-weight: 400;
    @media (max-width: 1900px) {
      font-size: 1.5rem;
    }
  }
  .row {
    display: flex;
    padding-top: 0.5rem;
    @media (max-width: 1900px) {
      padding-top: 0.1rem;
    }
  }
  .recharts-wrapper {
    margin-top: .75rem;
    @media (max-width: 1900px) {
      margin-top: 0.25rem;
    }
  }
`

const ShowStyling = styled(Styling)`
  flex: 0;
  overflow: unset;
`

export default AverageWidget
