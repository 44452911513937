import React, { useEffect, useState, useCallback, useRef } from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useSelector } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isArray, isString } from 'lodash'

import { Loading, Conditional } from '../common/'
import { useDropbox } from './dropbox'
import Api from '../api/call'
import { ATTACHMENTS } from '../root/action-types'

export const useDropboxFiles = (paths) => {
  const { dropboxRequest, ready } = useDropbox()
  const { data: files } = useSelector(r => r.attachments.files)

  useEffect(() => {
    ready && paths.forEach(path => files[path] || dropboxRequest('filesDownload', { path }))
  }, [paths.join(), ready, files, dropboxRequest])

  return { files }
}

export const useAttachments = ({ parentType, parentId }) => {
  const { data, loading, errors } = useSelector(r => r.attachments.index)
  const { files } = useDropboxFiles(Object.values(data).map(a => a.dropboxId))

  useEffect(() => {
    Api({ request: ATTACHMENTS, params: { parentType, parentId } })
  }, [parentId, parentType])

  const attachments = Object.values(data)
    .map(d => ({ ...d, file: files[d.dropboxId] }))

  return { attachments, loading, errors }
}

const Attachment = ({ paths, onChange, image, readOnly }) => {
  const { files } = useDropboxFiles(paths)
  const add = (path) => onChange([...paths, path])
  const remove = (path) => onChange(paths.filter(p => p !== path))

  if (readOnly) { return <ReadOnly files={files} paths={paths} /> }

  return (
    <div className="attachment">
      <UploadDropzone add={add} image={image}>
        {paths.map((p, i) =>
          <Element key={i} file={files[p]} remove={remove} />
        )}
      </UploadDropzone>
    </div>
  )
}

// Only loads the file on demand
export const AttachmentLink = ({ path, children }) => {
  const { files } = useSelector(r => r.attachments)
  const [trigger, setTrigger] = useState(false)
  const file = files[path]
  const { dropboxRequest, ready } = useDropbox()

  const onTrigger = () => {
    if (trigger) {
      setTrigger(false)
      show()
    }
  }

  useEffect(onTrigger, [file])

  const link = useRef(null)
  const data = file && URL.createObjectURL(file.fileBlob)
  const filename = file && file.name

  const show = () => {
    if (file) {
      link.current.click()
    } else if (!trigger) {
      setTrigger(true)
      dropboxRequest('filesDownload', { path })
    }
  }

  if (!ready) { return <Loading /> }
  return (
    <span className="attachment-link" onClick={show}>
      <a ref={link} className="hidden" href={data} download={filename}> </a>
      {children}
    </span>
  )
}

const ReadOnly = ({ files, paths }) => (
  <div className="attachment read-only">
    {paths.map((p, i) => <Element key={i} file={files[p]} />)}
  </div>
)

const sanitizePaths = (paths) => {
  if (!paths) { return [] }
  if (isString(paths)) { return JSON.parse(paths) }
  if (isArray(paths)) { return paths }
  throw new Error(`Unsupported data passed to attachment: ${paths}`)
}

const Sanitizer = ({ paths, ...props }) => (
  <Attachment paths={sanitizePaths(paths)} {...props} />
)

export const Element = (props) => (
  props.file ? <Upload {...props} /> : <Uploading />
)

const Uploading = () => (
  <div className="element uploading">
    <Loading />
  </div>
)

const Upload = ({ file, remove }) => {
  const onClick = (e) => {
    remove(file.id)
    e.stopPropagation()
  }

  return (
    <ElementStyles className="element upload-complete">
      <Thumbnail file={file} />
      <Conditional if={remove}>
        <div className="remove-upload" onClick={onClick}>
          <FontAwesomeIcon icon="times-circle" size="2x" />
        </div>
      </Conditional>
      <div className="footer">
        {file.name}
      </div>
    </ElementStyles>
  )
}

const Thumbnail = ({ file }) => {
  const url = URL.createObjectURL(file.fileBlob)
  if (isImageFile(file.name)) {
    return <img src={url} alt={file.name} onClick={() => window.open(url)} />
  }
  return <FontAwesomeIcon icon="file" onClick={() => window.open(url)} size="6x" />
}

const DROP_TEXT = {
  active: 'Drop your image here...',
  inactive: 'Drag & drop your image here, or click to select from your computer (.PNG or .JPG Only)'
}

export const UploadButton = ({ update, ...props }) => {
  const { dropboxUpload, ready } = useDropbox()
  const [uploading, setUploading] = useState(false)
  const input = useRef(null)
  const upload = useCallback(f => {
    if (!validateFile(f[0])) { return }
    setUploading(true)
    dropboxUpload({ contents: f[0], path: `/uploads/${f[0].name}` }).then(response => {
      setUploading(false)
      update(response)
    })
  })

  return (
    <React.Fragment>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={input}
        onChange={e => upload(e.target.files)}
      />
      <button {...props} onClick={() => input.current.click()} disabled={!ready || uploading} />
    </React.Fragment>
  )
}

const UploadDropzone = ({ add, children, image }) => {
  const { dropboxUpload, ready } = useDropbox()
  const [uploading, setUploading] = useState(0)

  const onDrop = useCallback(files => {
    if (files.length > 1) { return alert('Please select a single file to upload') }
    files.forEach(f => {
      if (!validateFile(f)) { return }
      setUploading(u => u + 1)
      dropboxUpload({
        contents: f,
        path: `/uploads/${f.path}`
      }).then(response => {
        setUploading(u => u - 1)
        add(response.id)
      })
    })
  }, [add, dropboxUpload, image])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  if (!ready) { return <Loading /> }
  return (
    <div className="upload-dropzone" {...getRootProps()}>
      {children}
      <div className="element dropper">
        <input {...getInputProps()} />
        {uploading > 0 ? <Loading /> : <FontAwesomeIcon icon="file-upload" />}
        <p>{DROP_TEXT[isDragActive ? 'active' : 'inactive']}</p>
      </div>
    </div>
  )
}

const TWO_MEGABYTES = 2097152

// Match the end of the string for one of the extension option, case insensitive
const IMAGES = /(png|jpg|jpeg|svg|gif)$/i

const ALLOWED = /(png|jpg|jpeg|svg|gif|doc|docx|pdf|txt|rtf|odt|xls|xlsx)$/i

const isImageFile = (name) => {
  try {
    return !!name.match(IMAGES)
  } catch (e) { return false }
}

const isAllowedFile = (name) => {
  try {
    return !!name.match(ALLOWED)
  } catch (e) { return false }
}

const validateFile = (file) => {
  if (file.size > TWO_MEGABYTES) {
    alert('File size is limited to 2MB')
  } else if (!isAllowedFile(file.name)) {
    alert('File type is not permitted')
  } else {
    return true
  }
  return false
}

const ElementStyles = styled.div`
  border: 0.2rem solid #cdcdcd;
  width: fit-content;
  padding: 0.5rem;
  border-radius: 5px;;
  img, svg {
    max-height: 8rem;
    max-width: auto;
    cursor: pointer;
    padding-bottom: 0.5rem;
  }
  .footer {
    border-top: 0.2rem solid #cdcdcd;
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    max-width: fit-content;
  }
`

Sanitizer.propTypes = {
  paths: propTypes.any
}
Attachment.propTypes = {
  paths: propTypes.array,
  onChange: propTypes.func,
  image: propTypes.bool,
  readOnly: propTypes.bool
}
AttachmentLink.propTypes = {
  path: propTypes.string,
  children: propTypes.node
}
ReadOnly.propTypes = {
  paths: propTypes.array,
  files: propTypes.oneOfType([
    propTypes.object,
    propTypes.array
  ])
}
Element.propTypes = {
  file: propTypes.object
}
Upload.propTypes = {
  file: propTypes.object,
  remove: propTypes.func
}
UploadDropzone.propTypes = {
  add: propTypes.func,
  children: propTypes.node,
  image: propTypes.bool
}

export default Sanitizer
