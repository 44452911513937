import { get, omit, uniq } from 'lodash'
import { RiskAssessor } from './risk'

import {
  DASHBOARD,
  DASHBOARD_ADD_FILTER,
  DASHBOARD_ADD_DRIVER_FILTER,
  DASHBOARD_CLEAR_FILTER,
  DASHBOARD_CLEAR_DRIVER_FILTER,
  DASHBOARD_ASSIGN_DRIVERS,
  DASHBOARD_SET_DATE,
  CURRENT_USER,
  TOGGLE_ROW,
  TOGGLE_ALL,
  FLEET_SET_MODE,
  GROUP_SELECT,
  GROUPS_UNASSIGN,
  GROUPS_ASSIGN,
  GROUPS_DESTROY,
  TAG_SELECT,
  TAGS_ASSIGN,
  TAGS_DESTROY,
  FLEET,
} from '../root/action-types'

const INITIAL = {
  vehicles: [],
  drivers: {},
  matchedDrivers: [],
  driverFilters: {},
  filters: {},
  matched: [],
  mode: 'make',
  date: {},
  riskAssessor: RiskAssessor({}),
  selected: [],
  isSelectable: false,
  groups: {},
  tagSelected: '',
}

const filter = ({ filters, date, vehicles, groups, groupSelected, fleetMode, tags, tagSelected }) => {
  let matched = vehicles
  if (groupSelected && fleetMode == 'ADD_TO_GROUP') {
    matched = matched.filter(m => !(groups[m.ovid] || []).includes(groupSelected))
  }
  if (groupSelected && groupSelected != 'root' && fleetMode != 'ADD_TO_GROUP') {
    matched = matched.filter(m => (groups[m.ovid] || []).includes(groupSelected))
  }
  if (tagSelected) {
    matched = matched.filter(m => (tags[m.ovid] || []).includes(tagSelected))
  }
  if (date.from) {
    matched = matched.filter(m => new Date(m.addedAt) >= date.from)
  }
  if (date.to) {
    matched = matched.filter(m => new Date(m.addedAt) <= date.to)
  }
  if (filters.motExpiryDate) {
    matched = matched.filter(m => {
      const motExpiry = get(m, 'motExpiryDate')
      if (motExpiry === null) return false

      return new Date(motExpiry) < filters.motExpiryDate
    })
  }
  Object.entries(omit(filters, ['motExpiryDate'])).forEach(([k, v]) => {
    matched = matched.filter(m => String(m[k]).toLowerCase() == String(v).toLowerCase())
  })
  return matched
}

const MODE_PRECEDENCE = ['make', 'model', 'year', 'colour', 'fuelType', 'transmission']

const mode = (filters) => (
  MODE_PRECEDENCE.find(m => !filters[m]) || MODE_PRECEDENCE[0]
)

const filterDrivers = (filters, drivers, riskAssessor) => {
  let matched = Object.values(drivers)
  if (filters.risk) {
    matched = matched.filter(d => filters.risk == riskAssessor.driver(d))
  }
  if (filters.expiry) {
    matched = matched.filter(d => new Date(d.validTo) < filters.expiry)
  }

  return matched
}

const selectRows = (id, selected) => {
  if (selected.includes(id)) {
    selected.splice(selected.indexOf(id), 1)
  } else {
    selected.push(id)
  }
}

const selectAll = (all, matched) => (
  all ? uniq(matched.map(m => m.ovid)) : []
)

export const dashboard = (state = INITIAL, action) => {
  switch (action.type) {
    case `${CURRENT_USER}_SUCCESS`: {
      return { ...state, riskAssessor: RiskAssessor(action.response.data.organisation) }
    }
    case `${DASHBOARD}_START`: {
      return { ...state, loading: true }
    }
    case `${DASHBOARD}_SUCCESS`: {
      const { vehicles, drivers } = action.response.data
      return {
        ...state,
        loading: false,
        vehicles,
        drivers,
        matched: vehicles,
        matchedDrivers: Object.values(drivers)
      }
    }
    case `${DASHBOARD_ASSIGN_DRIVERS}_SUCCESS`: {
      const vehicles = [...state.vehicles]
      const { vehicleId, drivers } = action.response.data
      vehicles.find(v => v.id == vehicleId).drivers = drivers
      return { ...state, vehicles }
    }
    case `${DASHBOARD}_FAILURE`: {
      return { ...state, loading: false, ...action.response.data }
    }
    case DASHBOARD_ADD_FILTER: {
      const filters = { ...state.filters, ...action.filter }
      return {
        ...state,
        filters,
        matched: filter({ ...state, filters }),
        mode: mode(filters)
      }
    }
    case TAG_SELECT: {
      const tagSelected = action.id === state.tagSelected ? '' : action.id
      return {
        ...state,
        tagSelected,
        matched: filter({ ...state, tagSelected }),
      }
    }
    case GROUP_SELECT: {
      return {
        ...state,
        groupSelected: action.id,
        matched: filter({ ...state, groupSelected: action.id }),
      }
    }
    case DASHBOARD_ADD_DRIVER_FILTER: {
      const driverFilters = { ...state.driverFilters, ...action.filter }
      return {
        ...state,
        driverFilters,
        matchedDrivers: filterDrivers(driverFilters, state.drivers, state.riskAssessor)
      }
    }
    case DASHBOARD_CLEAR_FILTER: {
      const filters = { ...state.filters }
      delete filters[action.name]
      return {
        ...state,
        filters,
        matched: filter({ ...state, filters }),
        mode: mode(filters)
      }
    }
    case DASHBOARD_CLEAR_DRIVER_FILTER: {
      const driverFilters = { ...state.driverFilters }
      delete driverFilters[action.name]
      return {
        ...state,
        driverFilters,
        matchedDrivers: filterDrivers(driverFilters, state.drivers, state.riskAssessor)
      }
    }
    case DASHBOARD_SET_DATE: {
      return {
        ...state,
        date: action.date,
        matched: filter({ ...state, date: action.date }),
      }
    }
    case FLEET_SET_MODE: {
      return {
        ...state,
        isSelectable: !!action.mode,
        fleetMode: action.mode,
        matched: filter({ ...state, fleetMode: action.mode }),
        selected: [],
      }
    }
    case `${GROUPS_ASSIGN}_SUCCESS`:
    case `${TAGS_ASSIGN}_SUCCESS`:
    case `${GROUPS_UNASSIGN}_SUCCESS`:
    case `${FLEET}_SUCCESS`: {
      return {
        ...state,
        groups: action.response.data.vehicleGroups,
        tags: action.response.data.vehicleTags,
        matched: filter({
          ...state,
          groups: action.response.data.vehicleGroups,
          tags: action.response.data.vehicleTags
        }),
      }
    }
    case TOGGLE_ROW: {
      const selected = [...state.selected]
      selectRows(action.id, selected)
      return { ...state, selected }
    }
    case TOGGLE_ALL: {
      const selected = selectAll(action.all, state.matched)
      return { ...state, selected }
    }
    case `${GROUPS_DESTROY}_SUCCESS`: {
      const destroyed = Object.keys(action.response.data)
      const groupSelected = destroyed.includes(state.groupSelected) ? 'root' : state.groupSelected
      const groups = omit(state.groups, destroyed)

      return { ...state, groupSelected, groups }
    }
    case `${TAGS_DESTROY}_SUCCESS`: {
      if (Object.keys(action.response.data).includes(state.tagSelected)) {
        return { ...state, tagSelected: '' }
      }
      return state
    }
    default: {
      return state
    }
  }
}

export default dashboard
