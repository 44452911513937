import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { capitalize } from 'lodash'

import { DASHBOARD_ADD_FILTER } from '../root/action-types'

import { Styling, COLORS, Row, Chart } from './DonutChart'

const breakdown = (vehicles, mode) => {
  const result = []
  const total = vehicles.length
  const byMan = {}

  vehicles.forEach(v => {
    v[mode] = capitalize(String(v[mode] || `(no ${mode})`).toLowerCase())
  })
  vehicles.forEach(v => {
    byMan[v[mode]] = byMan[v[mode]] || []
    byMan[v[mode]].push(v)
  })
  Object.values(byMan).forEach(man => {
    result.push({
      name: man[0][mode],
      number: man.length,
      percent: (man.length / total) * 100
    })
  })
  result.sort((a, b) => b.number - a.number)

  if (result.length > 11) {
    const other = { name: 'Other', number: 0 }
    result.splice(10).forEach(m => { other.number += m.number })
    other.percent = (other.number / total) * 100
    result.push(other)
  }
  result.forEach((r, i) => { r.color = COLORS[i] })

  return result
}

const Manufacturers = () => {
  const { matched, mode } = useSelector(r => r.dashboard)
  const dispatch = useDispatch()
  const data = breakdown(matched, mode)
  const addFilter = (name) => {
    if (name == 'Other' || !name) { return }
    dispatch({ type: DASHBOARD_ADD_FILTER, filter: { [mode]: name } })
  }

  return (
    <Styling>
      <h2>Registered Manufacturers</h2>
      <div className="content">
        <div className="list">
          {data.map((d, i) =>
            <Row key={i} {...d} suffix="Vehicle" onClick={() => addFilter(d.name)} />
          )}
        </div>
        <Chart data={data} addFilter={addFilter} />
      </div>
    </Styling>
  )
}

export default Manufacturers
