import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { get } from 'lodash'

import CreateAccount from './CreateAccount'
import PaymentDetails from './PaymentDetails'
import { SignupSuccess } from '.'

// Images:
import { createCar } from '../assets/'

export const SignupContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  background: #F5F6F9 0% 0% no-repeat padding-box;

  .create-car {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;

    img {
      display: block;
      width: 70%;
      transform: translateX(150%);
      opacity: 0;
      animation: slideLeft .5s .15s ease-in-out forwards;
    }

    @media screen and (max-width: 1279px) {
      display: none;
    }

    @media screen and (min-width: 1280px) and (max-width: 1919px) {
      flex: .7;
    }
  }
`
export const Smallprint = styled.span`
  display: inline-block;
  padding: 0 0.5rem 2rem 0.5rem;
  font-size: 0.9rem;

  @media screen and (max-width: 767px) {
    padding: 0 0 1rem 0;
  }

  > a { margin-left: .5rem }
`

const SignupFormContainer = styled.div`
  text-align: center;
  flex: 1;
  padding: 0 7vw;

  @media screen and (max-width: 767px) {
    padding: 0 5% 2rem 5%;
  }

  @media screen and (min-width: 767px) and (max-width: 1919px) {
    padding: 0 5% 2rem 5%;
  }

  > h1 {
    color: black;
    margin-bottom: 1.1rem;
    font-weight: 400;
    font-size: 2.2rem;
    text-align: center;
  }

  .signup-recaptcha {
    width: max-content;
    padding: 0.5rem 0;
    margin: 0 auto;
  }

  .terms-text {
    font-size: 0.8rem;
    padding: 1.25rem 0 .5rem 0;

    > span {
      color: ${props => props.theme.primaryColor};
      cursor: pointer;
    }
  }
`
function Signup (props) {
  const initialStage = get(props, 'initialStage', 'CREATE_ACCOUNT')
  const [stage, setStage] = useState(initialStage)

  const renderChild = () => {
    switch (stage) {
      case 'CREATE_ACCOUNT':
        return <CreateAccount setStage={setStage} />
      case 'PAYMENT_DETAILS':
        return (
          <PaymentDetails
            setStage={setStage}
            smallprint={get(props, 'smallprint')}
          />
        )
      case 'SIGNUP_SUCCESS':
        return <SignupSuccess />
      default:
        return null
    }
  }

  return (
    <SignupContainer className="signup-container">
      <div className="create-car">
        <img src={createCar} alt="Car" />
      </div>

      <SignupFormContainer>
        {renderChild()}
      </SignupFormContainer>
    </SignupContainer>
  )
}

export default Signup
