import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { difference, uniq } from 'lodash'
import propTypes from 'prop-types'

import ModalWindow from '../layout/ModalWindow'
import Api from '../api/call'
import { DASHBOARD, DASHBOARD_ASSIGN_DRIVERS } from '../root/action-types'
import { ModalContainer } from '../dashboard/DriverSelect'
import { Conditional, VerticalSelect } from '../common'

export const LinkVehicleWrapper = ({ id, className, children }) => {
  const { value, options, update } = useLinkVehicles(id)
  const [active, setActive] = useState(false)
  const toggle = () => setActive(!active)

  return (
    <>
      <div className={className || ''} onClick={toggle}>
        {children}
      </div>
      <Conditional if={active}>
        <Modal id={id} closeModal={toggle} value={value} options={options} update={update} />
      </Conditional>
    </>
  )
}
LinkVehicleWrapper.propTypes = {
  id: propTypes.string,
  className: propTypes.string,
  children: propTypes.node
}

const vehicleOption = (v) => (
  { value: v.ovid, label: `${v.registration} - ${v.make} ${v.model}`, details: v }
)

export const useLinkVehicles = (id) => {
  const { vehicles } = useSelector(r => r.dashboard)
  const value = vehicles.filter(v => v.drivers.includes(id)).map(vehicleOption)
  const options = vehicles.map(vehicleOption).filter(o => !value.includes(v => v.value == o.value))
  // This is the reverse of the DriverCell update function in DriverSelect.jsx.
  // Find which OrganisationVehicle id has been added/removed, then add/remove
  // the driver id for the current row to/from that vehicle. Here, the driver
  // id is `id` from `row` in props.
  const update = vs => {
    const ovids = value.map(v => v.value)
    let ovid, drivers
    if (vs.length >= ovids.length) {
      // added a vehicle
      ovid = difference(vs, ovids)[0]
      drivers = uniq([...vehicles.find(v => v.ovid === ovid).drivers, id])
    } else {
      // removed a vehicle
      ovid = difference(ovids, vs)[0]
      drivers = vehicles.find(v => v.ovid === ovid).drivers.filter(did => did !== id)
    }
    Api({ request: DASHBOARD_ASSIGN_DRIVERS, data: { ovid, drivers } })
  }

  return { value, options, update }
}

const FieldDisplay = ({ value }) => {
  if (value.length == 0) {
    return <span className="not-applicable">N/A</span>
  }
  if (value.length == 1) {
    const { registration, make, model } = value[0].details
    return (
      <>
        <span className="registration">{registration}</span>
        <span>{make} {model}</span>
      </>
    )
  }
  return <span>{value.length} Vehicles</span>
}

export const LinkVehicleField = ({ id }) => {
  const { value, options, update } = useLinkVehicles(id)
  const [active, setActive] = useState(false)
  const toggle = () => setActive(!active)

  useEffect(() => {
    Api({ request: DASHBOARD })
  }, [])

  return (
    <>
      <div className="right" onClick={toggle}>
        <FieldDisplay value={value} />
        <span className="edit">Edit</span>
      </div>
      <Conditional if={active}>
        <Modal id={id} closeModal={toggle} value={value} options={options} update={update} />
      </Conditional>
    </>
  )
}
LinkVehicleField.propTypes = {
  id: propTypes.string
}

const Modal = ({ closeModal, value, options, update }) => {
  return (
    <ModalWindow isOpen closeModal={closeModal} className="vehicle-modal">
      <ModalContainer>
        <h2>Link Vehicles</h2>
        <VerticalSelect
          value={value}
          options={options}
          onChange={update}
        />
      </ModalContainer>
    </ModalWindow>
  )
}
