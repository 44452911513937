import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Api, { isAuthenticated } from '../api/call'
import { LOGIN, LOGIN_RESET_STORE, PASSWORD_RESET, PASSWORD_CHANGE } from '../root/action-types'
import history from '../root/browser-history'
// Components:
import { Field, useForm, Status, Loading, Conditional, PasswordField } from '../common/'
// Images:
import { logo, car, envelope, checkCircle } from '../assets/'

import './login.scss'

const LoginManager = () => {
  const { loading, reset, errors } = useSelector(r => r.users.login)
  const error = errors && errors.join(', ')
  const dispatch = useDispatch()
  const [mode, setMode] = useState('LOGIN')
  const [email, setEmail] = useState('')

  const forgot = () => {
    dispatch({ type: LOGIN_RESET_STORE })
    setMode('FORGOT')
  }
  const login = () => setMode('LOGIN')
  const emailReset = (data) => {
    Api({ request: PASSWORD_RESET, data })
    setMode('EMAILED')
    setEmail(data.email)
  }

  if (isAuthenticated()) { return <Redirect to={window.logoutLocation || '/'} /> }

  if (mode == 'FORGOT' || (mode == 'EMAILED' && !reset)) {
    return <Forgot login={login} emailReset={emailReset} error={error} />
  }
  if (mode == 'EMAILED') {
    return (
      <Emailed
        email={email}
        login={login}
        forgot={forgot}
        loading={loading}
        reset={reset}
        error={error}
      />
    )
  }

  return <Login error={error} forgot={forgot} loading={loading} />
}

const BLANK_LOGIN = { email: '', password: '' }

export const Login = ({ error, forgot, loading }) => {
  const { form, inputOnChange } = useForm(BLANK_LOGIN)

  const sessionLogin = () => {
    Api({ request: LOGIN, data: form })
  }
  const onKeyPress = ({ key }) => {
    key === 'Enter' && form.password && sessionLogin()
  }

  return (
    <Wrapper error={error}>
      <h1>Login to Vehelix</h1>
      <div className="sub-header">
        New user? <Link to="/signup">Sign up here</Link>
      </div>
      <div className="login fields">
        <EmailField
          error={error}
          onChange={inputOnChange}
          value={form.email}
          disabled={loading}
        />
        <PasswordField
          name="password"
          error={error}
          onChange={inputOnChange}
          value={form.password}
          onKeyPress={onKeyPress}
        />
      </div>

      <button
        className="action-button"
        onClick={sessionLogin}
        disabled={!form.email || !form.password}
      >
        Login
      </button>
      <div className="change">
        <span onClick={forgot}>Forgot password?</span>
      </div>
    </Wrapper>
  )
}
Login.propTypes = {
  error: propTypes.string,
  forgot: propTypes.func,
  loading: propTypes.bool
}

export const Wrapper = ({ error, children }) => (
  <div className="login-wrapper">
    <nav className="header">
      <img className="logo" src={logo} alt="Vehelix logo" />
    </nav>
    <div className="car">
      <img src={car} alt="Car" />
    </div>
    <div className={`main ${error ? 'error' : ''}`}>
      <Status isError={!!error}>
        {error}
      </Status>
      {children}
    </div>
  </div>
)
Wrapper.propTypes = {
  error: propTypes.string,
  children: propTypes.node,
}

const EmailField = ({ error, ...props }) => (
  <Field label="Email" className="block" error={!!error}>
    <input
      type="email"
      name="email"
      className="underline"
      spellCheck="false"
      autoComplete="off"
      {...props}
    />
  </Field>
)
EmailField.propTypes = {
  error: propTypes.string
}

export const Forgot = ({ login, emailReset, error }) => {
  const { form, inputOnChange } = useForm({ email: '' })
  const [validation, setValidation] = useState('')

  const email = () => {
    if (form.email.match(/@/)) {
      emailReset(form)
    } else {
      setValidation('Please enter a valid email address')
    }
  }
  const onKeyPress = ({ key }) => {
    key === 'Enter' && email()
  }

  return (
    <Wrapper error={error || validation}>
      <h1>Forgot your password?</h1>
      <div className="sub-header">
        Enter your email address and we will send you instructions to reset your password
      </div>
      <div className="fields">
        <EmailField
          error={error || validation}
          onChange={inputOnChange}
          value={form.email}
          onKeyPress={onKeyPress}
        />
      </div>
      <button
        className="action-button"
        onClick={email}
        disabled={!form.email}
      >
        Reset your password
      </button>
      <BackToLogin login={login} />
    </Wrapper>
  )
}
Forgot.propTypes = {
  login: propTypes.func,
  emailReset: propTypes.func,
  error: propTypes.string
}

const BackToLogin = ({ login }) => (
  <div className="change">
    <span onClick={login}>
      <FontAwesomeIcon icon="caret-square-left" />
      Back
    </span>
  </div>
)
BackToLogin.propTypes = {
  login: propTypes.func
}

export const Emailed = ({ login, forgot, email, loading, reset, error }) => (
  <Wrapper error={error}>
    <img className="envelope" src={envelope} alt="Email Sent" />
    <h1>Check your email</h1>
    <div className="fields emailed">
      <Conditional if={reset}>
        <div className="success">
          <h4>We&apos;ve sent an email to <span className="email">{email}</span></h4>
          Click the link in the email to reset your password
          <br /><br />
          If you don&apos;t see the email, check other places it might be,
          like your junk, spam, social, or other folders.
          <br /><br />
          Didn&apos;t receive an email? <span className="resend" onClick={forgot}>Resend email</span>
        </div>
      </Conditional>
      <Conditional if={loading}>
        <Loading />
      </Conditional>
    </div>
    <BackToLogin login={login} />
  </Wrapper>
)
Emailed.propTypes = {
  login: propTypes.func,
  forgot: propTypes.func,
  loading: propTypes.bool,
  reset: propTypes.bool,
  error: propTypes.string,
  email: propTypes.string
}

const ChangeSuccess = ({ confirming }) => (
  <Wrapper>
    <div className="fields main change-success">
      <img src={checkCircle} alt="Account Created" className="check-circle" />
      <h1>Password {confirming ? 'set' : 'updated'}</h1>
      <div className="success">
        Your password has been successfully {confirming ? 'set' : 'changed'}, please click the link
        below to login.
      </div>
    </div>
    <button onClick={() => history.push('/login')} className="action-button">Login</button>
  </Wrapper>
)

export const PasswordChange = ({ match: { params: verification } }) => {
  const { loading, success, errors } = useSelector(r => r.users.change)
  const error = errors && errors.join(', ')
  const { form, inputOnChange } = useForm({ password: '', password_confirmation: '' })

  const action = () => {
    Api({ request: PASSWORD_CHANGE, data: { ...form, ...verification } })
  }
  const onKeyPress = ({ key }) => {
    key === 'Enter' && action()
  }

  const confirming = String(window.location.pathname).startsWith('/user_confirm')

  if (success) { return <ChangeSuccess confirming={confirming} /> }

  return (
    <Wrapper error={error}>
      <div className="fields change-password">
        <h1>
          {confirming ? 'Set' : 'Reset'} your password
        </h1>
        <div className="sub-header">
          Please enter your new password, confirm, and click "{confirming ? 'Set' : 'Change'} Password"
        </div>
        <Conditional if={loading}>
          <Loading />
        </Conditional>
        <PasswordField
          error={error}
          onChange={inputOnChange}
          name="password"
          value={form.password}
          disabled={loading}
          label="New Password"
        />
        <PasswordField
          error={error}
          onChange={inputOnChange}
          name="password_confirmation"
          value={form.password_confirmation}
          disabled={loading}
          onKeyPress={onKeyPress}
          label="Confirm Password"
        />
      </div>
      <button
        className="action-button"
        onClick={action}
        disabled={!form.password || !form.password_confirmation}
      >
        {confirming ? 'Set' : 'Change'} Password
      </button>
      <div className="change">
        <Link to="/login">
          <FontAwesomeIcon icon="caret-square-left" />
          Back to login
        </Link>
      </div>
    </Wrapper>
  )
}
PasswordChange.propTypes = {
  match: propTypes.object, // from react router
  params: propTypes.object
}

export default LoginManager
