export const DESCRIPTIONS = {
  AM: 'Mopeds',
  P: 'Mopeds',
  Q: 'Mopeds',
  A1: 'Motorcycles',
  A2: 'Motorcycles',
  A: 'Motorcycles',
  B1: 'Light vehicles and quad bikes',
  B: 'Cars',
  BE: 'Cars',
  C1: 'Medium-sized vehicles',
  C1E: 'Medium-sized vehicles',
  C: 'Large vehicles',
  CE: 'Large vehicles',
  D1: 'Minibuses',
  D1E: 'Minibuses',
  D: 'Buses',
  DE: 'Buses',
  F: 'Agricultural tractor',
  G: 'Road roller',
  H: 'Tracked vehicles',
  K: 'Mowing machine or pedestrian-controlled vehicle',
  L: 'Electrically-propelled vehicle',
  M: 'Trolley vehicles',
  N: 'Exempt from duty',
}
