import React from 'react'
import Loader from 'react-loader-spinner'
import styled from 'styled-components/macro'

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

export const Loading = () => (
  <Container>
    <Loader type="Rings" color="#F66C57" height={80} width={80} />
  </Container>
)

export const LoadingAbsolute = () => (
  <AbsoluteContainer>
    <Loader type="Rings" color="#F66C57" height={80} width={80} />
  </AbsoluteContainer>
)

export const Loaded = ({ loading, children }) => (
  loading ? <Loading /> : children
)

const Container = styled.div`
  display: flex;
  justify-content: center;
`
const AbsoluteContainer = styled(Container)`
  position: absolute;
  left: calc(50% - 40px);
  top: calc(50% - 40px);
  z-index: 1000;
`
