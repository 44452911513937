import React from 'react'
import { startCase } from 'lodash'

const Column = ({ accessor, flex, label }) => (
  <span
    className={'table header column flex-' + flex || 1}
    key={accessor + 'label'}
  >{label || startCase(accessor)}
  </span>
)

const Columns = ({ columns, checkbox }) => (
  <div className='table header'>
    {checkbox ? <Column {...checkbox} label={checkbox.label() || " "} /> : null}
    {columns.map(c => <Column {...c} key={c.accessor} />)}
  </div>
)

export default Columns
