import React, { useEffect } from 'react'
import styled from 'styled-components/macro'

import Api from '../api/call'
import { DASHBOARD } from '../root/action-types'
import { SelectAll, SelectCell, GroupsCell, TagsCell } from '../fleet/Table'
import { useWindowDimensions, Conditional } from '../common/'

import Sidebar from '../fleet/Sidebar'
import Manufacturers from './Manufacturers'
import AverageWidget from './AverageWidget'
import VehicleReminders from './VehicleReminders'
import Table from './Table'
import Filters from './Filters'

const Dashboard = () => (
  <GenericDashboard
    TopLeft={Manufacturers}
    Right={Averages}
    Bottom={VehicleReminders}
    Table={Table}
    request={DASHBOARD}
    scroll={false}
    id="vehicles"
  />
)

const ADDITIONAL_COLUMNS = [
  { accessor: 'groups', default: true, Component: GroupsCell },
  { accessor: 'tags', Component: TagsCell, default: true, flex: 2 },
]

const CHECKBOX = {
  accessor: 'id',
  label: SelectAll,
  className: 'checkbox',
  Component: SelectCell,
  flex: 0
}

export const FleetDashboard = () => (
  <GenericDashboard
    TopLeft={Manufacturers}
    Right={Averages}
    Bottom={VehicleReminders}
    Table={Table}
    SideTable={Sidebar}
    request={DASHBOARD}
    additionalColumns={ADDITIONAL_COLUMNS}
    checkbox={CHECKBOX}
    scroll={true}
    id="fleet"
  />
)

const LARGE = 1250
const MEDIUM = 1250

export const GenericDashboard = ({ TopLeft, Right, Bottom, Table, SideTable, request, mode, ...props }) => {
  useEffect(() => Api({ request }), [])
  const windim = useWindowDimensions()

  return (
    <Styling>
      <Filters mode={mode} />
      <div className="top">
        <div className="left">
          <TopLeft />
        </div>
        <div className="right">
          <Conditional if={windim.width >= MEDIUM}>
            <div className="double">
              <Right />
            </div>
          </Conditional>
          <Conditional if={windim.width >= LARGE}>
            <div className="tr-bottom">
              <Bottom />
            </div>
          </Conditional>
        </div>
      </div>
      <div className="middle">
        <Conditional if={windim.width < MEDIUM}>
          <Right />
        </Conditional>
        <Conditional if={windim.width < LARGE}>
          <Bottom />
        </Conditional>
      </div>
      <div className="bottom">
        {SideTable && <SideTable />}
        <div className="table-wrapper">
          <Table {...props} sideTable />
        </div>
      </div>
    </Styling>
  )
}

const Averages = () => (
  <React.Fragment>
    <AverageWidget
      field="mileage"
      title="Average Mileage"
      suffix="miles"
      stroke="#FE9C31"
      fill="#FFF5e9"
    />
    <AverageWidget
      field="co2Emissions"
      title="Average CO2 Emissions"
      suffix="tonnes of CO2"
      stroke="#E75C98"
      fill="#FDEEEF"
    />
  </React.Fragment>
)
const Styling = styled.div`
  background: #F5F6F9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .top {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    .left {
      flex-grow: 1;
      padding-top: 1rem;
      padding-bottom: 2rem;
      max-width: calc(50% - 1rem);
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      padding-top: 1rem;
      padding-bottom: 2rem;
      max-width: calc(50% - 1rem);

      .double {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 2rem;
      }
    }
  }
  .middle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 2rem;
    gap: 2rem;
    max-width: 48rem;

    &:empty { display: none;  }
    display: flex;
    flex-direction: row;
    gap: 2rem;

    .table-wrapper {
      flex-grow: 1;
      padding-right: 4rem;
      max-width: calc(100% - 4rem);
      overflow: auto;
    }
  }

  .bottom {
    display: flex;

    .table-wrapper {
      flex-grow: 1;
      max-width: 100%;
      overflow: auto;
    }
    .side-table {
      padding-right: 2rem;
    }
  }

  h2 {
    font-size: 22px;
    color: #633C76;
    font-weight: 400;

    @media (max-width: 1900px) {
      font-size: 17.6px;
    }
  }

  .cell {
    &.pointer { cursor: pointer }

    > div.tags-plus {
      border: 1px solid #8D8D8D;
      border-radius: 3px;
      height: 20px;

      &::before {
        content: '+';
        font-family: monospace;
        font-size: 1.25rem;
        line-height: 20px;
        color: #8D8D8D;
        padding: 0.25rem;
      }
    }

    > div.primary-tag {
      background: #EFF1F6;
      margin-left: 0.4rem;
      padding: 0.6rem 1rem;
      border-radius: 3px;
    }

    > div.add-tags, div.secondary-tags-count {
      font-style: italic;
      margin-left: 0.4rem;
      color: #8D8D8D;
    }
  }
`

export default Dashboard
