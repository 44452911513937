import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import { startCase, capitalize, invoke } from 'lodash'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { VEHICLES_TABLE_REMOVE } from '../root/action-types'
import { StyledTable, prettyNumber } from '../common'

const DataTable = () => {
  const { data } = useSelector(r => r.vehicles.table)
  const columns = Object.keys(ATTRIBUTES)
  const dispatch = useDispatch()
  const deleteRow = (registration) => {
    dispatch({ type: VEHICLES_TABLE_REMOVE, registration })
  }

  return (
    <Wrapper>
      <StylishTable>
        <Head columns={columns} />
        <tbody>
          {Object.entries(data).map(([reg, data]) =>
            <Row key={reg} reg={reg} data={data} columns={columns} deleteRow={deleteRow} />
          )}
        </tbody>
      </StylishTable>
    </Wrapper>
  )
}

const YesNo = ({ value, children }) => (
  <span className={`yes-no ${value ? 'yes' : 'no'}`}>
    <FontAwesomeIcon icon={['fas', value ? 'check-circle' : 'times-circle']} />
    {children}
  </span>
)
YesNo.propTypes = {
  value: propTypes.bool,
  children: propTypes.node
}

const taxed = (v) => (
  <YesNo value={v}>Taxed</YesNo>
)

const mot = (v) => (
  <YesNo value={v}>MOT</YesNo>
)

const ATTRIBUTES = {
  registration: { name: 'VRM' },
  make: { format: capitalize },
  model: { format: capitalize },
  colour: { format: capitalize },
  mileage: { format: prettyNumber },
  fuelType: { format: capitalize },
  transmission: { format: capitalize },
  taxed: { format: taxed },
  mot: { name: 'MOT', format: mot }
}

const TEMPLATE = new Blob(
  [
    "\ufeff",
    "Vehicle Registration"
  ]
)
const TEMPLATE_URL = URL.createObjectURL(TEMPLATE)

export const TemplateLink = ({ children }) => {
  const link = useRef(null)

  return (
    <div onClick={() => link.current.click()}>
      <a
        href={TEMPLATE_URL}
        download="vehicles-template.csv"
        ref={link}
        style={{ display: 'none' }}
      >Download vehicles template csv</a>
      {children}
    </div>
  )
}
TemplateLink.propTypes = {
  children: propTypes.node
}

const Head = ({ columns }) => {
  const names = columns.map(c => ATTRIBUTES[c].name || startCase(c))

  return (
    <thead>
      <tr>
        {names.map(n => <th key={n}>{n}</th>)}
        <th />
      </tr>
    </thead>
  )
}
Head.propTypes = {
  columns: propTypes.array
}

const formatValue = (column, val) => (
  invoke(ATTRIBUTES[column], 'format', val) || val
)

const Pending = ({ reg, columns }) => (
  <tr className="pending">
    <td>{reg}</td>
    <td colSpan={columns.length}>Pending</td>
  </tr>
)
Pending.propTypes = {
  reg: propTypes.string,
  columns: propTypes.array,
}

const DeleteControl = ({ reg, deleteRow, icon }) => (
  <td className="control">
    <FontAwesomeIcon icon={icon || "times"} onClick={() => deleteRow(reg)} />
  </td>
)
DeleteControl.propTypes = {
  reg: propTypes.string,
  icon: propTypes.string,
  deleteRow: propTypes.func
}

const Normal = ({ reg, data, columns, deleteRow }) => (
  <tr key={reg}>
    {columns.map(c => <td key={c}>{formatValue(c, data[c])}</td>)}
    <DeleteControl reg={reg} deleteRow={deleteRow} />
  </tr>
)
Normal.propTypes = {
  reg: propTypes.string,
  data: propTypes.object,
  columns: propTypes.array,
  deleteRow: propTypes.func
}

const Failure = ({ reg, data, columns, deleteRow }) => (
  <tr className="failure" title={data.error}>
    <td>{reg}</td>
    <td colSpan={columns.length - 1}>
      <FontAwesomeIcon icon="exclamation-triangle" />{data.error}
    </td>
    <DeleteControl reg={reg} deleteRow={deleteRow} />
  </tr>
)
Failure.propTypes = Normal.propTypes

const Confirmed = ({ reg, data, columns, deleteRow }) => (
  <tr key={reg} className="confirmed">
    {columns.map(c => <td key={c}>{formatValue(c, data[c])}</td>)}
    <DeleteControl reg={reg} deleteRow={deleteRow} icon="check" />
  </tr>
)
Confirmed.propTypes = Normal.propTypes

const Row = (props) => {
  if (props.data.pending) { return <Pending {...props} /> }
  if (props.data.error) { return <Failure {...props} /> }
  if (props.data.confirmed) { return <Confirmed {...props} /> }
  return <Normal {...props} />
}
Row.propTypes = { data: propTypes.object }

const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  border-top: 1px solid #00000015;
  border-bottom: 1px solid #00000015;
  height: 64vh;
  overflow-y: scroll;
`

const StylishTable = styled(StyledTable)`
  td { text-transform: capitalize }

  td:first-child { // registration
    color: #F66C57;
    text-transform: uppercase;
    font-weight: 700;
  }
`

export default DataTable
