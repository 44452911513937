import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { Button } from '../RegisterVehicle'
import { TabBodyContainer } from '../Show'
import { InfoTable, formatDate } from '../../common'
import { DriverSelectWrapper } from '../../dashboard/DriverSelect'

export const Drivers = () => {
  const { drivers, ovid } = useSelector(r => r.vehicles.show.data)
  const { drivers: allDrivers } = useSelector(r => r.drivers.index)

  let data = Object.values(allDrivers).filter(d => drivers.includes(d.id))
  data = data.map(d => {
    const link = [d.name, `/drivers/${d.id}`]
    return { ...d, link }
  })

  return (
    <>
      <Container>
        <div>Drivers</div>
        <div>
          Below are all the drivers in your fleet that are registered to drive this vehicle.
          If there are any issues, please contact your Transport Manager.
        </div>
        <DriverSelectWrapper value={drivers} ovid={ovid}>
          <EditButton>Edit</EditButton>
        </DriverSelectWrapper>
      </Container>
      <TabBodyContainer>
        <InfoTable
          attributes={ATTRIBUTES}
          data={data}
        />
      </TabBodyContainer>
    </>
  )
}

const EditButton = styled(Button)`
  width: 7.5rem;
  margin-bottom: 1rem;
`
const Container = styled.div`
  padding: 1.1rem;

  > div:first-child { font-weight: 700 }

  > * + * { margin-top: 1rem }
`

const linkTo = d => (
  <Link to={d[1]}>
    {d[0]}
  </Link>
)

const ATTRIBUTES = {
  link: { name: 'Name', format: linkTo },
  licenceNumber: {},
  validFrom: { format: formatDate },
  validTo: { format: formatDate },
  risk: { format: () => '--' },
  email: { format: () => '--' },
  createdAt: { name: 'Added', format: formatDate }
}
