import React from 'react'
import PropTypes from 'prop-types'

import './data-table.scss'
import ControlRow from './ControlRow'
import Columns from './Columns'
import Contents from './Contents'
import PagingRow from './PagingRow'
import { Conditional } from '../../common'

const DataTable = (props) => (
  <div className={'table index-table ' + props.model}>
    <ControlRow {...props} />
    <div className="data-body">
      <Columns columns={props.columns} checkbox={props.checkbox} />
      <Contents {...props} />
    </div>
    <Conditional if={!props.scroll}>
      <PagingRow {...props } />
    </Conditional>
  </div>
)

DataTable.propTypes = {
  model: PropTypes.string,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  checkbox: PropTypes.object,
  count: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  linkString: PropTypes.string,
  pageSizeOptions: PropTypes.array.isRequired,
  categoryOptions: PropTypes.array,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.object.isRequired,
  search: PropTypes.string,
  handlePageChange: PropTypes.func.isRequired,
  handleSizeChange: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func,
  handleCategoryChange: PropTypes.func
}

export default DataTable
