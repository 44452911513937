import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Api from '../api/call'
import { NOTIFICATIONS, NOTIFICATIONS_UPDATE, NOTIFICATIONS_ACKNOWLEDGE_ALL } from '../root/action-types'
import { notifications, notificationsActive } from '../assets/'
import { useHoverPanel, wordyDatetime, Conditional } from '../common/'

const Widget = () => {
  const { loading, index } = useSelector(r => r.notifications)
  useEffect(() => Api({ request: NOTIFICATIONS }), [])
  const { active, activate, ref } = useHoverPanel()
  const items = Object.values(index).sort((a, b) => a.createdAt < b.createdAt)

  return (
    <Style className="notifications dropdown-button">
      <img alt="notifications" src={items.length ? notificationsActive : notifications} onClick={activate} />
      <div className={`dropdown-content ${active ? 'visible' : ''}`} ref={ref}>
        <div className="header">
          <h1>Notifications</h1>
          <Link to="/settings">Settings</Link>
        </div>
        <div className="items">
          {items.map(n => <Notification key={n.id} {...n} loading={loading} />)}
          <Conditional if={items.length == 0}>
            <span className="empty">No outstanding notifications</span>
          </Conditional>
        </div>
        <div className="footer">
          <span onClick={acknowledgeAll}>Mark all as read</span>
        </div>
      </div>
    </Style>
  )
}

const acknowledgeAll = () => {
  Api({ request: NOTIFICATIONS_ACKNOWLEDGE_ALL })
}

const acknowledge = (id) => {
  Api({ request: NOTIFICATIONS_UPDATE, id, data: { acknowledged: true } })
}

const Notification = ({ id, title, body, acknowledged, createdAt, link }) => (
  <div className={`item ${acknowledged ? 'acknowledged' : ''}`}>
    <FontAwesomeIcon icon="circle" />
    <div className="middle">
      <div className="top">
        <Link to={link} className="title">{title}:</Link>
        {body}
      </div>
      <div className="bottom">
        {wordyDatetime(createdAt)}
      </div>
    </div>
    <FontAwesomeIcon icon="times" onClick={() => acknowledge(id)} />
  </div>
)

const Style = styled.div`
  img {
    cursor: pointer;
    margin-top: 0.15rem;
    width: 2.1rem;
  }
  .dropdown-content {
    min-width: 28rem;
    max-width: 28rem;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 0.15rem solid #eee;
      h1 {
        color: black;
      }
      a {
        color: #B9B9B9;
      }
      h1, a {
        padding: 1rem;
        margin: 0;
        font-size: 14px;
      }
    }
    .items {
      max-height: 20rem;
      overflow: auto;
    }
    .footer {
      padding: 1rem;
      padding-left: 1.3rem;
      font-size: 14px;
      cursor: pointer;
      border-top: 0.15rem solid #eee;

      span:hover {
        text-decoration: underline;
      }
    }
    .item {
      padding: 1rem;
      padding-bottom: 0;
      font-size: 14px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      svg {
        width: 5%;
      }
      svg:first-child {
        color: ${p => p.theme.primaryColor};
        font-size: 0.4rem;
        margin-top: 0.45rem;
      }
      svg:last-child {
        color: #ddd;
        font-size: 0.8rem;
        margin-top: 0.2rem;
        cursor: pointer;
        &:hover { color: black; }
      }
      .middle {
        width: 90%;
        margin-left: 1rem;
        margin-right: 1rem;
        padding-bottom: 1rem;
        border-bottom: 0.15rem solid #eee;
      }
      &:last-child {
        .middle {
          border-bottom: none;
        }
      }
      .title {
        margin: 0;
        color: black;
        font-weight: bold;
        padding-right: 0.25rem;
      }
      .top {
        white-space: break-spaces;
      }
      .bottom {
        padding-top: 0.5rem;
        color: #B9B9B9;
      }
    }
  }
`

export default Widget
