import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useHistory } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { isEmpty } from 'lodash'
import 'react-tabs/style/react-tabs.css'

import Api from '../api/call'
import { VEHICLES_SHOW, VEHICLES_SHOW_RESET_STORE } from '../root/action-types'
import { Loaded } from '../common/'
import MOTTaxStatus from './MOTTaxStatus'
import { AverageWidgetShow } from '../dashboard/AverageWidget'
import Overview from './Overview'
import Refresh from './Refresh'
import { MOT, Audit, AttachmentsTab, Drivers, Settings } from './tabs/'
import './tabs/tabs.scss'

const Show = ({ match: { params: { id } } }) => {
  const { loading, data, refreshed } = useSelector(r => r.vehicles.show)
  const [loaded, setLoaded] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    Api({ request: VEHICLES_SHOW, id })
    return () => dispatch({ type: VEHICLES_SHOW_RESET_STORE })
  }, [id])

  useEffect(() => {
    if (loading === false) {
      if (isEmpty(data)) return history.push('/dashboard')

      setLoaded(true)
    }
  }, [loading, data])

  return (
    <Styling>
      <div className="back">
        <Link to="/dashboard">
          <FontAwesomeIcon icon="caret-left" />
          <span>Registered Vehicles</span>
        </Link>
        <Refresh id={id} key={id} />
      </div>
      <div className="content">
        <Loaded loading={loaded === false}>
          <div className="content-left">
            <Overview {...data} refreshed={refreshed} />
          </div>
          <div className="content-right">
            <div className="widgets">
              <MOTTaxStatus />
              <AverageWidgetShow
                field="odometerValue"
                title="Average Mileage"
                suffix="miles"
                stroke="#FE9C31"
                fill="#FFF5e9"
              />
            </div>
            <Tabs>
              <TabList>
                <Tab>MOT History</Tab>
                <Tab>Audit Trails</Tab>
                <Tab>Attachments</Tab>
                <Tab>Drivers</Tab>
                <Tab>Settings</Tab>
              </TabList>

              <TabPanel><MOT /></TabPanel>
              <TabPanel><Audit /></TabPanel>
              <TabPanel>
                <AttachmentsTab parentId={data && data.ovid} parentType="OrganisationVehicle" />
              </TabPanel>
              <TabPanel><Drivers /></TabPanel>
              <TabPanel><Settings /></TabPanel>
            </Tabs>
          </div>
        </Loaded>
      </div>
    </Styling>
  )
}

export const TabBodyContainer = styled.div`
  max-height: 600px;
  overflow-y: scroll;
`

const Styling = styled.div`
  display: flex;
  flex-direction: column;

  .back {
    font-size: 1.3rem;
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;

    a { color: #633c76; }
    svg {
      padding-right: 0.75rem;
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .content-left {
    margin-bottom: 1rem;
    flex-shrink: 0;
  }
  .content-right {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: calc(100% - 25rem);
  }
  .widgets {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`

export default Show
