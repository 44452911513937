import { useSelector } from 'react-redux'
import { meanBy, get } from 'lodash'

import { parseCompletedDate } from '../common'

const DAY = 86400000

export const useChart = (field) => {
  const { matched } = useSelector(r => r.dashboard)
  const points = matched.sort(m => m.addedAt).reverse().map(vehicle => ({
    value: parseFloat(vehicle[field] || 0),
    date: vehicle.addedAt,
    x: Math.floor(new Date(vehicle.addedAt).valueOf() / DAY)
  }))
  const byX = {}
  points.forEach((p, i) => {
    byX[p.x] = {
      x: p.x,
      y: Math.floor(meanBy(points.slice(0, i + 1), v => v.value)),
      label: p.date
    }
  })
  const chart = Object.values(byX)
  const mean = meanBy(matched, v => parseFloat(v[field] || 0)) || 0

  return { mean, chart, percentChange: percentChange(30, chart) }
}

export const useChartMOT = (field) => {
  const data = useSelector(r => r.vehicles.show)
  const mots = [...(get(data, 'data.motTests') || [])]

  const chart = mots.filter(m => m.testResult === 'PASSED').reverse().map(m => ({
    value: parseFloat(m[field]),
    label: parseCompletedDate(get(m, 'completedDate'))
  }))
  const latestMOT = get(mots.filter(m => (
    m.testResult === 'PASSED'
  )), [0, 'completedDate'], '').split('.')[0]
  const years = latestMOT - get(data, 'data.year', 0)
  const mean = get(data, 'data.mileage', 0) / Math.max(1, years)

  let pC
  if (chart.length >= 2) {
    const difference = chart[chart.length - 1].value - chart[chart.length - 2].value
    pC = difference / chart[chart.length - 2].value * 100
  }

  return { mean, chart, percentChange: pC }
}

const percentChange = (days, points) => {
  const current = points[points.length - 1]
  const last = points.slice(0, points.length - 2).reverse().find(p => p.x + days <= current.x)
  if (!last) { return }
  return ((current.y - last.y) / last.y) * 100
}
