import React from 'react'
import styled from 'styled-components/macro'

const CostsPanel = styled.div`
  width: 50%;
  text-align: left;
  padding-right: 5%;

  @media screen and (max-width: 1023px) {
    width: 100%;
    margin-top: 3rem;
    order: 2;
    padding-right: 0;
  }

  .costs-title {
    padding-bottom: 0.75rem;
    font-weight: 700;
  }

  .costs-box {
    border: 1px solid #B9B9B9;
    border-radius: 5px;
    background: white;
  }

  .costs-box-row {
    padding: 1.5rem 0;
    margin: 0 1rem;
    display: flex;
    justify-content: space-between;

    &:not(:last-of-type) {
      border-bottom: 1px solid #B9B9B9;
    }
  }

  .costs-box-primary-label {
    font-size: 1.1rem;
    margin-bottom: .5rem;
  }

  .costs-box-secondary-label {
    color: #B9B9B9;
    font-size: 0.8rem;
    margin-right: .5em;
  }

  .costs-box-price {
    font-size: 2.1rem;
    font-weight: 700;
    display: flex;
    align-items: center;

    i {
      font-weight: 400;
      margin: 0 0 0 .25rem;
    }

    span {
      font-size: 0.9rem;
      display: block;
      font-weight: 400;
      margin-left: .9rem;
    }
  }
`
export const Costs = ({ costs }) => {
  const cost = amount => parseFloat(amount / 100)

  return (
    <CostsPanel>
      <div className="costs-title">Costs</div>
      <div className="costs-box">
        <div className="costs-box-row">
          <span className="costs-box-type">
            <div className="costs-box-primary-label">Vehicles</div>
            <div className="costs-box-secondary-label">
              This option is best suited for those looking to
              register smaller fleets, adding vehicles individually.
            </div>
          </span>
          <span className="costs-box-price">£{cost(costs.vehicle)}<i>/</i><span>per <br/> vehicle</span></span>
        </div>
        <div className="costs-box-row">
          <span className="costs-box-type">
            <div className="costs-box-primary-label">Driving Licence</div>
            <div className="costs-box-secondary-label">
              For each driver registered, Vehelix will acquire a complete
              account of their driving history, including any vehicles
              registered in their name, offences, licence points and more.
            </div>
          </span>
          <span className="costs-box-price">£{cost(costs.licence)}<i>/</i><span>per <br/> licence</span></span>
        </div>
      </div>
    </CostsPanel>
  )
}
