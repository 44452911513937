import React from 'react'
import { useSelector } from 'react-redux'

import { TabBodyContainer } from '../Show'
import { InfoTable, formatDate } from '../../common'
import { CATEGORIES } from '../penaltyCategories'

export const Offences = () => {
  const { endorsements } = useSelector(r => r.drivers.show.data)
  const data = endorsements.map(e => ({
    ...e,
    code: `${e.code}: ${CATEGORIES[e.code] || '--'}`,
  }))

  return (
    <TabBodyContainer>
      <InfoTable
        attributes={ATTRIBUTES}
        data={data}
      />
    </TabBodyContainer>
  )
}

const yesNo = b => b ? 'Yes' : 'No'

const ATTRIBUTES = {
  offenceDate: { name: 'Offence Date', format: formatDate },
  convictionDate: { name: 'Conviction Date', format: formatDate },
  penaltyPoints: { name: 'Penalty Points' },
  expiryDate: { name: 'Expiry Date', format: formatDate },
  code: { name: 'Code' },
  isDisqualified: { name: 'Disqualified', format: yesNo },
}
