import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import { Wrapper } from './Login'

const Welcome = () => {
  return (
    <Wrapper>
      <h1>Welcome to Vehelix</h1>
      <div className="sub-header">
        Select from one of the options below to add vehicles or drivers to your fleet.
      </div>
      <div className="welcome-actions">
        <Link to="/vehicles/new" className="button">
          <FontAwesomeIcon icon="car-alt" size="2x" />
          <span>Add Vehicles</span>
        </Link>
        <Link to="/drivers/new" className="button">
          <FontAwesomeIcon icon="id-card" size="2x" />
          <span>Add Driver</span>
        </Link>
      </div>
      <div className="change">
        <Link to="/">
          Skip
          <FontAwesomeIcon className="right" icon="caret-square-right" />
        </Link>
      </div>
    </Wrapper>
  )
}

export default Welcome
