import { combineReducers } from 'redux'
import { get } from 'lodash'

import { SEARCH, LIMIT, VEHICLES_CONFIRM, DRIVERS_CREATE, PAYMENT_REQUIRED } from '../root/action-types'

const INITIAL_SEARCH = { drivers: [], vehicles: [] }

const search = (state = INITIAL_SEARCH, action) => {
  switch (action.type) {
    case `${SEARCH}_START`: {
      return { ...INITIAL_SEARCH, loading: true }
    }
    case `${SEARCH}_SUCCESS`: {
      return { ...state, loading: false, ...action.response.data }
    }
    case `${SEARCH}_RESET_STORE`: {
      return INITIAL_SEARCH
    }
    default: {
      return state
    }
  }
}

const INITIAL_LIMIT = { open: false, mode: 'unpaid' }

const limitModal = (state = INITIAL_LIMIT, action) => {
  switch (action.type) {
    case `${VEHICLES_CONFIRM}_SUCCESS`: {
      const { data } = action.response
      const errors = Object.keys(data).filter(key => !!data[key]?.error)
      if (errors.length === 0) return state
      return { open: true, closeable: true, mode: 'slots' }
    }
    case `${DRIVERS_CREATE}_FAILURE`: {
      const error = get(action.response, 'data.errors[0]', '')
      if (error.match(/Trial account/)) {
        return { open: true, mode: 'slots' }
      }
      return state
    }
    case PAYMENT_REQUIRED: {
      return { open: true, mode: 'unpaid' }
    }
    case `${LIMIT}_CLOSE`: {
      if (state.closeable) return INITIAL_LIMIT
      return state
    }
    case `${LIMIT}_OPEN`: {
      return { open: true, mode: 'unpaid' }
    }
    case `${LIMIT}_CONTINUE`: {
      return { ...state, open: false }
    }
    default:
      return state
  }
}

export default combineReducers({
  search,
  limitModal,
})
