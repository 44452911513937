import { combineReducers } from 'redux'

import {
  DRIVERS,
  DRIVERS_CREATE,
  DRIVERS_UPDATE,
  DRIVERS_INVITE,
  DRIVERS_GUEST,
  DRIVERS_GUEST_NEW,
  DRIVERS_PENDING,
  DRIVERS_SHOW,
  DRIVERS_SHOW_RESET_STORE,
  DRIVERS_UNASSOCIATE
} from '../root/action-types'

const INITIAL = {}

export const driverStatus = (state = INITIAL, action) => {
  switch (action.type) {
    case `${DRIVERS_GUEST}_START`:
    case `${DRIVERS_CREATE}_START`: {
      return { ...INITIAL, loading: true }
    }
    case `${DRIVERS_GUEST}_SUCCESS`:
    case `${DRIVERS_CREATE}_SUCCESS`: {
      return {
        ...state,
        loading: false,
        success: true,
        ...action.response.data,
      }
    }
    case `${DRIVERS_GUEST}_FAILURE`:
    case `${DRIVERS_CREATE}_FAILURE`: {
      return { ...state, loading: false, ...action.response.data }
    }
    default: {
      return state
    }
  }
}

const guestNew = (state = INITIAL, action) => {
  switch (action.type) {
    case `${DRIVERS_GUEST_NEW}_START`: {
      return { loading: true }
    }
    case `${DRIVERS_GUEST_NEW}_SUCCESS`: {
      return {
        ...state,
        loading: false,
        ...action.response.data,
      }
    }
    case `${DRIVERS_GUEST_NEW}_FAILURE`: {
      return { ...state, loading: false, ...action.response.data }
    }
    default: {
      return state
    }
  }
}

export const invite = (state = {}, action) => {
  switch (action.type) {
    case `${DRIVERS_INVITE}_START`: {
      return { loading: true }
    }
    case `${DRIVERS_INVITE}_SUCCESS`: {
      return {
        ...state,
        loading: false,
        success: true,
        ...action.response.data,
      }
    }
    case `${DRIVERS_INVITE}_FAILURE`: {
      return { ...state, loading: false, ...action.response.data }
    }
    case `${DRIVERS_INVITE}_RESET_STORE`: {
      return {}
    }
    default: {
      return state
    }
  }
}

const INDEX = { drivers: {}, errors: [], loading: false }

const index = (state = INDEX, action) => {
  switch (action.type) {
    case `${DRIVERS}_START`: {
      return { ...INDEX, loading: true }
    }
    case `${DRIVERS}_SUCCESS`: {
      return { ...INDEX, drivers: action.response.data }
    }
    case `${DRIVERS}_FAILURE`: {
      return { ...INDEX, errors: action.response.data }
    }
    default: {
      return state
    }
  }
}

const INITIAL_SHOW = { errors: [], data: {} }

const show = (state = INITIAL_SHOW, action) => {
  switch (action.type) {
    case `${DRIVERS_UNASSOCIATE}_START`:
    case `${DRIVERS_UPDATE}_START`:
    case `${DRIVERS_SHOW}_START`: {
      return { ...state, loading: true, updated: false }
    }
    case `${DRIVERS_UNASSOCIATE}_FAILURE`:
    case `${DRIVERS_UPDATE}_FAILURE`:
    case `${DRIVERS_SHOW}_FAILURE`: {
      return { ...state, loading: false, errors: action.response.data }
    }
    case `${DRIVERS_UPDATE}_SUCCESS`: {
      return { ...state, loading: false, data: action.response.data, updated: true }
    }
    case `${DRIVERS_SHOW}_SUCCESS`: {
      return { ...state, loading: false, data: action.response.data }
    }
    case `${DRIVERS_UNASSOCIATE}_SUCCESS`: {
      return {
        ...state,
        loading: false,
        unassociated: true
      }
    }
    case DRIVERS_SHOW_RESET_STORE: {
      return INITIAL_SHOW
    }
    default: {
      return state
    }
  }
}

const pending = (state = {}, action) => {
  switch (action.type) {
    case `${DRIVERS_PENDING}_START`: {
      return { ...state, loading: true }
    }
    case `${DRIVERS_PENDING}_SUCCESS`: {
      return { ...state, loading: false, ...action.response.data }
    }
    case `${DRIVERS_PENDING}_FAILURE`: {
      return { ...state, loading: false, errors: action.response.data }
    }
    default: {
      return state
    }
  }
}

export default combineReducers({
  driverStatus,
  invite,
  index,
  show,
  pending,
  guestNew
})
