import React from 'react'
import styled from 'styled-components/macro'

import { Conditional } from '../common'
import SelectedDate from './SelectedDate'

const GenericReminders = ({ title, showDatePicker, data }) => (
  <Styling>
    <div>
      <span className="title">{title}</span>
      <Conditional if={showDatePicker}>
        <SelectedDate />
      </Conditional>
    </div>
    <ReminderCounts>
      {(data || []).map((d, i) => (
        <ReminderCount key={i} onClick={d.onClick} placeholder={d.placeholder ? 1 : 0}>
          <ReminderCircle>{d.count}</ReminderCircle>
          <ReminderTitle>{d.title}</ReminderTitle>
        </ReminderCount>
      ))}
    </ReminderCounts>
  </Styling>
)

const Styling = styled.div`
  background: white;
  padding: 1rem 2rem;
  min-height: 9rem;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 6px rgba(0,0,0,0.05);

  & > div:first-child {
    > span:last-child { float: right }
    display: flex;
    width: 100%;
  }
  .selected-date {
    padding-left: 1rem;
  }

  @media (max-width: 1900px) {
    font-size: 0.8rem;
    .title { font-size: 14px; }
    min-height: 7.2rem;
    padding: 0.8rem 1.6rem;
  }
`
const ReminderCounts = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
`
const ReminderCircle = styled.div`
  font-size: 210%;
  line-height: 2;
  height: 70px;
  width: 70px;
  margin: 0 auto;
  border: 2px solid;
  border-radius: 50%;

  @media (max-width: 1900px) {
    height: 56px;
    width: 56px;
  }
`
const ReminderCount = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 70%;
  margin: 1.5rem 0;
  margin-bottom: 0;

  ${({ placeholder }) => placeholder ? 'cursor: default;' : 'cursor: pointer;'}

  &:nth-child(1) ${ReminderCircle} { color: #E75A66 }
  ${ReminderCircle} { color: #FE9C2C }
`
const ReminderTitle = styled.div`
  padding-top: 1rem;
`

export default GenericReminders
