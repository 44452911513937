import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useHistory } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { get, isEmpty } from 'lodash'
import 'react-tabs/style/react-tabs.css'

import Api from '../api/call'
import { DRIVERS_SHOW, DRIVERS_SHOW_RESET_STORE } from '../root/action-types'
import { Loaded } from '../common/'
import OffencesWidget from './OffencesWidget'
import Overview from './Overview'
import { Offences, Categories, AttachmentsTab, LinkedVehicles, Settings } from './tabs/'
import '../vehicles/tabs/tabs.scss'

const Show = ({ match: { params } }) => {
  const id = parseFloat(params.id)
  const { loading, data } = useSelector(r => r.drivers.show)
  const [loaded, setLoaded] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const tabIndex = get(history, 'location.state.tabIndex', 0)

  useEffect(() => {
    Api({ request: DRIVERS_SHOW, id })
    return () => dispatch({ type: DRIVERS_SHOW_RESET_STORE })
  }, [id])

  useEffect(() => {
    if (loading === false) {
      if (isEmpty(data)) return history.push('/dashboard')

      setLoaded(true)
    }
  }, [loading, data])

  return (
    <Styling>
      <div className="back">
        <Link to="/drivers">
          <FontAwesomeIcon icon="caret-left" />
          <span>Registered Drivers</span>
        </Link>
      </div>
      <div className="content">
        <Loaded loading={loaded === false}>
          <div className="content-left">
            <Overview {...data} />
          </div>
          <div className="content-right">
            <div className="widgets">
              <OffencesWidget {...data} />
              <div>
                <h1>Placeholder Tachyometer</h1>
              </div>
            </div>
            <Tabs defaultIndex={tabIndex}>
              <TabList>
                <Tab>Offences</Tab>
                <Tab>Vehicle Categories</Tab>
                <Tab>Attachments</Tab>
                <Tab>Linked Vehicles</Tab>
                <Tab>Settings</Tab>
              </TabList>

              <TabPanel><Offences /></TabPanel>
              <TabPanel><Categories /></TabPanel>
              <TabPanel>
                <AttachmentsTab parentId={id} parentType="Driver" />
              </TabPanel>
              <TabPanel><LinkedVehicles id={id} /></TabPanel>
              <TabPanel><Settings /></TabPanel>
            </Tabs>
          </div>
        </Loaded>
      </div>
    </Styling>
  )
}

export const TabBodyContainer = styled.div`
  max-height: 600px;
  overflow-y: scroll;
`

const Styling = styled.div`
  display: flex;
  flex-direction: column;

  .back {
    font-size: 1.3rem;
    color: #633C76;
    width: 100%;
    margin-bottom: 1rem;
    svg {
      padding-right: 0.75rem;
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .content-left {
    margin-bottom: 1rem;
    flex-shrink: 0;
  }
  .content-right {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: calc(100% - 25rem);
  }
  .widgets {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
`

export default Show
