import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'

// TODO: move this into a better location
import { ModalContainer } from '../vehicles/tabs/Settings'
import Api from '../api/call'
import { STRIPE_CANCEL_DETAILS } from '../root/action-types'

const CancelPaymentDetails = ({ toggleCancel }) => {
  const { id } = useSelector(r => r.users.current)

  const cancelDetails = () => {
    toggleCancel()
    Api({ request: STRIPE_CANCEL_DETAILS, data: { id } })
  }

  return (
    <SpacedContainer>
      <h2>Are you sure you want to cancel your payment details?</h2>
      <div>
        <button onClick={cancelDetails}>Yes</button>
        <button onClick={toggleCancel}>Go back</button>
      </div>
    </SpacedContainer>
  )
}

const SpacedContainer = styled(ModalContainer)`
  > h2 { margin-bottom: 4rem !important }
`

export default CancelPaymentDetails
