import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'

const Total = ({ title, count }) => (
  <TotalContainer>
    <div className="total-title">{title}</div>
    <div className="total-count">{count}</div>
  </TotalContainer>
)

const TotalContainer = styled.div`
  background: white;
  padding: 1.5rem;
  flex: 1;
  box-shadow: 0 3px 6px rgba(0,0,0,0.05);
  @media (max-width: 1900px) { padding: 1.2rem; }

  .total-title {
    margin-bottom: 1rem;
    @media (max-width: 1900px) { margin-bottom: 0.8rem; }
  }

  .total-count {
    color: #633C76;
    font-size: 3.5rem;
    text-align: center;
    padding: 1rem 2.5rem;

    @media (max-width: 1900px) {
      font-size: 2.8rem;
      padding: 0.8rem 2.1rem;
    }

  }
`

const Totals = () => {
  const { matchedDrivers } = useSelector(r => r.dashboard)

  let offences = 0
  let points = 0
  let disqualifications = 0
  matchedDrivers.forEach(d => {
    offences += d.endorsements.length
    points += d.penaltyPoints
    disqualifications += (d.disqualified ? 1 : 0)
  })

  return (
    <>
      <Total title="Total Offences" count={offences} />
      <Total title="Total Penalty Points" count={points} />
      <Total title="Total Disqualified" count={disqualifications} />
    </>
  )
}

export default Totals
