import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'

import ModalWindow from '../layout/ModalWindow'
import { Button, Conditional } from '../common/'
import Api from '../api/call'
import { VEHICLES_REFRESH, VEHICLES_SHOW } from '../root/action-types'
import { subscribe, unsubscribe } from './subscription'

let delay = false
let timeout

const Refresh = ({ id }) => {
  const [open, setOpen] = useState(false)
  const [updated, setUpdated] = useState(false)
  const [throttle, setThrottle] = useState(false)
  const { refreshing, refreshed, apisBreakdown } = useSelector(r => r.vehicles.show)
  const onClick = () => {
    if (delay) {
      setOpen(true)
      setThrottle(true)
    } else {
      Api({ request: VEHICLES_REFRESH, data: { id } })
      setThrottle(false)
      timeout = setTimeout(() => { setOpen(true) }, 10000)
    }
  }
  const close = () => {
    setOpen(false)
  }
  if (refreshed && !updated) {
    Api({ request: VEHICLES_SHOW, id })
    setOpen(true)
    setUpdated(true)
    delay = true
    clearTimeout(timeout)
    timeout = setTimeout(() => { delay = false }, 60000)
  }

  useEffect(() => {
    subscribe()
    return () => {
      unsubscribe()
      delay = false
      clearTimeout(timeout)
    }
  }, [])

  return (
    <React.Fragment>
      <RefreshButton onClick={onClick} disabled={refreshing}>
        <FontAwesomeIcon icon="redo" />
        {refreshing ? '' : 'Refresh Vehicle Information'}
      </RefreshButton>
      <ModalWindow isOpen={open} closeModal={close} className="refresh-modal">
        <div>
          <Conditional if={refreshed}>
            <Refreshed details={apisBreakdown} throttle={throttle} />
          </Conditional>
          <Conditional if={refreshing}>
            <h2>Refresh Vehicle Information</h2>
            Your request has been queued.
          </Conditional>
        </div>
        <Controls>
          <Button className="primary" onClick={close}>Close</Button>
        </Controls>
      </ModalWindow>
    </React.Fragment>
  )
}

const APIS = {
  dvla: 'DVLA VES',
  dvsa: 'DVSA',
  trade: 'Trade',
  search: 'DVLA Search'
}

const Refreshed = ({ details, throttle }) => (
  <div>
    <h2>Vehicle Information Has Been Refreshed</h2>
    <Conditional if={throttle}>
      <Throttled>You must wait 60 seconds before refreshing again.</Throttled>
    </Conditional>
    <List>
      {Object.entries(details || {}).map(([k, v]) => (
        <div className="entry" key={k}><span>{APIS[k]}: </span>{v}</div>
      ))}
    </List>
  </div>
)

const Throttled = styled.div`
  padding-bottom: 1rem;
`

const List = styled.div`
border-left: 5px solid ${p => p.theme.primaryColor};

.entry {
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  span {
    font-weight: bold;
  }
}
`

const RefreshButton = styled(Button)`
  background-color: ${p => p.theme.secondaryColor};
  border-color: ${p => p.theme.secondaryColor};
  color: black;
  font-size: 0.9rem;
  margin: 0;
  padding: 0.7rem 1rem;
  max-width: 17rem;

  svg {
    padding: 0 !important;
    margin-right: 0.75rem;
  }
  &:disabled {
    svg {
      animation: rotation 2s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
    width: 3rem;
  }
  @keyframes rotation {
    from { transform: rotate(0deg); }
    to { transform: rotate(359deg); }
  }

`

const Controls = styled.div`
  margin-top: 1rem;
  width: 100%;
  button {
    float: right;
    width: 8rem;
  }
`

export default Refresh
