import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js'

import Api from '../api/call'
import {
  STRIPE_CONFIRM_CARD,
  STRIPE_CONFIRM_CARD_RESET_STORE,
  STRIPE_CREATE_SETUP_INTENT
} from '../root/action-types'

const useCardDetails = (id, name, setError) => {
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()

  const [stripeLoading, setStripeLoading] = useState(false)

  const { clientSecret } = useSelector(r => r.users.stripeSetupIntent)

  useEffect(() => {
    if (id) {
      Api({ request: STRIPE_CREATE_SETUP_INTENT, data: { id } })
    }
  }, [id])

  useEffect(() => {
    return () => dispatch({ type: STRIPE_CONFIRM_CARD_RESET_STORE })
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!stripe || !elements || !clientSecret) return

    setStripeLoading(true)

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name
        },
      }
    })

    if (result.error) {
      setError(result.error.message)
    } else {
      const { payment_method } = result.setupIntent
      Api({
        request: STRIPE_CONFIRM_CARD,
        data: { id, payment_method }
      })
    }
    setStripeLoading(false)
  }

  return { stripe, stripeLoading, handleSubmit }
}

export default useCardDetails
