import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'
import propTypes from 'prop-types'

import Searchbar from './Searchbar'
import { logoAlt, user } from '../assets'
import { CURRENT_USER, LOGOUT } from '../root/action-types'
import Api from '../api/call'
import Notifications from '../notifications/Widget'

const NavItem = ({ className, itemIsActive, path, text, onMouseOver, onMouseOut, children }) => (
  <span className={`navbar-item ${className || ""}`} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
    <Link className={`navbar-item-link ${itemIsActive ? "navbar-item-active" : ""}`} to={path}>
      {text}
    </Link>
    {children}
  </span>
)
NavItem.propTypes = {
  className: propTypes.string,
  itemIsActive: propTypes.bool,
  path: propTypes.string,
  text: propTypes.string,
  onMouseOver: propTypes.func,
  onMouseOut: propTypes.func,
  children: propTypes.node,
}

const Bar = styled.nav`
  box-sizing: border-box;
  height: 5rem;
  display: flex;
  align-items: center;

  .navbar-logo { height: 2rem; width: 10rem; }

  .navbar-vehicles-new { margin-left: auto }

  .navbar-logo-container {
    width: 300px;
  }

  .navbar-logo {
    display: block;
    margin: 0 auto;
    height: 2rem;
    width: 10rem;
  }
`
const UserInfo = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-self: flex-end;
  align-items: center;
  margin-left: 2.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  border-left: 1px solid lightgray;
  text-overflow: elipsis;

  .navbar-user-email { color: #8D8D8D }
  .navbar-user-chevron { font-size: 0.7rem; margin-left: auto; cursor: pointer; }
  .notifications {
    margin-right: 2rem;
  }
  .navbar-info-container {
    margin-right: 2rem;
    font-size: .9rem;
  }
`
const Circle = styled.div`
  display: flex;
  justify-content: center;
  border: 2px solid ${props => props.theme.primaryColor};
  border-radius: 50%;
  width: 2.125rem;
  height: 2.125rem;
  margin-right: 1rem;
  flex-shrink: 0;
`
const Button = styled.button`
  border: none;
  border-radius: 5px;
  color: black;
  width: 8rem;
  padding: 0.7rem 0;
  letter-spacing: 0.5px;
  cursor: pointer;

  svg {
    margin-right: 0.5rem;
  }
`
const Navbar = ({ location }) => {
  const current = useSelector(store => store.users.current)
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    Api({ request: CURRENT_USER })
  }, [])

  useEffect(() => {
    const { loading, active, admin } = current
    if (
      get(location, 'pathname') !== './confirm_account' &&
      loading === false &&
      active === false &&
      admin === true
    ) history.push('/confirm_account')
  }, [current, get(location, 'pathname')])

  return (
    <Bar>
      <LogoContainer>
        <Link to={'/'}>
          <img className="navbar-logo" src={logoAlt} alt="Vehelix Logo" />
        </Link>
      </LogoContainer>
      <Searchbar />
      <Link className="navbar-vehicles-new" to={'/vehicles/new'}>
        <Button><FontAwesomeIcon icon="plus" />Add Vehicle</Button>
      </Link>
      <UserInfo>
        <Notifications />
        <Circle>
          <img src={user} alt="User Icon" />
        </Circle>
        <div className="navbar-info-container">
          <div>{[get(current, 'firstName'), get(current, 'lastName')].join(' ')}</div>
          <div className="navbar-user-email">{get(current, 'email')}</div>
        </div>
        <div className="navbar-user-chevron" onClick={() => dispatch({ type: LOGOUT })}>&#9660;</div>
      </UserInfo>
    </Bar>
  )
}
Navbar.propTypes = {
  location: propTypes.object
}
const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  background: #282828;
  height: 100%;
  width: calc(300px - 2.5rem); // box-sizing: content-box wasn't working...
  flex: 0 0 calc(300px - 2.5rem);
  padding-left: 2.5rem;

  @media (max-width: 1900px) {
    width: calc(240px - 2.5rem);
    flex: 0 0 calc(240px - 2.5rem);
  }
`

export default Navbar
