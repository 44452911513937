import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import { useSelector, useDispatch } from 'react-redux'
import { startCase, get } from 'lodash'

import BasicDataTable from '../common/DataTable/BasicTable'
import { DASHBOARD_ADD_FILTER, DASHBOARD_SET_DATE, USERS_SAVE_COLUMNS } from '../root/action-types'
import { capitalCase, modelCase } from '../common/'
import Api from '../api/call'

import DriverCell from './DriverSelect'

const Table = ({ id, additionalColumns, ...props }) => {
  const { matched, date, loading } = useSelector(r => r.dashboard)
  const dispatch = useDispatch()
  const setFilter = (filter) => {
    dispatch({ type: DASHBOARD_ADD_FILTER, filter })
  }
  const columns = useColumns(id, setFilter, additionalColumns)

  const onDateChange = (date) => {
    dispatch({ type: DASHBOARD_SET_DATE, date })
  }

  return (
    <DashboardTableContainer>
      <BasicDataTable
        data={matched}
        loading={loading}
        {...columns}
        newLink="/vehicles/new"
        newText="New Vehicle"
        header=""
        onDateChange={onDateChange}
        dateRange={date}
        {...props}
      />
    </DashboardTableContainer>
  )
}

export const useColumnSave = (id, options, setColumns) => {
  const { columns } = useSelector(r => r.users.current)

  const preference = get(columns, id, []).filter(c => options.includes(c))
  useEffect(() => {
    preference.length && setColumns(preference)
  }, [columns])

  const setSavedColumns = (columns) => {
    Api({ request: USERS_SAVE_COLUMNS, data: { id, columns } })
    setColumns(columns)
  }
  return { setSavedColumns }
}

// The way the columns work is not ideal, and the datatable logic could benefit
// from a refactor. If you want fancy logic for column values, you need to use
// objects for the columns.... but we use plain strings for the column picker,
// because thats much simpler to work with. Hence this fudgey looking code...
const useColumns = (id, setFilter, additionalColumns = []) => {
  const all = {}
  buildColumnOptions(setFilter, additionalColumns).forEach(o => {
    all[o.label || startCase(o.accessor)] = o
  })
  const columnOptions = Object.keys(all).filter(co => all[co].default !== false)
  const defaultColumns = columnOptions.slice(0, 9)
  const [currentColumns, setColumns] = useState(defaultColumns)
  const columns = currentColumns.map(c => all[c])
  const { setSavedColumns } = useColumnSave(id, columnOptions, setColumns)

  return { columns, columnOptions, currentColumns, defaultColumns, setColumns: setSavedColumns }
}

const PrettyDate = ({ value }) => (
  <span className="cell flex-1 date">
    {value && new Date(value).toLocaleDateString()}
  </span>
)

const buildColumnOptions = (onClick, additionalColumns) => ([
  ...additionalColumns,
  { accessor: 'registration', label: 'VRM', className: 'registration', linkString: '/vehicles' },
  { accessor: 'make', onClick, className: 'clickable', format: capitalCase },
  { accessor: 'model', onClick, className: 'clickable', format: modelCase },
  { accessor: 'colour', onClick, className: 'clickable', format: capitalCase },
  { accessor: 'year', onClick, className: 'clickable' },
  { accessor: 'fuelType', onClick, className: 'clickable', format: capitalCase },
  { accessor: 'engineCapacity' },
  { accessor: 'mileage' },
  { accessor: 'drivers', label: 'Driver', Component: DriverCell },
  { accessor: 'addedAt', Component: PrettyDate },
  { accessor: 'co2Emissions', label: 'CO2 Emissions' },
  { accessor: 'markedForExport' },
  { accessor: 'monthOfFirstRegistration' },
  { accessor: 'motExpiryDate', Component: PrettyDate },
  { accessor: 'motStatus' },
  { accessor: 'taxDueDate', Component: PrettyDate },
  { accessor: 'taxStatus' },
  { accessor: 'transmission', onClick, className: 'clickable', format: capitalCase },
  { accessor: 'vin', label: 'VIN' },
  { accessor: 'typeApproval' },
  { accessor: 'wheelplan', format: capitalCase },
  { accessor: 'dateOfLastV5CIssued' },
  { accessor: 'euroStatus' },
  { accessor: 'revenueWeight' },
  { accessor: 'realDrivingEmissions' },
])

export const DashboardTableContainer = styled.div`
  background: white;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 3px 6px rgba(0,0,0,0.05);
  width: calc(100% - 4rem);

  div.table.header {
    padding-bottom: 1.5em;

    span {
      color: #B9B9B9;
    }
  }

  h2 {
    margin-top: 0;
  }

  .registration {
    color: black;
    font-weight: bold;
  }
  .clickable {
    cursor: pointer;
    text-decoration: underline;
  }

  .cell.driver.blank {
    font-style: italic;
    opacity: 0.5;
  }
  .cell.driver {
    cursor: pointer;
  }
`

export default Table
