import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { TOGGLE_ROW, TOGGLE_ALL, TAGS_ASSIGN } from '../root/action-types'
import { ModalContainer } from '../dashboard/DriverSelect'
import ModalWindow from '../layout/ModalWindow'
import { Conditional, VerticalSelect } from '../common/'
import Api from '../api/call'

const useSelectRows = () => {
  const [all, setAll] = useState(false)
  const { selected } = useSelector(r => r.dashboard)
  const dispatch = useDispatch()

  const toggleAll = () => {
    setAll(!all)
    dispatch({ type: TOGGLE_ALL, all: !all })
  }

  const toggleRow = id => dispatch({ type: TOGGLE_ROW, id })

  return { toggleRow, selected, toggleAll, all }
}

export const SelectAll = () => {
  const { toggleAll, all } = useSelectRows()
  const { isSelectable } = useSelector(r => r.dashboard)

  if (!isSelectable) { return null }

  return (
    <input
      type="checkbox"
      onChange={toggleAll}
      checked={all}
    />
  )
}

export const SelectCell = ({ row: { ovid } }) => {
  const { toggleRow, selected } = useSelectRows()
  const { isSelectable } = useSelector(r => r.dashboard)

  if (!isSelectable) { return <span className="cell flex-0" /> }

  return (
    <span className="cell flex-0">
      <input
        type="checkbox"
        onChange={() => toggleRow(ovid)}
        checked={selected.includes(ovid)}
      />
    </span>
  )
}

export const GroupsCell = ({ row: { ovid } }) => {
  const { groups, vehicleGroups } = useSelector(r => r.fleet)
  const names = (vehicleGroups[ovid] || []).filter(id => groups[id]).map(id => groups[id].name)
  let content
  if (names.length == 0) {
    content = ""
  } else if (names.length == 1) {
    content = names[0]
  } else {
    content = `${names[0]} + ${names.length - 1}`
  }

  return <span className="cell">{content}</span>
}

export const TagsCell = ({ row: { ovid } }) => {
  const { tags, vehicleTags } = useSelector(r => r.fleet)
  const [active, setActive] = useState(false)
  const toggle = () => setActive(!active)
  const names = (vehicleTags[ovid] || []).map(id => tags[id].name)
  const content = names.length ? names[0] : 'Add tags'
  const value = vehicleTags[ovid] || []

  const update = tags => {
    Api({ request: TAGS_ASSIGN, data: { ovid, tags } })
  }

  return (
    <>
      <span className="cell pointer flex-2" onClick={toggle}>
        <div className="tags-plus"></div>
        <div className={names.length ? "primary-tag" : "add-tags"}>{content}</div>
        <Conditional if={names.length > 1}>
          <div className="secondary-tags-count">+ {names.length - 1}</div>
        </Conditional>
      </span>
      <Conditional if={active}>
        <TagModal
          value={value}
          toggle={toggle}
          tags={tags}
          update={update}
        />
      </Conditional>
    </>
  )
}

const option = ({ id, name }) => ({
  value: id,
  label: name,
  description: ''
})

const TagModal = ({ value, toggle, tags, update }) => (
  <ModalWindow isOpen closeModal={toggle} className="tag-modal">
    <ModalContainer>
      <h2>Add Tags</h2>
      <VerticalSelect
        value={value.map(id => option(tags[id] || {}))}
        options={Object.values(tags).map(option)}
        onChange={update}
      />
    </ModalContainer>
  </ModalWindow>
)

export default useSelectRows
