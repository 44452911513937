import React, { Component } from 'react'
import { get, debounce, isEqual } from 'lodash'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const value = (row, accessor, format) => {
  const v = get(row, accessor)

  if (v === null) { return null }
  if (v === true) { return <FontAwesomeIcon icon="check" /> } // [1] == true!
  if (format) { return format(v) }
  return v
}

const Cell = ({ className, flex, accessor, format, row, onClick, linkString, Component }) => {
  const content = value(row, accessor, format)
  const link = linkString && `${linkString}/${row.id}`
  if (Component) { return <Component value={content} row={row} /> }
  return (
    <span
      className={`cell ${className || ''} flex-${flex || 1}`}
      onClick={onClick ? () => onClick({ [accessor]: row[accessor] }) : null}
    >
      {link ? <Link to={link}>{content}</Link> : content}
    </span>
  )
}

const BaseRow = ({ row, columns, checkbox }) => (
  <div className='row' key={row.id}>
    {checkbox ? <Cell {...checkbox} row={row} /> : null }
    {columns.map(c => <Cell {...c} row={row} key={c.accessor + row.id} />)}
  </div>
)

const Row = ({ row, linkString, ...props }) => {
  const link = row.linkString || (linkString ? `${linkString}${row.id}` : null)
  if (link) {
    return (
      <Link className="navbar-item-link" to={link}>
        <BaseRow row={row} {...props} />
      </Link>
    )
  } else {
    return <BaseRow row={row} {...props} />
  }
}

class Contents extends Component {
  constructor (props) {
    super(props)
    this.state = { page: 1 }
  }

  componentDidUpdate = (prev) => {
    if (!isEqual(this.props.data, prev.data)) this.setState({ page: 1 })
  }

  componentDidMount = () => {
    const contents = document.getElementById('contents-table')
    if (!contents) return

    contents.scrollTop = 0
    contents.onscroll = debounce(() => {
      const { scrollTop, scrollTopMax } = contents
      if (scrollTop === scrollTopMax) this.setState({ page: this.state.page + 1 })
    }, 100)
  }

  rows = () => {
    if (this.props.scroll) {
      return this.props.data.slice(0, this.state.page * 20)
    } else {
      return this.props.data
    }
  }

  render = () => (
    <div id="contents-table" className="contents">
      {this.rows().map(r => (
        <Row
          key={r.id}
          row={r}
          columns={this.props.columns}
          checkbox={this.props.checkbox}
          linkString={this.props.linkString}
        />
      ))}
    </div>
  )
}

export default Contents
