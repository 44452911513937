import {
  FLEET,
  GROUP_SELECT,
  GROUP_EXPAND,
  GROUPS_ASSIGN,
  GROUPS_UNASSIGN,
  GROUPS_CREATE,
  GROUPS_UPDATE,
  GROUPS_DESTROY,
  TAGS_CREATE,
  TAGS_UPDATE,
  TAG_SELECT,
  TAGS_DESTROY,
  TAGS_ASSIGN,
  FLEET_SET_MODE,
} from '../root/action-types'

const INITIAL = {
  groups: {},
  groupsByParent: {},
  groupsExpanded: { root: true },
  groupSelected: 'root',
  tagSelected: '',
  tags: {},
  vehicleGroups: {},
  vehicleTags: {},
}

const groupByParent = (groups) => {
  const byParent = {}
  Object.values(groups).forEach(g => {
    if (g.id == 'root') { return true }
    const id = g.parentId || 'root'
    byParent[id] = byParent[id] || []
    byParent[id].push(g)
  })
  return byParent
}

export const fleet = (state = INITIAL, action) => {
  switch (action.type) {
    case `${TAGS_DESTROY}_START`:
    case `${TAGS_UPDATE}_START`:
    case `${TAGS_CREATE}_START`:
    case `${GROUPS_DESTROY}_START`:
    case `${GROUPS_UPDATE}_START`:
    case `${GROUPS_ASSIGN}_START`:
    case `${GROUPS_UNASSIGN}_START`:
    case `${GROUPS_CREATE}_START`: {
      return { ...state, loading: true, success: false }
    }
    case `${FLEET}_START`: {
      return { ...state, loading: true }
    }
    case `${GROUPS_ASSIGN}_SUCCESS`:
    case `${TAGS_ASSIGN}_SUCCESS`:
    case `${GROUPS_UNASSIGN}_SUCCESS`:
    case `${FLEET}_SUCCESS`: {
      return {
        ...state,
        loading: false,
        ...action.response.data,
        groupsByParent: groupByParent(action.response.data.groups),
        mode: null,
      }
    }
    case `${TAGS_UPDATE}_SUCCESS`:
    case `${TAGS_CREATE}_SUCCESS`: {
      const tags = { ...state.tags, ...action.response.data }
      return {
        ...state,
        loading: false,
        success: true,
        tags,
      }
    }
    case `${GROUPS_UPDATE}_SUCCESS`:
    case `${GROUPS_CREATE}_SUCCESS`: {
      const groups = { ...state.groups, ...action.response.data }
      return {
        ...state,
        loading: false,
        success: true,
        groups,
        groupsByParent: groupByParent(groups)
      }
    }
    case `${TAGS_DESTROY}_SUCCESS`: {
      const tags = { ...state.tags }
      const vehicleTags = { ...state.vehicleTags }
      const deleted = Object.keys(action.response.data)

      deleted.forEach(k => { delete tags[k] })

      for (const vt in vehicleTags) {
        const newVehicleTags = vehicleTags[vt].filter(vtt => !deleted.includes(vtt))
        if (newVehicleTags.length) {
          vehicleTags[vt] = newVehicleTags
        } else {
          delete vehicleTags[vt]
        }
      }

      const tagSelected = deleted.includes(state.tagSelected) ? '' : state.tagSelected
      return {
        ...state,
        loading: false,
        success: true,
        tagSelected,
        vehicleTags,
        tags,
      }
    }
    case `${GROUPS_DESTROY}_SUCCESS`: {
      const groups = { ...state.groups }
      const deleted = Object.keys(action.response.data)
      deleted.forEach(k => { delete groups[k] })
      const groupSelected = deleted.includes(state.groupSelected) ? 'root' : state.groupSelected
      const vehicleGroups = {}
      Object.keys(state.vehicleGroups).forEach(k => {
        vehicleGroups[k] = state.vehicleGroups[k].filter(g => !deleted.includes(g))
      })

      return {
        ...state,
        loading: false,
        success: true,
        groups,
        groupsByParent: groupByParent(groups),
        groupSelected,
        vehicleGroups
      }
    }
    case `${TAGS_DESTROY}_FAILURE`:
    case `${TAGS_UPDATE}_FAILURE`:
    case `${TAGS_CREATE}_FAILURE`:
    case `${GROUPS_DESTROY}_FAILURE`:
    case `${GROUPS_UPDATE}_FAILURE`:
    case `${GROUPS_ASSIGN}_FAILURE`:
    case `${GROUPS_UNASSIGN}_FAILURE`:
    case `${GROUPS_CREATE}_FAILURE`: {
      return { ...state, loading: false, errors: action.response.data }
    }
    case `${FLEET}_FAILURE`: {
      return { ...state, loading: false, ...action.response.data }
    }
    case GROUP_EXPAND: {
      const groupsExpanded = {
        ...state.groupsExpanded,
        [action.id]: !state.groupsExpanded[action.id]
      }
      return { ...state, groupsExpanded }
    }
    case GROUP_SELECT: {
      return { ...state, groupSelected: action.id }
    }
    case TAG_SELECT: {
      const tagSelected = action.id === state.tagSelected ? '' : action.id
      return { ...state, tagSelected }
    }
    case FLEET_SET_MODE: {
      return { ...state, mode: action.mode }
    }
    default: {
      return state
    }
  }
}

export default fleet
