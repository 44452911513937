import { capitalize } from 'lodash'

const VOWELS = ['a', 'e', 'o', 'i', 'u']

// If theres no vowels its probably an acronym so keep it upper case
export const modelCase = (words) => {
  if (words && VOWELS.some(v => words.toLowerCase().includes(v))) {
    return capitalCase(words)
  }
  return words
}

export const capitalCase = (word) => (
  word && capitalize(word.toLowerCase())
)

export const prettyNumber = (n) => {
  const safe = parseFloat(n)
  return isNaN(safe) ? '-' : safe.toLocaleString('en-GB')
}

const TIME = { hour: 'numeric', minute: 'numeric' }

const DATETIME = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }

const DATE = { year: 'numeric', month: '2-digit', day: '2-digit' }

const ONE_DAY = 86400000

export const parseUKDate = (string) => {
  if (!string) { return }
  const [day, month, year] = string.split('/')
  return new Date(`${year}/${month}/${day}`)
}

export const formatDatetime = (date) => (
  date && new Date(date).toLocaleDateString('default', DATETIME)
)

export const formatUKDatetime = (date) => (
  date && new Date(date).toLocaleDateString('en-GB', DATETIME)
)

export const formatDate = (date) => (
  date && new Date(date).toLocaleDateString('en-GB', DATE)
)

export const parseCompletedDate = (completedDate = '') => (
  formatDate(completedDate.split(' ')[0].replaceAll('.', '-'))
)

export const wordyDate = (dateString) => {
  const date = new Date(dateString)
  const today = new Date()
  const yesterday = new Date(new Date() - ONE_DAY)

  if (isSameDay(today, date)) { return 'Today' }
  if (isSameDay(yesterday, date)) { return 'Yesterday' }
  return formatDate(date)
}

export const wordyDatetime = (dateString) => {
  const time = new Date(dateString).toLocaleTimeString('en-GB', TIME)
  return `${wordyDate(dateString)} - ${time}`
}

const isSameDay = (a, b) => (
  a.getMonth() == b.getMonth() &&
  a.getFullYear() == b.getFullYear() &&
  a.getDate() == b.getDate()
)

export const daysAgo = (dateString) => {
  const date = new Date(dateString)
  const today = new Date()
  return Math.floor((today - date) / ONE_DAY)
}
