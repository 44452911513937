import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'

import { DASHBOARD_ADD_FILTER } from '../root/action-types'
import GenericReminders from './GenericReminders'

export const dateFrom = organisation => {
  const [motTime, motPeriod] = (get(organisation, 'motLimit') || '').split(' ')
  if (!motTime && !motPeriod) return new Date()

  const newDate = new Date()
  if (motPeriod === 'days') {
    newDate.setDate(newDate.getDate() + parseInt(motTime))
  }
  if (motPeriod === 'weeks') {
    newDate.setDate(newDate.getDate() + (7 * parseInt(motTime)))
  }
  if (motPeriod === 'months') {
    newDate.setMonth(newDate.getMonth() + parseInt(motTime))
  }
  return newDate
}

export const breakdown = (vehicles, motLimit) => {
  const motFail = vehicles.filter(v => get(v, 'mot') === false).length

  let motDue = 0
  for (const v of vehicles) {
    const motExpiry = get(v, 'motExpiryDate')
    if (motExpiry === null) continue

    if (new Date(motExpiry) < motLimit) motDue++
  }

  return { motFail, motDue }
}

const VehicleReminders = () => {
  const { matched } = useSelector(r => r.dashboard)
  const { organisation } = useSelector(r => r.users.current)
  const motLimit = dateFrom(organisation)
  const { motFail, motDue } = breakdown(matched, motLimit)
  const dispatch = useDispatch()
  const filter = (filter) => {
    dispatch({ type: DASHBOARD_ADD_FILTER, filter })
  }

  const data = [
    { count: motFail, title: 'MOT Test Fail results', onClick: () => filter({ mot: false }) },
    { count: motDue, title: 'MOT Test due soon', onClick: () => filter({ motExpiryDate: motLimit }) },
  ]

  return (
    <GenericReminders
      title="Reminders & Alerts"
      showDatePicker={true}
      data={data}
    />
  )
}

export default VehicleReminders
