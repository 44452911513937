import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components/macro'

const FieldError = styled.div`
  display: none;
  font-size: 0.8rem;
  margin-top: -10px;
  text-align: left;
  bottom: 0;
  padding: 0.5rem 1rem;
  color: #FF0000;
  background: #FFE6E6;
  border: 1px solid #FFE6E6;
  border-radius: 5px;
  text-overflow: ellipsis;
`
export const RawField = ({ label, error, className, children, errorMessage }) => (
  <div className={`field ${error ? 'error' : ''} ${className || ''}`}>
    <label className="field-label">
      {label}
    </label>
    {children}
    <FieldError>{errorMessage}</FieldError>
  </div>
)
RawField.propTypes = {
  label: propTypes.string,
  error: propTypes.bool,
  className: propTypes.string,
  children: propTypes.node,
  errorMessage: propTypes.string
}

export const Field = styled(RawField)`
  display: flex;
  flex-direction: column;
  color: #282828;
  margin-bottom: 10px;
  position: relative;
  width: 100%;

  &.disabled {
    label { color: #AAA }

    input {
      background: #F7F7F7;
      border-color: #F7F7F7;
    }
  }

  label {
    font-size: .9rem;
    text-align: left;
  }

  input {
    border-radius: 5px;
    border: 1px solid #B9B9B9;
    padding: 1rem;
    margin-top: 7px;
    margin-bottom: 10px;
    font-family: 'Ubuntu';
    transition: border-color .15s ease-in-out;
    width: 100%;
    box-sizing: border-box;
  }
  input:focus {
    border-color: ${props => props.theme.primaryColor};
    border-radius: 5px;
    border-style solid;
    outline: none; // chrome fix
  }
  ${({ errorMessage }) => errorMessage && `
    ${FieldError} { display: block }
  `}

  &.error {
    input { border: 1px solid #FF0000 }
  }
`
