import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { DateSelectWrapper } from '../common/DateSelect'
import { DASHBOARD_SET_DATE } from '../root/action-types'

const DateContainer = styled.div`
  .date-select {
    display: inline-block;
    color: #633C76;
    cursor: pointer;

    > span {
      padding-right: 0.5rem ;
      font-size: 14px;
    }

    svg { width: .7rem; }

    .active {
      left: auto;
      right: 10rem;
      padding-top: 1rem;
    }
    .tip {
      display: none;
    }
  }
`
const SelectedDate = () => {
  const { date } = useSelector(r => r.dashboard)
  const end = date && date.to ? new Date(date.to) : new Date()
  const dispatch = useDispatch()
  const onChange = (date) => {
    dispatch({ type: DASHBOARD_SET_DATE, date })
  }

  return (
    <DateContainer className="selected-date">
      <DateSelectWrapper {...date} onChange={onChange}>
        <span>{end.toLocaleString('default', { month: 'short', year: 'numeric' })}</span>
        <FontAwesomeIcon icon="calendar-week" />
      </DateSelectWrapper>
    </DateContainer>
  )
}

export default SelectedDate
