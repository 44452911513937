import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'

import Api from '../api/call'
import { Status, Loading, formatDate } from '../common'
import ModalWindow from '../layout/ModalWindow'
import { BILLING_INDEX, CURRENT_USER } from '../root/action-types'

import NextPayment from './NextPayment'
import PaymentDetails from './PaymentDetails'
import BillingHistory from './BillingHistory'
import UpdatePaymentDetails from './UpdatePaymentDetails'
import CancelPaymentDetails from './CancelPaymentDetails'
import useConfirmDirectDebit from './useConfirmDirectDebit'
import { SettingsPanel } from './Settings'

const Billing = () => {
  const [updateOpen, setUpdateOpen] = useState(false)
  const [cancelOpen, setCancelOpen] = useState(false)
  const toggleUpdate = () => setUpdateOpen(open => !open)
  const toggleCancel = () => setCancelOpen(open => !open)

  const { loading, errors, loaded } = useSelector(r => r.users.billing)
  const { loading: cardLoading, errors: cardErrors } = useSelector(r => r.users.confirmCard)
  const { loading: cancellationLoading, cancelAt, cancelAtPeriodEnd } = useSelector(r => r.users.cancelDetails)
  const { ddLoading, ddErrors } = useConfirmDirectDebit()

  // Status: 2-tuple of (isError, [message*])
  const [status, setStatus] = useState([false, []])

  useEffect(() => {
    if (!loaded && !loading) Api({ request: BILLING_INDEX })
    return () => setStatus([false, []])
  }, [])

  useEffect(() => {
    if (errors) setStatus([true, [errors]])
  }, [errors])

  useEffect(() => {
    if (cardErrors) setStatus([true, [cardErrors]])
    if (!cardErrors && cardLoading === false) {
      setUpdateOpen(false)
      Api({ request: BILLING_INDEX })
      setStatus([false, ['Card details added successfully']])
      Api({ request: CURRENT_USER })
    }
  }, [cardLoading, cardErrors])

  useEffect(() => {
    if (ddErrors) setStatus([true, [ddErrors]])
    if (!ddErrors && ddLoading === false) {
      Api({ request: BILLING_INDEX })
      setStatus([false, ['Direct debit details added successfully']])
    }
  }, [ddErrors, ddLoading])

  useEffect(() => {
    if (cancelAtPeriodEnd && cancelAt) {
      setStatus([
        false,
        [`Cancellation successful - your subscription will end on ${formatDate(cancelAt * 1000)}`]
      ]) // UNIX timestamp
    }
  }, [cancelAtPeriodEnd])

  return (
    <SettingsPanel>
      <BillingContainer>
        <h1>Billing Dashboard</h1>
        {status[1].length ? (
          <Status isError={status[0]} messages={status[1]} />
        ) : null}
        {(loading !== false || ddLoading || cancellationLoading) ? (
          <Loading />
        ) : (
          <>
            <div className="top">
              <NextPayment />
              <PaymentDetails
                toggleUpdate={toggleUpdate}
                toggleCancel={toggleCancel}
              />
            </div>
            <BillingHistory />
          </>
        )
        }
        <SlimmerBillingModal isOpen={updateOpen} closeModal={() => setUpdateOpen(false)}>
          <UpdatePaymentDetails />
        </SlimmerBillingModal>
        <BillingModal isOpen={cancelOpen} closeModal={() => setCancelOpen(false)}>
          <CancelPaymentDetails toggleCancel={toggleCancel} />
        </BillingModal>
      </BillingContainer>
    </SettingsPanel>
  )
}

const BillingModal = styled(ModalWindow)`
  h2 {
    font-weight: normal;
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }
`

const SlimmerBillingModal = styled(BillingModal)`
  width: 400px;
`

// Could not make BillingContainer a styled variant of SettingsPanel due to
// 'ReferenceError: can't access lexical declaration...' - thank you webpack
const BillingContainer = styled.div`
  > div.top {
    display: flex;
    @media screen and (max-width: 1750px) {
      flex-wrap: wrap;
    }
  }
`

export default Billing
