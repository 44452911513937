import { call, put, takeEvery } from 'redux-saga/effects'
import { get } from 'lodash'

import { LOGIN, LOGOUT, PAYMENT_REQUIRED } from '../root/action-types'

function * apiRequest ({ payload: { request, action } }) {
  try {
    yield put({ type: `${request}_START` })

    const response = yield call(action)

    yield put({ type: `${request}_SUCCESS`, response })
  } catch (e) {
    if (get(e.response, 'status') == 401) {
      if (request == LOGIN) {
        yield put({ type: 'LOGIN_FAILURE', response: e.response })
      } else {
        yield put({ type: LOGOUT, response: e.response })
      }
    } else if (get(e.response, 'status') == 402) {
      yield put({ type: PAYMENT_REQUIRED, response: e.response })
    } else if (get(e.response, 'status') == 403) {
      yield put({ type: 'CURRENT_USER_FORBIDDEN', response: e.response })
    } else if (get(e.response, 'status') == 422) {
      yield put({ type: `${request}_FAILURE`, response: e.response })
    } else if (get(e.response, 'status') == 500) {
      // eslint-disable-next-line
      console.log('500!')
    } else if (get(e.response, 'status') == 404) {
      // eslint-disable-next-line
      console.log('404! Oh no!')
    } else {
      throw e
    }
  }
}

export function * apiWatcher () {
  yield takeEvery('API_REQUEST', apiRequest)
}
