import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import Select from 'react-select'
import ReCAPTCHA from 'react-google-recaptcha'
import { get, startCase } from 'lodash'
import propTypes from 'prop-types'

import Api from '../api/call'
import { SIGN_UP } from '../root/action-types'
import { Status, useForm, Field, Conditional, Loading, Button, TermsAndConditions } from '../common'
import ModalWindow from '../layout/ModalWindow'
import { Smallprint } from './Signup'
import { COMPANY_TYPES, selectStyles } from './accountFormOptions'

const INITIAL = {
  firstName: '',
  lastName: '',
  companyName: '',
  email: '',
  password: '',
  confirmPassword: ''
}

const CreateAccountFields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 40px;
  justify-items: center;
  max-width: 800px;
  margin: 2rem auto 0 auto;

  .full { grid-column: 1 / -1; }

  .password-field label::after {
    content: '(6 characters minimum)';
    display: inline-block;
    margin-left: 10px;
    color: #aaa;
  }

  > div:nth-child(1) { order: 1 }
  > div:nth-child(2) { order: 2 }
  > div:nth-child(3) { order: 3 }
  > div:nth-child(4) { order: 4 }
  > div:nth-child(5) { order: 6 }
  > div:nth-child(6) { order: 7 }
  > div:nth-child(7) { order: 5 }
  > div:nth-child(8) { order: 8 }

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`
function CreateAccountForm (props) {
  const { loading, errors } = useSelector(r => r.users.signup)
  const { form, updateForm, inputOnChange } = useForm({ ...INITIAL, companyType: null })
  const recaptchaRef = useRef(null)

  const errorMessage = () => {
    if (errors) {
      const regexBlank = /can't be blank/
      if (errors.flat().some(e => e && e.match(regexBlank))) {
        return 'Please ensure all fields are populated'
      }
      const regexTaken = /Email is already taken/
      if (errors.flat().some(e => e && e.match(regexTaken))) {
        return 'Account already exists, please go to Login'
      }
      return errors.join(' ')
    }
    return null
  }
  const error = errorMessage()

  useEffect(() => {
    if (errors) recaptchaRef.current.reset()
    if (errors === null) props.setStage('PAYMENT_DETAILS')
  }, [errors])

  const handleSelect = companyType => {
    updateForm({ companyType })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const data = {
      ...form,
      companyType: get(form.companyType, 'value'),
      captcha: recaptchaRef.current.getValue()
    }
    Api({ request: SIGN_UP, data })
  }

  const handleShowModal = () => (
    props.setShowModal(true)
  )

  return (
    <div className="create-account-container">
      <Status isError={!!error}>
        {error}
      </Status>
      <Conditional if={loading}>
        <Loading />
      </Conditional>
      <CreateAccountFields>
        {['firstName', 'lastName'].map((name, i) => (
          <Field key={name} label={startCase(name)} className="block" error={!!error}>
            <input
              tabIndex={`${i + 1}`}
              className="signup-input"
              type="text"
              name={name}
              onChange={inputOnChange}
              value={form[name]}
              disabled={loading}
            />
          </Field>
        ))}
        <Field label="Email" className="block full" error={!!error}>
          <input
            tabIndex="3"
            className="signup-input"
            type="text"
            name="email"
            onChange={inputOnChange}
            value={form.email}
            disabled={loading}
          />
        </Field>
        <Field label="Company Name" className="block" error={!!error}>
          <input
            tabIndex="4"
            className="signup-input"
            type="text"
            name="companyName"
            onChange={inputOnChange}
            value={form.companyName}
            disabled={loading}
          />
        </Field>
        <Field label="Password" className="block password-field" error={!!error}>
          <input
            tabIndex="6"
            className="signup-input"
            type="password"
            name="password"
            onChange={inputOnChange}
            value={form.password}
            disabled={loading}
          />
        </Field>
        <Field label="Confirm Password" className="block" error={!!error}>
          <input
            tabIndex="7"
            className="signup-input"
            type="password"
            name="confirmPassword"
            onChange={inputOnChange}
            value={form.confirmPassword}
            disabled={loading}
          />
        </Field>
        <Field label="Company Type" className="company-type" error={!!error}>
          <Select
            tabIndex="5"
            value={form.companyType}
            onChange={handleSelect}
            styles={selectStyles}
            options={COMPANY_TYPES}
          />
        </Field>
      </CreateAccountFields>
      <ReCAPTCHA
        className="signup-recaptcha"
        ref={recaptchaRef}
        sitekey={process.env.REACT_APP_RECAPTCHA_PK}
      />
      <div className="terms-text">
        By clicking you accept Vehelix
        <span onClick={handleShowModal}>Terms & Conditions</span>
      </div>
      <Button tabIndex="8" onClick={handleSubmit}>Next</Button>
    </div>
  )
}
CreateAccountForm.propTypes = {
  setShowModal: propTypes.func,
  setStage: propTypes.func
}

function CreateAccount (props) {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <ModalWindow isOpen={showModal} closeModal={() => setShowModal(false)}>
        <TermsAndConditions />
      </ModalWindow>
      <h1>Create a Vehelix Account</h1>
      <Smallprint>Already have an account?<Link to='/login'>Login</Link></Smallprint>
      <Smallprint>
        DVLASearch user?
        <a href='https://dvlasearch.co.uk/' rel="noopener noreferrer" target="_blank">
          Click here
        </a>
      </Smallprint>
      <CreateAccountForm setShowModal={setShowModal} setStage={props.setStage} />
    </>
  )
}
CreateAccount.propTypes = {
  setStage: propTypes.func
}

export default CreateAccount
