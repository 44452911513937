import React, { useState, useEffect } from 'react'
import { useStripe } from '@stripe/react-stripe-js'
import { useSelector } from 'react-redux'
import propTypes from 'prop-types'
import styled from 'styled-components/macro'

import { STRIPE_CREATE_CHECKOUT_SESSION } from '../root/action-types'
import { Button, Status, Conditional, Loading } from '../common'
import { ReactComponent as StripeWordmark } from '../assets/stripe_wordmark.svg'
import Api from '../api/call'

const DEFAULT_SUCCESS_PATH = '/signup/success'
const DEFAULT_HASH = ''

const DirectDebitForm = ({ id, path, hash }) => {
  const [error, setError] = useState(null)
  const { session, loading } = useSelector(r => r.users.signupCheckoutSession)
  const stripe = useStripe()

  const redirectDirectDebit = () => {
    Api({
      request: STRIPE_CREATE_CHECKOUT_SESSION,
      data: {
        id,
        success_path: path || DEFAULT_SUCCESS_PATH,
        hash: hash || DEFAULT_HASH
      }
    })
  }

  useEffect(() => {
    if (session.id) {
      stripe.redirectToCheckout({
        sessionId: session.id
      }).then(result => {
        setError(result.error.message)
      })
    }
  }, [session])

  return (
    <DirectDebitContainer>
      <Status isError={!!error}>
        {error}
      </Status>
      <Conditional if={loading}>
        <Loading />
      </Conditional>
      <div className="direct-debit-message">
        <StripeWordmark />
        <div className="message-text">We use Stripe to handle direct debit payment details.</div>
      </div>
      <Button disabled={loading || !id} onClick={redirectDirectDebit}>Redirect to Stripe</Button>
    </DirectDebitContainer>
  )
}

const DirectDebitContainer = styled.div`
  margin: 0 auto;
  flex: 1;
  text-align: center;

  .direct-debit-message {
    margin: 1rem 1rem 2rem 1rem;
    padding: 2rem;
    background: white;
    border-radius: 3px;
    Line-height: 150%;

    > * { margin: 0 auto }

    > svg {
      display: block;
      width: 125px;
    }

    .message-text {
      width: 225px;
    }
  }
`

DirectDebitForm.propTypes = {
  id: propTypes.string
}

export default DirectDebitForm
