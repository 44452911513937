import axios from 'axios'

const { get, post, put } = axios

const API = process.env.REACT_APP_VEHELIX_API_URL

const CRUD_ROUTES = ({ action, path }) => ({
  [action]: { verb: get, path },
  [`${action}_SHOW`]: { verb: get, path },
  [`${action}_CREATE`]: { verb: post, path },
  [`${action}_UPDATE`]: { verb: put, path },
  [`${action}_DESTROY`]: { verb: axios.delete, path },
})

export const cable = `${String(API).replace('http', 'ws')}/cable`

const routes = {
  ...CRUD_ROUTES({ action: 'DRIVERS', path: 'drivers' }),
  ...CRUD_ROUTES({ action: 'ATTACHMENTS', path: 'attachments' }),
  ...CRUD_ROUTES({ action: 'GROUPS', path: 'groups' }),
  ...CRUD_ROUTES({ action: 'TAGS', path: 'tags' }),
  ...CRUD_ROUTES({ action: 'USERS', path: 'users' }),
  ...CRUD_ROUTES({ action: 'ACCOUNT', path: 'account_management' }),
  CLEAR_DRIVERS: {
    verb: axios.delete,
    path: 'dev/clear_drivers'
  },
  CLEAR_VEHICLES: {
    verb: axios.delete,
    path: 'dev/clear_vehicles'
  },
  DRIVERS_GUEST: {
    verb: post,
    path: 'drivers/guest'
  },
  DRIVERS_GUEST_NEW: {
    verb: get,
    path: 'drivers/guest_new'
  },
  DRIVERS_INVITE: {
    verb: post,
    path: 'drivers/invite'
  },
  DRIVERS_PENDING: {
    verb: get,
    path: 'drivers/pending'
  },
  GROUPS_ASSIGN: {
    verb: post,
    path: 'groups/assign'
  },
  GROUPS_UNASSIGN: {
    verb: axios.delete,
    path: 'groups/unassign'
  },
  LOGIN: {
    verb: post,
    path: 'authenticate'
  },
  PASSWORD_RESET: {
    verb: post,
    path: 'password_reset'
  },
  PASSWORD_CHANGE: {
    verb: post,
    path: 'password_change'
  },
  FILE_DOWNLOAD: {
    verb: post,
    path: 'downloads',
  },
  FLEET: {
    verb: get,
    path: 'fleet'
  },
  CURRENT_USER: {
    verb: get,
    path: 'users/current',
  },
  DASHBOARD: {
    verb: get,
    path: 'dashboard'
  },
  DASHBOARD_ASSIGN_DRIVERS: {
    verb: put,
    path: 'vehicles/assign_drivers'
  },
  DRIVERS_UNASSOCIATE: {
    verb: axios.delete,
    path: 'drivers'
  },
  NOTIFICATIONS: {
    verb: get,
    path: 'notifications'
  },
  NOTIFICATIONS_UPDATE: {
    verb: put,
    path: 'notifications',
  },
  NOTIFICATIONS_ACKNOWLEDGE_ALL: {
    verb: put,
    path: 'notifications/acknowledge_all',
  },
  ORGANISATIONS_SHOW: {
    verb: get,
    path: 'organisations'
  },
  ORGANISATIONS_UPDATE: {
    verb: put,
    path: 'organisations'
  },
  SEARCH: {
    verb: get,
    path: 'search'
  },
  SIGN_UP: {
    verb: post,
    path: 'signup'
  },
  STRIPE_CONFIRM_CARD: {
    verb: put,
    path: 'account_management/confirm_card'
  },
  STRIPE_CONFIRM_DIRECT_DEBIT: {
    verb: put,
    path: 'account_management/confirm_direct_debit'
  },
  STRIPE_CANCEL_DETAILS: {
    verb: put,
    path: 'account_management/cancel_details'
  },
  STRIPE_CREATE_SETUP_INTENT: {
    verb: post,
    path: 'account_management/create_setup_intent'
  },
  STRIPE_CREATE_CHECKOUT_SESSION: {
    verb: post,
    path: 'account_management/create_checkout_session'
  },
  STRIPE_FETCH_PRICES: {
    verb: get,
    path: 'account_management/prices'
  },
  BILLING_INDEX: {
    verb: get,
    path: 'account_management/billing_index'
  },
  TAGS_ASSIGN: {
    verb: post,
    path: 'tags/assign'
  },
  VEHICLES_LOOKUP: {
    verb: get,
    path: 'vehicles/lookup'
  },
  VEHICLES_CONFIRM: {
    verb: post,
    path: 'vehicles/confirm'
  },
  VEHICLES_SHOW: {
    verb: get,
    path: 'vehicles'
  },
  VEHICLES_ASSIGN_DRIVERS: {
    verb: put,
    path: 'vehicles/assign_drivers'
  },
  VEHICLES_REFRESH: {
    verb: put,
    path: 'vehicles/refresh'
  },
  VEHICLES_UNASSOCIATE: {
    verb: axios.delete,
    path: 'vehicles'
  },
  VEHICLES_IMAGE: {
    verb: get,
    path: 'vehicles/image'
  },
  USERS_SAVE_COLUMNS: {
    verb: put,
    path: 'users/save_columns'
  },
}

export default routes
