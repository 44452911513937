import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'

import { DropdownButton, DropdownOption } from './DropdownButton'

export const IconButton = ({ icon, children, ...props }) => (
  <button {...props}>
    <FontAwesomeIcon icon={icon} className="icon" />
    {children}
  </button>
)

export { DropdownButton, DropdownOption }

export const Button = styled.button`
  width: 100%;
  max-width: 380px;
  padding: .75rem 0;
  display: block;
  margin: .5rem auto;
  font-family: ${props => props.theme.font};
  font-size: 1rem;
  color: white;
  background-color: ${props => props.theme.primaryColor};
  border: 1px solid ${props => props.theme.primaryColor};
  border-radius: 5px;
  cursor: pointer;
  align-self: center;
  transition: opacity .15s ease-in-out;

  &:hover {
    opacity: .7;
  }

  &.off {
    background: #B9B9B9 !important;
    border-color: #B9B9B9 !important;
  }
  &.secondary {
    color: black !important;
    border-color: ${p => p.theme.primaryColor} !important;
    background-color: white !important;
  }
  &:disabled {
    background: #B9B9B9 !important;
    border-color: #B9B9B9 !important;
    cursor: not-allowed;
    opacity: 1;
  }

  @media screen and (max-width: 1023px) {
    max-width: unset;
  }
`
