import { defaults } from 'axios'

import { store } from '../root/store'
import history from '../root/browser-history'
import KEYS from '../root/local-storage-keys'
import ActionCable from 'actioncable'

import endpoints, { cable } from './endpoints'

const { LOGIN_AUTH_TOKEN, LOGIN_USER, LOGIN_USER_ID } = KEYS
const config = {}

const getStore = () => (
  config.store || store
)

const VEHELIX_API = process.env.REACT_APP_VEHELIX_API_URL

export default function Api (args) {
  getStore().dispatch({
    type: 'API_REQUEST',
    payload: {
      request: args.request,
      action: buildAction(args)
    }
  })
}

function buildAction ({ request, id, params, data }) {
  const { verb, path } = endpoints[request]
  const url = [VEHELIX_API, path, id].filter(x => x).join('/')

  return () => verb(url, data || { params })
}

export const setAuthToken = (token) => {
  localStorage.setItem(LOGIN_AUTH_TOKEN, token)
  defaults.headers.common.Authorization = token
}

export const getAuthToken = () => (
  defaults.headers.common.Authorization
)

let Cable

export const getCable = () => {
  if (!Cable) {
    Cable = ActionCable.createConsumer(`${cable}?Authorization=${getAuthToken()}`)
  }
  return Cable
}

export const setUser = ({ email, id }) => {
  localStorage.setItem(LOGIN_USER, email)
  localStorage.setItem(LOGIN_USER_ID, id)
  defaults.headers.common.User = email
  defaults.headers.common.UserId = id
}

export const isAuthenticated = () => (
  !!defaults.headers.common.Authorization
)

// Useful for mocking api in tests
export const setApiStore = (mock) => (
  config.store = mock
)

// You can't redirect within an action for some reason, so we need to do it
// async. We only need this for the case when your session has invalidated
const redirectToLogin = () => {
  setTimeout(() => history.push('/login'), 1)
}

export const redirectToHome = () => {
  setTimeout(() => history.push('/'), 1)
}

const rememberLastPage = () => {
  // Async requests sometimes fire after we've logged out
  if (window.location.pathname == '/login') { return }
  window.logoutLocation = window.location.pathname
}

export const logout = () => {
  rememberLastPage()
  localStorage.removeItem(LOGIN_AUTH_TOKEN)
  defaults.headers.common.Authorization = undefined
  redirectToLogin()
}

// set defaults if page reloaded
const init = () => {
  if (!defaults.headers.common.Authorization) {
    defaults.headers.common.Authorization = localStorage.getItem(LOGIN_AUTH_TOKEN)
    defaults.headers.common.User = localStorage.getItem(LOGIN_USER)
    defaults.headers.common.UserId = localStorage.getItem(LOGIN_USER_ID)
  }
}

init()
