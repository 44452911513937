import React from 'react'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'

const StatusContainer = styled.div`
  height: 11.4rem;
  width: 23rem;
  background: white;
  box-shadow: 0px 3px 6px #00000008;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  margin-right: 2rem;

  > div {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;

    &:nth-last-child(-n+2) {
      border-top: 1px solid #EAEDF3;
    }
    &:nth-child(even) {
      padding-left: 0.75rem;
      font-size: 0.9rem;

      > div:first-child {
        color: #B9B9B9;
        margin-bottom: .25rem;
      }
    }
  }
`
const Status = styled.div`
  font-size: 2rem;
  padding-left: 1.75rem;

  ${({ passed }) => passed ? `color: #53B761` : `color: #E75A66`}
`
const StatusIcon = styled(FontAwesomeIcon)`
  padding-right: 0.75rem;
  vertical-align: -0.35rem;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`
const MOTTaxStatus = () => {
  const data = useSelector(r => r.vehicles.show.data)
  const { mot, taxed, motExpiryDate: motExpiry, taxDueDate: taxDue } = data

  const motExpiryDate = motExpiry && new Date(motExpiry)
  const taxDueDate = taxDue && new Date(taxDue)

  const taxOK = taxed && taxDue && new Date() <= taxDueDate
  const motOK = mot && motExpiryDate && new Date() < motExpiryDate

  const dateOptions = { day: '2-digit', month: 'long', year: 'numeric' }
  const formatDate = date => date ? date.toLocaleDateString("en-GB", dateOptions) : 'No date provided'

  return (
    <StatusContainer>
      <Status passed={taxOK}>
        <div>
          <StatusIcon icon={['far', taxOK ? 'check-circle' : 'times-circle']} />
          Taxed
        </div>
      </Status>
      <div>
        <div>Tax due:</div>
        <div>{formatDate(taxDueDate)}</div>
      </div>
      <Status passed={motOK}>
        <span>
          <StatusIcon icon={['far', motOK ? 'check-circle' : 'times-circle']} />
          MOT
        </span>
      </Status>
      <div>
        <div>Expires:</div>
        <div>{formatDate(motExpiryDate)}</div>
      </div>
    </StatusContainer>
  )
}

export default MOTTaxStatus
