import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { get } from 'lodash'

import { formatAmount } from './NextPayment'
import { ReactComponent as DownloadImg } from '../assets/vehelix_download_01.svg'
import { InfoTable, formatDate } from '../common'

const BillingHistory = () => {
  const billing = useSelector(r => r.users.billing)
  const paidInvoices = get(billing, 'paidInvoices', [])

  const data = paidInvoices.map(pi => (
    {
      ...pi,
      amount: formatAmount(pi.currency, pi.amountPaid)
    }
  ))

  return (
    <BillingHistoryContainer>
      <h2>Billing History</h2>
      <div className="table-container">
        <InfoTable
          attributes={ATTRIBUTES}
          data={data}
        />
      </div>
    </BillingHistoryContainer>
  )
}

const BillingHistoryContainer = styled.div`
  margin-top: 1rem;
  padding: 1rem 0;

  .table-container {
    margin-left: -2.5rem;
    margin-right: -2.5rem;

    > table tr *:first-of-type { padding-left: 2.5rem }
    > table tr td:last-of-type {
      padding-right: 0;
      max-width: min-content;
    }

    .invoice-date-cell { color: ${p => p.theme.primaryColor} }
  }

  .invoice-pdf-link {
    color: #BEBEBE;

    > svg {
      height: 1rem;
      padding-right: .25rem;
      vertical-align: top;
    }
    > svg path { fill: #989898 }
  }
`

const linkPdf = link => (
  <a href={link} className="invoice-pdf-link">
    <DownloadImg />
    Download PDF
  </a>
)

const ATTRIBUTES = {
  periodEnd: { name: 'Date', format: d => formatDate(d * 1000), className: 'invoice-date-cell' },
  number: {},
  amount: {},
  invoicePdf: { name: '', format: linkPdf },
}

export default BillingHistory
