import React from 'react'
import styled from 'styled-components/macro'
import propTypes from 'prop-types'

const OffencesWidget = ({ endorsements, penaltyPoints, disqualified }) => {
  const offences = endorsements.length

  return (
    <Container>
      <Box>
        <Count>{offences}</Count>
        Offence{offences === 1 ? '' : 's'}
      </Box>
      <Box>
        <Count>{penaltyPoints}</Count>
        Penalty Point{penaltyPoints === 1 ? '' : 's'}
      </Box>
      <DQBox disqualified={disqualified}>
        {disqualified ? 'Disqualified' : 'Not Disqualified'}
      </DQBox>
    </Container>
  )
}
OffencesWidget.propTypes = {
  disqualifications: propTypes.array,
  penalties: propTypes.array,
  penaltyPoints: propTypes.number
}

const Container = styled.div`
  width: 100%;
  display: flex;
`
const Box = styled.div`
  flex: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-right: 1rem;
  box-shadow: 0px 3px 6px #00000008;
  font-size: 2rem;
  color: #FE9C2C;
  background: white;
`
const DQBox = styled(Box)`
  ${({ disqualified }) => disqualified ? 'color: #E75A66;' : 'color: #53B761;'}
`
const Count = styled.span`
  text-align: center;
  line-height: 1.35;
  height: 2.75rem;
  width: 2.75rem;
  margin-right: 1rem;
  border: 2px solid;
  border-radius: 50%;
`

export default OffencesWidget
