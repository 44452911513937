import { combineReducers } from 'redux'

const INITIAL_FILES = {
  data: {}
}

export const files = (state = INITIAL_FILES, action) => {
  switch (action.type) {
    case 'ATTACHMENT_DOWNLOAD_START':
      return { ...state, loading: true }
    case 'ATTACHMENT_DOWNLOAD_SUCCESS':
      return {
        ...state,
        loading: false,
        data: { ...state.data, [action.response.id]: action.response }
      }
    case 'ATTACHMENT_DOWNLOAD_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.response
      }
    default:
      return state
  }
}

const INITIAL_INDEX = {
  data: {}
}

const index = (state = INITIAL_INDEX, action) => {
  switch (action.type) {
    case 'ATTACHMENTS_START':
      return { ...state, loading: true, data: {} }
    case 'ATTACHMENTS_CREATE_START':
    case 'ATTACHMENTS_UPDATE_START':
    case 'ATTACHMENTS_DESTROY_START':
      return { ...state, loading: true }
    case 'ATTACHMENTS_SUCCESS':
      return { ...state, loading: false, data: action.response.data }
    case 'ATTACHMENTS_UPDATE_SUCCESS':
    case 'ATTACHMENTS_CREATE_SUCCESS': {
      const data = { ...state.data, ...action.response.data }
      return { ...state, loading: false, data }
    }
    case 'ATTACHMENTS_DESTROY_SUCCESS': {
      const data = { ...state.data }
      delete data[action.response.data]
      return { ...state, loading: false, data }
    }
    case 'ATTACHMENTS_FAILURE':
    case 'ATTACHMENTS_CREATE_FAILURE':
    case 'ATTACHMENTS_UPDATE_FAILURE':
    case 'ATTACHMENTS_DESTROY_FAILURE':
      return { ...state, loading: false, errors: action.response.data }
    default:
      return state
  }
}

const INITIAL_SEARCH = { files: [] }

export const dropboxSearch = (state = INITIAL_SEARCH, action) => {
  switch (action.type) {
    case 'DROPBOX_SEARCH_START':
    case 'DROPBOX_LIST_START':
      return { ...INITIAL_SEARCH, loading: true }
    case 'DROPBOX_LIST_SUCCESS':
      return {
        ...state,
        loading: false,
        files: action.response.entries,
        more: action.response.has_more
      }
    case 'DROPBOX_SEARCH_SUCCESS':
      return {
        ...state,
        loading: false,
        files: action.response.matches,
        more: action.response.more
      }
    case 'DROPBOX_LIST_FAILURE':
    case 'DROPBOX_SEARCH_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.response
      }
    default:
      return state
  }
}

const INITIAL_DROPBOX = {}

export const dropbox = (state = {}, action) => {
  switch (action.type) {
    case 'DROPBOX_REQUEST_START':
      return { ...INITIAL_DROPBOX, loading: true }
    case 'DROPBOX_REQUEST_SUCCESS':
      return { ...state, loading: false, response: action.response }
    case 'DROPBOX_REQUEST_FAILURE':
      return { ...state, loading: false, error: action.response }
    default:
      return state
  }
}

export default combineReducers({
  index,
  files,
  dropbox,
  dropboxSearch
})
