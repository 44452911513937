import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import styled from 'styled-components/macro'
import { find } from 'lodash'

import Api from '../api/call'
import { ORGANISATIONS_UPDATE } from '../root/action-types'
import { useForm, Field } from '../common'
import Thresholds from './Thresholds'
import Notifications from './Notifications'

const INITIAL = {
  motCount: '',
  motPeriod: '',
  insuranceCount: '',
  insurancePeriod: '',
}

const OPTIONS = [
  { value: 'days', label: 'Days' },
  { value: 'weeks', label: 'Weeks' },
  { value: 'months', label: 'Months' },
]

export const Container = styled.div`
  padding: 1rem 2.5rem;
  height: 100vh;
`
const GlobalSettings = () => {
  const { form, updateForm } = useForm({ ...INITIAL })
  const { organisationId, organisation, loading } = useSelector(r => r.users.current)

  useEffect(() => {
    if (loading === false && organisation.insurancePeriod && organisation.motLimit) {
      let [insuranceCount, insurancePeriod] = organisation.insuranceLimit.split(' ')
      insurancePeriod = find(OPTIONS, o => o.value === insurancePeriod)
      let [motCount, motPeriod] = organisation.motLimit.split(' ')
      motPeriod = find(OPTIONS, o => o.value === motPeriod)
      updateForm({ insuranceCount, insurancePeriod, motCount, motPeriod })
    }
  }, [organisation, loading])

  const submit = () => {
    let motLimit = ''
    if (form.motCount && form.motPeriod) {
      motLimit = [form.motCount, form.motPeriod.value].join(' ')
    }
    let insuranceLimit = ''
    if (form.insuranceCount && form.insurancePeriod) {
      insuranceLimit = [form.insuranceCount, form.insurancePeriod.value].join(' ')
    }

    Api({
      request: ORGANISATIONS_UPDATE,
      id: organisationId,
      data: { motLimit, insuranceLimit }
    })
  }

  return (
    <Container className="panel">
      <h1>Global Settings</h1>
      <Notifications />
      <Thresholds />
      <button className="hidden" onClick={submit}>Save</button>
    </Container>
  )
}

export const AlertsAndReminders = ({ inputOnChange, form, updateForm }) => {
  return (
    <div>
      <h2 className="sub">Alerts and reminders</h2>
      <Field label="MOT Due">
        <input
          type="number"
          name="motCount"
          max="1000"
          onChange={inputOnChange}
          value={form.motCount}
        />
        <Select
          options={OPTIONS}
          value={form.motPeriod}
          onChange={motPeriod => updateForm({ motPeriod })}
        />
      </Field>
      <Field label="Insurance Due">
        <input
          type="number"
          name="insuranceCount"
          max="1000"
          onChange={inputOnChange}
          value={form.insuranceCount}
        />
        <Select
          options={OPTIONS}
          value={form.insurancePeriod}
          onChange={insurancePeriod => updateForm({ insurancePeriod })}
        />
      </Field>
    </div>
  )
}

export default GlobalSettings
