import { omit } from 'lodash'

import {
  NOTIFICATIONS,
  NOTIFICATIONS_UPDATE,
  NOTIFICATIONS_ACKNOWLEDGE_ALL,
} from '../root/action-types'

const INITIAL = { index: {} }

export const notifications = (state = INITIAL, action) => {
  switch (action.type) {
    case `${NOTIFICATIONS_UPDATE}_START`:
    case `${NOTIFICATIONS_ACKNOWLEDGE_ALL}_START`:
    case `${NOTIFICATIONS}_START`: {
      return { ...state, loading: true, error: null }
    }
    case `${NOTIFICATIONS_UPDATE}_FAILURE`:
    case `${NOTIFICATIONS_ACKNOWLEDGE_ALL}_FAILURE`:
    case `${NOTIFICATIONS}_FAILURE`: {
      return { ...state, loading: false, error: action.response.data }
    }
    case `${NOTIFICATIONS_ACKNOWLEDGE_ALL}_SUCCESS`:
    case `${NOTIFICATIONS}_SUCCESS`: {
      return { ...state, loading: false, index: action.response.data }
    }
    case `${NOTIFICATIONS_UPDATE}_SUCCESS`: {
      return {
        ...state,
        loading: false,
        index: omit(state.index, Object.keys(action.response.data))
      }
    }
    default: {
      return state
    }
  }
}
