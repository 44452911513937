import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ModalWindow, { Form, Controls } from '../layout/ModalWindow'
import {
  Loaded,
  Conditional,
  useForm,
  Field,
  Status,
  DropdownButton,
  DropdownOption,
} from '../common/'
import Api from '../api/call'
import {
  GROUPS_CREATE,
  GROUPS_UPDATE,
  GROUPS_DESTROY,
  GROUP_SELECT,
  GROUP_EXPAND,
  GROUPS_ASSIGN,
  GROUPS_UNASSIGN,
  FLEET_SET_MODE,
} from '../root/action-types'
import { vehicle, removeVehicle } from '../assets/'

const Action = ({ create, cancel }) => {
  const { mode, groupSelected } = useSelector(r => r.fleet)
  const { selected } = useSelector(r => r.dashboard)
  const add = () => {
    Api({ request: GROUPS_ASSIGN, data: { id: groupSelected, vehicles: selected } })
    cancel()
  }
  const remove = () => {
    Api({ request: GROUPS_UNASSIGN, params: { id: groupSelected, vehicles: selected } })
    cancel()
  }

  if (mode == 'ADD_TO_GROUP') {
    return <React.Fragment>
      <button className="cancel" onClick={cancel}>Cancel</button>
      <button className="confirm" onClick={add}>Add Vehicles</button>
    </React.Fragment>
  }
  if (mode == 'REMOVE_FROM_GROUP') {
    return <React.Fragment>
      <button className="cancel" onClick={cancel}>Cancel</button>
      <button className="confirm" onClick={remove}>Remove Vehicles</button>
    </React.Fragment>
  }
  return <React.Fragment>
    <span>Groups</span>
    <button onClick={create}>Create Group</button>
  </React.Fragment>
}

const Groups = () => {
  const { loading } = useSelector(r => r.fleet)
  const dispatch = useDispatch()
  const [creating, setCreating] = useState(false)
  const [destroying, setDestroying] = useState(false)
  const [updating, setUpdating] = useState(false)
  const actions = {
    destroy: () => setDestroying(true),
    update: () => setUpdating(true),
    addVehicles: () => dispatch({ type: FLEET_SET_MODE, mode: 'ADD_TO_GROUP' }),
    removeVehicles: () => dispatch({ type: FLEET_SET_MODE, mode: 'REMOVE_FROM_GROUP' }),
    cancel: () => dispatch({ type: FLEET_SET_MODE, mode: null })
  }

  return (
    <Style>
      <div className="header">
        <Action create={() => setCreating(true)} cancel={actions.cancel} />
      </div>
      <div className="body">
        <Loaded loading={loading}>
          <Node id="root" depth={0} actions={actions} />
        </Loaded>
      </div>
      <CreateModal
        isOpen={creating}
        closeModal={() => setCreating(false)}
        className="groups-modal"
      />
      <UpdateModal
        isOpen={updating}
        closeModal={() => setUpdating(false)}
        className="groups-modal"
      />
      <DestroyModal
        isOpen={destroying}
        closeModal={() => setDestroying(false)}
        className="groups-modal"
      />
    </Style>
  )
}

const useModal = () => {
  const [submitted, setSubmitted] = useState(false)
  const { groups, groupSelected, loading, success, errors } = useSelector(r => r.fleet)
  const selected = groups[groupSelected] || {} // parent

  const isComplete = submitted && !loading && success

  return { submitted, setSubmitted, selected, isComplete, errors }
}

const DestroyModal = (props) => {
  const { selected, setSubmitted, isComplete, ...state } = useModal()
  const submit = () => {
    Api({ request: GROUPS_DESTROY, id: selected.id })
    setSubmitted(true)
  }
  const close = () => {
    setSubmitted(false)
    setTimeout(() => props.closeModal(), 50)
  }
  if (isComplete) { close() }

  return (
    <Modal
      title="Confirm"
      submit={submit}
      {...props}
      {...state}
      closeModal={close}
    >
      <p>Are you sure you want to destroy {selected.name}?</p>
      <br />
    </Modal>
  )
}

export const Modal = ({ title, submitted, errors, children, submit, loading, ...props }) => (
  <ModalWindow {...props}>
    <Form>
      <h2>{title}</h2>
      <Conditional if={submitted}>
        <Status isError={errors && errors.length > 0} messages={errors} />
      </Conditional>
      {children}
    </Form>
    <Controls>
      <button className="primary" onClick={submit} disabled={loading}>Confirm</button>
      <button className="back" onClick={props.closeModal} disabled={loading}>Cancel</button>
    </Controls>
  </ModalWindow>
)

const UpdateModal = ({ ...props }) => {
  const [name, setName] = useState('')
  const { selected, isComplete, setSubmitted, ...state } = useModal()
  const [activated, setActivated] = useState(false)
  const { groups } = useSelector(r => r.fleet)
  if (props.isOpen && !activated) {
    setName(selected.name)
    setActivated(true)
  }
  const submit = () => {
    Api({ request: GROUPS_UPDATE, id: selected.id, data: { name } })
    setSubmitted(true)
  }
  const close = () => {
    setActivated(false)
    setSubmitted(false)
    setTimeout(() => props.closeModal(), 50)
  }
  if (isComplete) { close() }
  return (
    <Modal
      title="Update Group"
      submit={submit}
      {...state}
      {...props}
      closeModal={close}
    >
      <Field label="Name">
        <input
          value={name}
          onChange={e => setName(e.target.value)}
          disabled={state.loading}
        />
      </Field>
      <Field label="Parent">
        <input
          disabled
          value={selected.parentId ? groups[selected.parentId].name : 'root'}
        />
      </Field>
    </Modal>
  )
}

const CreateModal = ({ ...props }) => {
  const { form, inputProps, reset } = useForm({ name: '' })
  const { selected, isComplete, setSubmitted, ...state } = useModal()
  const submit = () => {
    Api({
      request: GROUPS_CREATE,
      data: { ...form, parentId: selected.id == 'root' ? '' : selected.id }
    })
    setSubmitted(true)
  }
  const close = () => {
    reset()
    setSubmitted(false)
    setTimeout(() => props.closeModal(), 50)
  }
  if (isComplete) { close() }

  return (
    <Modal
      title="Create Group"
      submit={submit}
      {...state}
      {...props}
      closeModal={close}
    >
      <Field label="Name">
        <input {...inputProps('name')} disabled={state.loading}/>
      </Field>
      <Field label="Parent">
        <input disabled value={selected.name} />
      </Field>
    </Modal>
  )
}

const nodeIcon = (children, expanded) => {
  if (children.length == 0) { return 'square' }
  return expanded ? 'plus' : 'minus'
}

const useGroups = (id) => {
  const { groups, groupsByParent, groupsExpanded, groupSelected } = useSelector(r => r.fleet)
  const dispatch = useDispatch()
  const expand = () => dispatch({ type: GROUP_EXPAND, id })
  const select = () => dispatch({ type: GROUP_SELECT, id })

  return {
    self: groups[id] || {},
    children: groupsByParent[id] || [],
    isExpanded: !!groupsExpanded[id],
    isSelected: id == groupSelected,
    expand,
    select
  }
}

const Node = ({ id, depth, actions }) => {
  const { self, children, isExpanded, expand, isSelected, select } = useGroups(id)
  const selected = isSelected ? 'selected' : ''

  return (
    <div className="node">
      <div className={`graphic ${selected}`}>
        <div className="icon">
          <div className={`h-line depth-${depth}`} />
          <FontAwesomeIcon icon={nodeIcon(children, isExpanded)} onClick={expand} />
        </div>
        <div className="v-line" />
      </div>
      <div className="details">
        <div className={`head ${selected}`} onClick={select}>
          <span className={`depth-${depth}`}>{self.name}</span>
          {id == 'root' ? <Counter /> : <Actions actions={actions} />}
        </div>
        <Conditional if={isExpanded}>
          {children.map(c => <Node key={c.id} id={c.id} depth={depth + 1} actions={actions} />)}
        </Conditional>
      </div>
    </div>
  )
}

const Counter = () => {
  const { vehicles } = useSelector(r => r.dashboard)

  return (
    <span className="counter">
      <img src={vehicle} alt="icon" />
      {vehicles.length}
    </span>
  )
}

const Actions = ({ actions }) => (
  <DropdownButton>
    <DropdownOption
      className=""
      svg={vehicle}
      label="Add Vehicles"
      onClick={actions.addVehicles}
    />
    <DropdownOption
      className="remove"
      svg={removeVehicle}
      label="Remove Vehicles"
      onClick={actions.removeVehicles}
    />
    <DropdownOption
      className="warning"
      icon="edit"
      label="Edit"
      onClick={actions.update}
    />
    <DropdownOption
      className="danger"
      icon="trash"
      label="Delete"
      onClick={actions.destroy}
    />
  </DropdownButton>
)

const Style = styled.div`
  background: white;
  min-height: 40%;
  font-size: 0.9rem;
  width: 18rem;

  .header {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border-top: 0.1rem solid #f0f0f1;
    border-bottom: 0.1rem solid #f0f0f1;
    background: #f9f9f9;
    align-items: center;

    button {
      padding: 0.375rem 1rem;
      background: white;
      color: #B9B9B9;
      border: 1px solid #EAEAEA;
      border-radius: 3px;
      cursor: pointer;
      transition: all .3s ease-in-out;
      &:hover {
        color: ${p => p.theme.primaryColor};
        border-color: ${p => p.theme.primaryColor};
      }
      &:focus {
        outline: none;
      }
    }
  }

  .node {
    display: flex;
    padding-top: 1rem;

    .head, svg {
      cursor: pointer;
    }
    svg {
      font-size: 0.8rem;
      width: 0.75rem;
      height: 0.75rem;
      margin-top: 0.15rem;
      top: -0.1rem;
      position: relative;
    }
    .head {
      display: flex;
      justify-content: space-between;
      span {
        padding-left: 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 0.5rem;
        white-space: nowrap;
        max-width: 11rem;
        &.depth-1 { max-width: 9rem; }
        &.depth-2 { max-width: 8rem; }
        &.depth-3 { max-width: 7rem; }
      }
      svg { padding-right: 1rem; }

      button:focus {
        color: ${p => p.theme.primaryColor};
        outline: none;
      }
      .counter {
        color: #8D8D8D;
        margin-right: 1.4rem;
        padding-right: 0;
        img {
          padding-right: 0.5rem;
          width: 0.75rem;
          filter: brightness(150%) grayscale(100%);
        }
      }
    }
    .details {
      width: 100%;
    }
    .selected {
      color: ${p => p.theme.primaryColor};
      .dropdown-content {
        color: black;
      }
    }
    .icon {
      display: flex;
    }
    .v-line {
      border-right: 0.15rem solid #cacaca;
      height: calc(100% - 1.8rem);
      width: 1.3rem;
      margin-top: 0.25rem;
    }
    .h-line {
      border-bottom: 0.15rem solid #cacaca;
      width: 1rem;
      margin-bottom: 0.4rem;
      position: relative;
      right: 0.4rem;
      &.depth-0 { border-color: white; }
    }
  }
`

export default Groups
