import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import propTypes from 'prop-types'

const Errors = ({ children }) => {
  const { invalid } = useSelector(r => r.vehicles.table)
  const link = useRef(null)
  if (!invalid.length) { return null }

  const csv = new Blob(["\ufeff", ["Vehicle Registration", ...invalid].join(",\n")])
  const url = URL.createObjectURL(csv)

  return (
    <div onClick={() => link.current.click()}>
      <a
        href={url}
        download="bad-regs.csv"
        ref={link}
        style={{ display: 'none' }}
      >Download bad regs as csv</a>
      {children}
    </div>
  )
}
Errors.propTypes = {
  children: propTypes.node
}

export default Errors
