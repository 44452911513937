import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'

import Api from '../api/call'
import { ORGANISATIONS_SHOW } from '../root/action-types'
import { carleft, vehicle, licence, calendar } from '../assets/'
import { daysAgo } from '../common/'

const AccountChecker = () => {
  const { organisation = {}, changes, organisationId } = useSelector(r => r.users.current)

  useEffect(
    () => organisationId && Api({ request: ORGANISATIONS_SHOW, id: organisationId }),
    [changes, organisationId]
  )

  if (organisation.accountType != 'trial') { return null }
  return <TrialStatus {...organisation} />
}

const TrialStatus = ({ vehiclesCounter, driversCounter, createdAt }) => {
  const vehiclesLeft = 5 - vehiclesCounter
  const driversLeft = 1 - driversCounter
  const daysLeft = 60 - daysAgo(createdAt)

  return (
    <Style>
      <img className="background" src={carleft} alt="a nice car" />
      <div className="content">
        <h2><span>Vehelix</span> free trial</h2>
        <Info
          icon={vehicle}
          value={vehiclesLeft}
          text={`vehicle${vehiclesLeft == 1 ? '' : 's'} remaining`}
        />
        <Info
          icon={licence}
          value={driversLeft}
          text={`driver${driversLeft == 1 ? '' : 's'} remaining`}
        />
        <Info
          icon={calendar}
          value={daysLeft}
          text={`day${daysLeft == 1 ? '' : 's'} remaining`}
        />
      </div>
    </Style>
  )
}

const Info = ({ icon, value, text }) => (
  <InfoItem>
    <img src={icon} alt="cute little icon" />
    <span className="value">{value}</span>
    <span className="text">{text}</span>
  </InfoItem>
)

const InfoItem = styled.div`
  padding-bottom: 1rem;

  img {
    width: 1.1rem;
    height: 1.1rem;
    top: 0.15rem;
    position: relative;
    padding-right: 1rem;
  }
  .icon {
    padding-right: auto;
    width: 4rem;
  }
  .value {
    font-weight: bold;
    padding-right: 0.1rem;
  }
  .text {
    padding-left: 0.1rem;
  }
`

const Style = styled.div`
  background: white;
  width: 100%;
  display: flex;
  padding: 0 !important;
  margin-top: 1rem;

  font-size: 0.9rem;
  @media (max-width: 1900px) {
    font-size: 0.75rem;
  }

  img.background {
    height: 15rem;
    margin-right: -1rem;
  }
  h2 {
    padding-top: 2rem;
    span { font-weight: bold; }
  }
`

export default AccountChecker
