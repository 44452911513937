import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'

import Api from '../api/call'
import { STRIPE_CONFIRM_DIRECT_DEBIT, STRIPE_CONFIRM_DIRECT_DEBIT_RESET_STORE } from '../root/action-types'

const useConfirmDirectDebit = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { loading, errors } = useSelector(r => r.users.confirmDirectDebit)
  const { pathname, hash } = location
  const params = new URLSearchParams(location.search)
  const sessionId = params.get('session_id')

  useEffect(() => {
    if (sessionId && loading !== false) {
      Api({ request: STRIPE_CONFIRM_DIRECT_DEBIT, data: { sessionId } })
    }
  }, [sessionId])

  useEffect(() => {
    if (loading === false && errors === null) {
      history.push(`${pathname}${hash}`)
    }
  }, [loading, errors])

  useEffect(() => {
    return () => dispatch({ type: STRIPE_CONFIRM_DIRECT_DEBIT_RESET_STORE })
  }, [])

  return { ddLoading: loading, ddErrors: errors }
}

export default useConfirmDirectDebit
