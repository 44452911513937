import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { DASHBOARD_ADD_DRIVER_FILTER, DRIVERS_PENDING } from '../root/action-types'
import GenericReminders from '../dashboard/GenericReminders'
import Api from '../api/call'

export const expiryCount = (date, data) => {
  let count = 0
  for (const d of data) {
    const { validTo } = d
    if (new Date(validTo) < date) count++
  }

  return count
}

const Reminders = () => {
  const dispatch = useDispatch()
  const { matchedDrivers } = useSelector(r => r.dashboard)
  const { count } = useSelector(r => r.drivers.pending)
  useEffect(() => {
    Api({ request: DRIVERS_PENDING })
  }, [])

  const date = new Date()
  date.setMonth(date.getMonth() + 3)

  const expiryFilter = () => {
    dispatch({ type: DASHBOARD_ADD_DRIVER_FILTER, filter: { expiry: date } })
  }

  const data = [
    { count: matchedDrivers.length, title: 'Total Licences' },
    { count: expiryCount(date, matchedDrivers), title: 'Licence Expiry', onClick: expiryFilter },
    { count: count, title: 'Pending' },
  ]

  return (
    <GenericReminders
      title="Licence Overview"
      showDatePicker={false}
      data={data}
    />
  )
}

export default Reminders
