import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom/cjs/react-router-dom"

/*
  Note: This hook is only used for the "unpaid" account type accounts
        This means that the modalMayOpen() should only run when an unpaid account
        accesses an index outside of settings.

  Note: The code for the vehicle slot modal runs on VEHICLES_CONFIRM_SUCCESS in: src/layout/reducer.js
*/
const useLimits = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const currentUser = useSelector(store => store.users.current)

  const modalMayOpen = () => {
    if (!currentUser?.organisation?.accountType) return false
    if (['regular', 'admin', 'trial'].includes(currentUser?.organisation?.accountType)) return false
    if (pathname.includes('dashboard') || pathname.includes('fleet')) return true
    if (pathname.includes('drivers')) return true

    return false
  }

  useEffect(() => {
    if (modalMayOpen()) dispatch({ type: 'LIMIT_OPEN', mode: 'unpaid' })
  }, [pathname])
}

export default useLimits
