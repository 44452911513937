import React, { useCallback, useRef } from 'react'
import { useDispatch } from 'react-redux'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'
import { useDropzone } from 'react-dropzone'
import Papa from 'papaparse'

import { VEHICLES_INVALID, VEHICLES_LOOKUP } from '../root/action-types'
import Api from '../api/call'

export const useParser = () => {
  const dispatch = useDispatch()
  const complete = results => {
    const { valid, invalid } = parseCSV(results.data)
    dispatch({ type: VEHICLES_INVALID, invalid })
    Api({ request: VEHICLES_LOOKUP, params: { registrations: valid } })
  }
  const parse = (files) => files.forEach(f => Papa.parse(f, { complete }))

  return { parse }
}

const VALID_REGISTRATION = /(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)/

const parseCSV = (csv) => {
  const valid = []
  const invalid = []
  csv.flat().filter(reg => reg && !reg.match(/vehicle registration/i))
    .map(reg => reg.toUpperCase().replace(/\s/g, ''))
    .forEach(r => {
      if (r.match(VALID_REGISTRATION)) {
        valid.push(r)
      } else {
        invalid.push(r)
      }
    })
  return { valid, invalid }
}

export const FileSelector = ({ children }) => {
  const input = useRef(null)
  const { parse } = useParser()

  return (
    <div onClick={() => input.current.click()}>
      <input
        type="file"
        multiple
        style={{ display: 'none' }}
        ref={input}
        onChange={e => parse(Array.from(e.target.files))}
      />
      {children}
    </div>
  )
}
FileSelector.propTypes = {
  children: propTypes.node
}

const DragNDrop = () => {
  const { parse } = useParser()
  const onDrop = useCallback(parse, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <Styling {...getRootProps()}>
      <input {...getInputProps()} />
      <FontAwesomeIcon icon="cloud-upload-alt" size="3x" />
      <span>Drag and drop .csv files here</span>
      <span><strong>or</strong></span>
      <button>Browse files</button>
    </Styling>
  )
}
DragNDrop.propTypes = {
  onParse: propTypes.func
}

const Styling = styled.div`
  width: 100%;
  box-sizing: border-box;
  color: white;
  border: 2px dashed #C2CDDA;
  margin-top: 1.5rem;
  height: 100%;
  color: gray;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 768px) and (max-width: 1250px) {
    max-width: unset;
    flex: 1;
    height: auto;
    margin-top: 0;
    margin-left: 2rem;
  }

  svg {
    color: ${props => props.theme.primaryColor};
    margin-bottom: 1.25rem;
  }

  span { margin: .25rem 0; }

  button {
    font-family: ${props => props.theme.font};
    color: white;
    background-color: ${props => props.theme.primaryColor};
    border: 1px solid ${props => props.theme.primaryColor};
    border-radius: 5px;
    cursor: pointer;
    flex-shrink: 0;
    width: 9em;
    cursor: pointer;
    margin-top: 1.25rem;
    padding: 0.7rem 1rem;
  }
`

export default DragNDrop
