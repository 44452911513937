import React from 'react'
import propTypes from 'prop-types'

// For some reason I couldn't get styled components to work here
import './status.scss'

export const Status = ({ isError, messages, children }) => {
  if ((!messages || !messages.length) && !children) { return null }

  return (
    <div className={`form-status ${isError ? 'error' : ''}`}>
      {(messages || []).map((m, i) => <div key={i}>{m}</div>)}
      {children}
    </div>
  )
}
Status.propTypes = {
  isError: propTypes.bool,
  messages: propTypes.array,
  children: propTypes.node
}
