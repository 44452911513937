import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Button, Conditional } from '../common'
import Api from '../api/call'
import { ORGANISATIONS_UPDATE } from '../root/action-types'

const updateThresholds = (state, id) => {
  const data = {}
  const numbers = [1, 2, 3, 4]
  numbers.forEach(i => {
    data[`risk_band_${i}`] = state[i] ? state[i].max : null
    data[`risk_label_${i}`] = state[i] ? state[i].label : null
  })
  data.risk_label_5 = state[5] ? state[5].label : null

  Api({ request: ORGANISATIONS_UPDATE, id, data })
}

const parseThresholds = (org) => {
  const state = {}
  const numbers = [1, 2, 3, 4, 5]
  numbers.forEach(i => {
    state[i] = {
      label: org[`riskLabel${i}`],
      max: org[`riskBand${i}`],
      min: org[`riskBand${i - 1}`] || 0
    }
  })
  const optional = [4, 5]
  optional.forEach(i => {
    if (!state[i].label) { delete state[i] }
  })
  return state
}

const useThresholds = () => {
  const [state, setState] = useState({})
  const [edit, setEdit] = useState(false)
  const { organisation, loading } = useSelector(r => r.users.current)
  useEffect(() => {
    setState(parseThresholds(organisation))
    setEdit(false)
  }, [loading])
  const onChange = ({ target: { name, value } }) => {
    const [id, field] = name.split('-')
    const next = 1 + parseFloat(id)
    const nextState = { ...state }
    nextState[id] = { ...state[id], [field]: value }
    if (state[next] && field == 'max') {
      nextState[next] = { ...state[next], min: value }
    }
    setState(nextState)
  }
  const addNew = () => {
    const last = Math.max(...Object.keys(state).map(parseFloat))
    setState({ ...state, [last + 1]: { label: '', min: state[last].max, max: '' } })
  }
  const save = () => updateThresholds(state, organisation.id)

  return { state, onChange, addNew, edit, setEdit, save }
}

const Threshold = ({ id, label, min, max, onChange, edit }) => (
  <div className="threshold">
    <input value={label} name={`${id}-label`} onChange={onChange} disabled={!edit} />
    <FontAwesomeIcon icon="greater-than" className="offset" />
    <input value={min} name={`${id}-min`} min="0" readOnly disabled type="number" />
    <input
      value={max}
      name={`${id}-max`}
      min="0"
      onChange={onChange}
      type="number"
      disabled={!edit || id == 5}
    />
  </div>
)

const Thresholds = () => {
  const { state, onChange, addNew, edit, setEdit, save } = useThresholds()
  return (
    <Style>
      <h2 className="sub">Penalty points threshold</h2>
      <div className="labels">
        <label>Ranges</label>
        <span className="offset" />
        <label>Min</label>
        <label>Max</label>
      </div>
      {Object.keys(state).map(id =>
        <Threshold key={id} {...state[id]} id={id} onChange={onChange} edit={edit} />
      )}
      <div className="controls">
        <Conditional if={!edit}>
          <Button onClick={() => setEdit(true)} className="off">Edit</Button>
        </Conditional>
        <Conditional if={edit}>
          <Button onClick={save}>Save</Button>
          <Button
            onClick={addNew}
            className="secondary"
            disabled={Object.keys(state).length >= 5}
          >
            Add new range
          </Button>
        </Conditional>
      </div>
    </Style>
  )
}

const Style = styled.div`
  h2 {
    font-size: 0.9rem;
    font-weight: bold;
  }
  label {
    font-size: 0.9rem;
    text-align: left;
    padding-bottom: 0.65rem;
    padding-right: 2.2rem;
  }
  .labels, .threshold {
    display: flex;
  }
  label, input {
    flex: 1;
    max-width: 3.5rem;
    &:first-child {
      max-width: 10rem;
    }
    &:last-child {
      margin-left: 1rem;
    }
  }
  .offset {
    min-width: 3rem;
    padding-top: 1rem;
    color: #B9B9B9;
  }
  input {
    border-radius: 5px;
    border: 1px solid #B9B9B9;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
  }
  button {
    font-size: 0.9rem;
    max-width: 12.2rem;
    padding: 0.97rem 0;
    margin: 0;
    outline: none;
    svg { padding-right: 1rem; }

    &:first-child {
      margin-right: 3rem;
    }
  }
  .controls {
    display: flex;
  }
`

export default Thresholds
