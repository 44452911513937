import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import propTypes from 'prop-types'

import gbFlag from '../assets/vehelix_gb_flag.svg'
import car from '../assets/vehelix_car.svg'
import { Conditional, Field, LoadingAbsolute } from '../common'
import Api from '../api/call'
import { VEHICLES_LOOKUP } from '../root/action-types'

const VALID_REGISTRATION_NO = /(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)/

export const Button = styled.button`
  width: 100%;
  font-family: ${props => props.theme.font};
  color: white;
  background-color: ${props => props.theme.primaryColor};
  border: 1px solid ${props => props.theme.primaryColor};
  border-radius: 5px;
  cursor: pointer;
  flex-shrink: 0;
  transition: all .15s ease-in-out;
  padding: 0.7rem 1rem;

  &:disabled {
    background-color: #B9B9B9;
    border-color: #B9B9B9;
    cursor: default;
  }
`
const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.4rem 2rem 2rem;
  max-width: 360px;
  max-height: 60%;
  border: 2px solid ${props => props.theme.primaryColor};
  text-align: center;

  > h1 {
    color: black;
    font-size: 1.8rem;
    font-weight: normal;
    margin: 0.5rem 1rem 1rem 1rem;
  }
  > div { font-size: 0.9rem; margin-bottom: 1rem; line-height: 150%; }

  @media screen and (min-width: 768px) and (max-width: 1250px) {
    max-width: unset;
    flex: 1;
    max-height: unset;
  }
`
export const StyledField = styled(Field)`
  position: relative;
  margin: 0 auto;
  width: 100%;
  > input {
    box-sizing: border-box;
    height: 4rem;
    width: 100%;
    font-size: 2.2rem;
    font-weight: bold;
    font-stretch: expanded;
    padding: 0.75rem 0 0.75rem 3rem;
  }

  &::before {
    position: absolute;
    content: url(${gbFlag});
    height: 1.75rem;
    width: 1.75rem;
    z-index: 200;
    left: 0.5rem;
    top: 16%;
  }

  + .error {
    font-size: 0.8rem;
    margin-top: -10px;
    text-align: center;
    bottom: 0;
    padding: 0.7rem 1rem;
    color: #FF0000;
    background: #FFE6E6;
    border: 1px solid #FFE6E6;
    border-radius: 5px;
    text-overflow: ellipsis;
  }
`
const RegisterVehicle = () => {
  const [reg, setReg] = useState("")
  const { data, loading } = useSelector(store => store.vehicles.table)
  const [error, setError] = useState("")

  const validateReg = () => {
    if (data[reg]) {
      setError("Registration number already exists")
    } else if (!reg.match(VALID_REGISTRATION_NO)) {
      setError("Registration number is not valid")
    } else {
      return true
    }
  }

  const registerVehicle = () => {
    Api({ request: VEHICLES_LOOKUP, params: { registrations: [reg] } })
  }

  useEffect(() => {
    if (reg && !loading) { setReg("") }
  }, [loading])

  const handleChange = val => {
    if (/^[A-Z0-9]*$/.test(val)) setReg(val)
    setError("")
  }
  const onKeyPress = ({ key }) => {
    key == 'Enter' && submit()
  }
  const submit = () => {
    validateReg() && registerVehicle()
  }

  return (
    <Container className="register-vehicle-container">
      <Conditional if={loading}>
        <LoadingAbsolute />
      </Conditional>
      <img src={car} alt="Register vehicle car icon" />
      <h1>Register a vehicle</h1>
      <div>Enter the registration number (number plate) of the vehicle. For example, CU57ABC</div>
      <StyledField>
        <input
          type="text"
          value={reg}
          onChange={e => handleChange(e.target.value.toUpperCase())}
          onKeyPress={onKeyPress}
        />
      </StyledField>
      <Conditional if={error}>
        <div className="error">{error}</div>
      </Conditional>
      <Button onClick={submit}>Add Vehicle</Button>
    </Container>
  )
}
RegisterVehicle.propTypes = {
  regs: propTypes.array,
  setRegs: propTypes.func
}

export default RegisterVehicle
