import React from 'react'

import { DASHBOARD } from '../root/action-types'
import { GenericDashboard } from '../dashboard/Dashboard'
import Reminders from './Reminders'
import Table from './Table'
import Totals from './Totals'
import LicenceRisk from '../dashboard/LicenceRisk'

const DriverDashboard = () => (
  <GenericDashboard
    TopLeft={LicenceRisk}
    Right={Totals}
    Bottom={Reminders}
    Table={Table}
    request={DASHBOARD}
    mode="driver"
  />
)

export default DriverDashboard
