import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { formatDatetime, Conditional, Loaded, ConfirmPromptButton, Status } from '../common/'
import { useAttachments, UploadButton, Element } from './Attachment'
import {
  ATTACHMENTS_CREATE, ATTACHMENTS_UPDATE, ATTACHMENTS_DESTROY
} from '../root/action-types'
import Api from '../api/call'

export const AttachmentsTab = ({ parentType, parentId }) => {
  const [text, setText] = useState('')
  const [file, setFile] = useState(null)
  const [id, setId] = useState(null)

  const { attachments, loading, errors } = useAttachments({ parentType, parentId })

  const save = () => {
    Api({
      request: id ? ATTACHMENTS_UPDATE : ATTACHMENTS_CREATE,
      id,
      data: {
        parentType,
        parentId,
        text,
        dropboxId: file ? file.id : null
      }
    })
    setText('')
    setFile(null)
    setId(null)
  }

  const edit = (id) => {
    const active = id && attachments.find(a => a.id == id)
    setText(id ? active.text : '')
    setFile(id ? active.file : null)
    setId(id)
  }

  const items = attachments.filter(a => a.id != id).sort(
    (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
  )
  return (
    <Styling>
      <h4>Attachments</h4>
      <Status isError={errors && errors.length > 0} messages={errors} />
      <Loaded loading={loading}>
        <textarea
          value={text}
          onChange={e => setText(e.target.value)}
          placeholder="Note..."
        />
        <div className="attach">
          <UploadButton update={setFile}>
            <FontAwesomeIcon icon="paperclip" />
            {file ? file.name : 'Attach file'}
          </UploadButton>
          <span>Max 2mb</span>
          <button onClick={save} className="save">{id ? 'Update' : 'Add'}</button>
          <Conditional if={id}>
            <button onClick={() => edit(null)} className="cancel">Cancel</button>
          </Conditional>
        </div>
        {items.map(a => <Item key={a.id} {...a} edit={edit} />)}
      </Loaded>
    </Styling>
  )
}

const destroy = (id) => {
  Api({ request: ATTACHMENTS_DESTROY, id })
}

const Item = ({ updatedAt, text, id, dropboxId, file, edit }) => (
  <div className="item">
    <div className="date">
      {formatDatetime(updatedAt)}
    </div>
    <div className="main">
      <p>{text}</p>
      <div className="file-wrapper">
        <Conditional if={dropboxId}>
          <Element file={file} />
        </Conditional>
      </div>
    </div>
    <div className="controls">
      <span
        className="edit"
        onClick={() => edit(id)}
      >
        <FontAwesomeIcon icon="edit" />Edit
      </span>
      <ConfirmPromptButton
        className="delete"
        onClick={() => destroy(id)}
        text="Are you sure you want to delete this attachment?"
      >
        <FontAwesomeIcon icon="trash" />Delete
      </ConfirmPromptButton>
    </div>
  </div>
)

const Styling = styled.div`
  padding: 2rem;

  h4 {
    font-size: 16px;
    font-weight: bold;
    margin-top: 0;
  }
  textarea {
    padding: 1rem;
    width: calc(100% - 2rem);
    border-radius: 5px;;
    border-color: #cdcdcd;
    border-width: 0.15rem;
  }
  button {
    padding: 0.5rem;
    background: white;
    border: 0.15rem solid #eff1f6;
    border-radius: 5px;;
    font-size: 14px;
    cursor: pointer;

    &:disabled {
      border-color: #cdcdcd !important;
      cursor: no-drop;
    }
    svg {
      color: ${p => p.theme.primaryColor};
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  .attach {
    margin-top: 1rem;
    margin-bottom: 3rem;

    button {
      border: 0.15rem solid ${p => p.theme.primaryColor};
    }
    .save {
      float: left;
      width: 8rem;
      background: ${p => p.theme.primaryColor};
      color: white;
      margin-right: 1rem;
    }
    .cancel {
      float: left;
      width: 8rem;
      color: ${p => p.theme.primaryColor};
      background: white;
      margin-right: 1rem;
    }
    span {
      padding-left: 1rem;
      color: #b9b9b9;
    }
  }
  .item {
    border-top: 0.15rem solid #eff1f6;
    margin-left: -3rem;
    margin-right: -3rem;
    padding: 1rem;
    padding-left: 3rem;
    padding-right: 3rem;
    display: flex;
    flex-direction: row;

    &:last-child {
      border-bottom: 0.15rem solid #eff1f6;
    }
    .main {
      padding-left: 3rem;
      flex-grow: 1;
    }
    .date, .controls {
      width: 10rem;
      flex-shrink: 0;
    }
    .controls {
      display: flex;
      flex-direction: column;

      span {
        opacity: 0.5;
        cursor: pointer;
      }
      span:hover {
        opacity: 1;
      }
      .edit {
        color: orange;
        padding-bottom: 0.5rem;
      }
      .delete {
        color: crimson;
      }
      svg {
        padding-right: 0.5rem;
      }
    }
    p {
      margin-top: 0;
      white-space: pre;
    }
    button {
      width: fit-content;
    }
  }
`
