import React from 'react'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from 'react-modal'
import styled from 'styled-components/macro'

import "./ModalWindow.scss"

if (process.env.NODE_ENV !== 'test') {
  // Prevents console warnings in production.
  // Not required for testing, and causes test failures if present.
  Modal.setAppElement('#root')
}

const ModalWindow = ({ isOpen, closeModal, className, children }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={closeModal}
    className={"modal-window " + className}
    overlayClassName="modal-overlay"
    shouldCloseOnOverlayClick={true}
    ariaHideApp={ !process.env.NODE_ENV === 'test' } // Don't whinge in test
  >
    <div className="close-modal" onClick={closeModal}>
      <FontAwesomeIcon icon={['fas', 'times']} size="lg" fixedWidth />
    </div>
    <div className="modal-content">
      {children}
    </div>
  </Modal>
)
ModalWindow.propTypes = {
  isOpen: propTypes.bool,
  closeModal: propTypes.func,
  className: propTypes.string,
  children: propTypes.node,
}

export const Form = styled.div`
  width: 100%;
`

export const Controls = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  button {
    width: 8rem;
  }
  .primary {
    background: ${p => p.theme.primaryColor};
  }
  .back {
    color: black !important;
    background: #EBECF3;
  }


`

export default ModalWindow
