import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import propTypes from 'prop-types'

import { Button } from '../common'
import useConfirmDirectDebit from '../settings/useConfirmDirectDebit'
import { CURRENT_USER } from '../root/action-types'
import Api from '../api/call'

import { checkCircle } from '../assets/'

const SuccessContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  > h1 {
    color: black;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-weight: 400;
    font-size: 2.2rem;
    text-align: center;
  }
  > div.success-message {
    max-width: 55ch;
    font-size: 0.9rem;
    margin-bottom: 3em;
    line-height: 150%;
  }
  .check-circle {
    width: 6rem;
    height: 6rem;
    color: #53B761;
    display: block;
    margin: 0 auto;
  }

  @media screen and (max-width: 1023px) {
    min-height: calc(100vh + 2rem);
    > div { width: 100%; }
  }
`
export function SignupSuccess () {
  const { ddLoading } = useConfirmDirectDebit()
  const history = useHistory()

  const { active, loading } = useSelector(r => r.users.current)

  useEffect(() => {
    if (active) history.push('/welcome')
  }, [active])

  const handleClick = () => {
    if (!active) Api({ request: CURRENT_USER })
    history.push('/welcome')
  }

  return (
    <SuccessContainer>
      <img src={checkCircle} alt="Account Created" className="check-circle" />
      <h1>Account created</h1>
      <div className="success-message">
        Your account has been created and your payment method has been successfully registered.
        To begin adding drivers and vehicles to your fleet, please click the button below.
      </div>
      <Button disabled={loading || ddLoading} onClick={handleClick}>Welcome to Vehelix</Button>
    </SuccessContainer>
  )
}
SignupSuccess.propTypes = {
  id: propTypes.string
}
