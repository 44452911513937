import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import propTypes from 'prop-types'

import { filterList } from '../assets/'
import { DASHBOARD_CLEAR_FILTER, DASHBOARD_CLEAR_DRIVER_FILTER } from '../root/action-types'

const Filters = ({ mode }) => {
  const dashboard = useSelector(r => r.dashboard)
  const filters = mode == 'driver' ? dashboard.driverFilters : dashboard.filters
  const dispatch = useDispatch()
  const remove = (name) => {
    dispatch({
      type: mode == 'driver' ? DASHBOARD_CLEAR_DRIVER_FILTER : DASHBOARD_CLEAR_FILTER,
      name
    })
  }
  return (
    <Styling>
      <div className="label">
        <img src={filterList} alt="Filters" />
        <span>Filters</span>
      </div>
      {Object.entries(filters).map(([k, v]) =>
        <Filter name={name(k, v)} key={k} onClick={() => remove(k)} />
      )}
    </Styling>
  )
}

const BOOL = ['true', 'false']

const name = (k, v) => {
  if (k === "mot") return "MOT Fail"
  if (k === "motExpiryDate") return "MOT Due Soon"
  if (k === "expiry") return "Licence Expiry"
  if (k === 'risk') return `${v} risk`
  if (BOOL.includes(String(v).toLowerCase())) {
    return `${k}: ${v}`
  }
  return v
}

const Filter = ({ name, onClick }) => (
  <div className="filter">
    <span className="name">{name}</span>
    <FontAwesomeIcon icon="times" onClick={onClick} />
  </div>
)
Filter.propTypes = {
  name: propTypes.string,
  onClick: propTypes.func
}

const Styling = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: .75rem;
  margin: -1rem;
  position: sticky;
  top: -1rem;
  z-index: 99;
  background: #f5f6f9;

  .label {
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    img {
      padding-right: .5rem;
    }
    span {
      position: relative;
    }
  }
  .filter {
    background: #43386C;
    border-radius: 5px;;
    color: white;
    padding: 0 0.5rem;
    height: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 1rem;
    display: flex;
    align-items: center;
    transition: opacity .3s ease-in-out;
    font-size: .8rem;

    &:hover { opacity: .7; }

    span {
      padding-right: 1.5rem;
      margin-top: -2px;
    }
    svg {
      cursor: pointer;
    }
  }
`

export default Filters
