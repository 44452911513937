import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import styled from 'styled-components/macro'

import Api from '../api/call'
import { useForm, Field, Status, Loading } from '../common'
import { USERS_CREATE, USERS_UPDATE, SELECT_USER } from '../root/action-types'

const validateForm = ({ firstName, lastName, email }) => (
  firstName && lastName && String(email).match(/@/)
)
const BLANK = { firstName: '', lastName: '', email: '' }

const NewUser = () => {
  const { userCreated, userHasUpdated, loading, errors } = useSelector(r => r.users.crud)
  const { users } = useSelector(r => r.users.index)
  const { id } = useSelector(r => r.users.selected)
  const { form, inputProps, reset, updateForm } = useForm(BLANK)
  const isValid = validateForm(form)
  const dispatch = useDispatch()

  const submit = () => {
    if (id) {
      Api({ request: USERS_UPDATE, id, data: form })
    } else {
      Api({ request: USERS_CREATE, data: form })
    }
  }

  const cancel = () => {
    dispatch({ type: SELECT_USER, id: undefined })
    reset()
  }

  useEffect(cancel, [userCreated, userHasUpdated])

  useEffect(() => {
    if (id) {
      const user = get(users, 'data', []).find(u => u.id === id)
      if (user) {
        updateForm({ firstName: user.firstName, lastName: user.lastName, email: user.email })
      }
    }
  }, [id])

  useEffect(() => {
    return (() => {
      dispatch({ type: `${USERS_CREATE}_RESET_STORE` })
      dispatch({ type: `${USERS_UPDATE}_RESET_STORE` })
    })
  }, [])

  return (
    <Container className="panel">
      <h1>User Management</h1>
      <Status isError={!!errors}>
        {(userCreated || userHasUpdated) ? 'User details saved' : errors}
      </Status>
      {loading ? (<Loading />) : null}
      <h3>Invite Users</h3>
      <p>
        Any users added to the system will receive an invitation email
        containing an Account Creation link.
        This link will allow the invited user to set up their account
        passwordfor the first-time use.
      </p>
      <div className="fields">
        <Field label="First Name">
          <input {...inputProps('firstName')} value={form.firstName} />
        </Field>
        <Field label="Last Name">
          <input {...inputProps('lastName')} />
        </Field>
        <Field label="Email">
          <input {...inputProps('email')} />
        </Field>
        <div className="controls">
          <button
            disabled={!isValid}
            className="primary"
            onClick={submit}
          >{id ? 'Update' : 'Add'} user</button>
          <span
            className={`cancel ${isValid ? '' : 'invalid'}`}
            onClick={cancel}
          >Cancel</span>
        </div>
      </div>
    </Container>
  )
}

const Container = styled.div`
  padding: 1rem 2.5rem;

  h3 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  p {
    font-size: 0.9rem;
    max-width: 40rem;
  }
  .fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 1rem;
  }
  .field, .controls {
    flex: 1;
    min-width: 40%;
    max-width: 48%;
  }

  .controls {
    padding-top: 1.5rem;

    button {
      background: ${p => p.theme.primaryColor};
      color: white;
      padding: 0.95rem 1rem;
      width: 50%;
      color: white;
      border: 1px solid ${p => p.theme.primaryColor};
      border-radius: 3px;
      cursor: pointer;
      transition: all .3s ease-in-out;
      font-size: 0.9rem;
      margin-right: 2rem;
    }
    button:disabled {
      background: #B9B9B9;
      border-color: #B9B9B9;
      cursor: not-allowed;
    }
    button:focus {
      outline: none;
    }
    .cancel {
      color: #B9B9B9;
      font-size: 0.9rem;
      cursor: pointer;

      &.invalid {
        display: none;
      }
    }
  }
`

export default NewUser
