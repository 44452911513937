import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'

import { Loading, Button } from '../common'
import { Smallprint } from './Signup'
import { Costs } from './Costs'
import { CURRENT_USER, STRIPE_FETCH_PRICES } from '../root/action-types'
import Api from '../api/call'

import { ReactComponent as VehelixLogo } from '../assets/vehelix_logo-01.svg'
import { ReactComponent as CarIcon } from '../assets/vehicle.svg'
import { ReactComponent as CardIcon } from '../assets/document.svg'

// This PaymentDetails component is shown during onboarding, or when
// confirming an account after logging in without an activated account.
const PaymentDetails = ({ smallprint, setStage }) => {
  const { id } = useSelector(r => r.users.current)
  const { loaded, prices } = useSelector(r => r.users.prices)

  useEffect(() => {
    if (!id) Api({ request: CURRENT_USER })
  }, [id])

  useEffect(() => {
    Api({ request: STRIPE_FETCH_PRICES })
  }, [])

  return (
    <PaymentDetailsContainer>
      <h1>Vehelix Trial Details</h1>
      <Smallprint>{smallprint || 'Smart, Intuitive Fleet Management Software'}</Smallprint>
      <div className="payment-details-panels">
        {(!id || !loaded) ? (
          <div className="loading-wrapper">
            <Loading />
          </div>
        ) : (
          <>
            <div className="details-wrap">
              <div className="details-title">Details</div>
              <div className="details-body">
                <div className="details-body-logo-wrap">
                  <VehelixLogo className="details-body-logo-src" />
                </div>
                <div className="details-body-offer">
                  <div className="details-body-offer-wrap">
                    <div className="offer-wrap">
                      <CarIcon className="offer-icon" />
                      <div className="offer-text"><span className="offer-number">5</span>Free Vehicles</div>
                    </div>
                    <div className="offer-wrap">
                      <CardIcon className="offer-icon" />
                      <div className="offer-text"><span className="offer-number">1</span>Free Vehicles</div>
                    </div>
                  </div>
                </div>
                <div className="details-body-small">
                Your free trial gives you the ability to add five vehicles and one driver to your organisation. To add more, you'll need to enter your card details and be charged per vehicle or driver added.
                </div>
              </div>
            </div>
            <Costs costs={prices} />
          </>
        )}
      </div>
      <div className="create-wrap">
        <div className="create-text">Please note, once a vehicle or driver has been added you are unable to swap them out for another - each entry is fixed. Deleting a vehicle or driver does not free up a free space. To get started building out your fleet in Vehelix, press the button below.</div>
        <Button onClick={() => setStage('SIGNUP_SUCCESS')}>Create Account</Button>
      </div>
    </PaymentDetailsContainer>
  )
}

const PaymentDetailsContainer = styled.div`
  > h1 {
    color: black;
    margin-bottom: 1.1rem;
    font-weight: 400;
    font-size: 2.2rem;
    text-align: center;
  }

  .payment-details-panels {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;

    @media screen and (max-width: 1023px) {
      flex-direction: column;
    }
  }

  .loading-wrapper {
    flex: 1;
    padding-left: 5%;

    @media screen and (max-width: 1023px) {
      padding-left: 0;
    }
  }

  .details-wrap {
    display: flex;
    flex-direction: column;
    width: 50%;
    text-align: left;
    padding-right: 5%;

    @media screen and (max-width: 1023px) {
      width: 100%;
    }
  }

  .details-title {
    padding-bottom: 0.75rem;
    font-weight: 700;
  }

  .details-body {
    display: flex;
    flex: 1;
    flex-direction: inherit;
    align-items: center;
    justify-content: center;
    background-color: #E2E2E2;
    border-radius: 5px;
    
    .details-body-logo-wrap {
      display: flex;
      height: 30%;
    }

    .details-body-logo-src {
      width: 10vw;
      min-width: 100px;
      margin: 14px 0;
      
      /* Importing the SVG as a react component also allows the editing of the SVG with CSS */
      .st0 { 
        fill: black;
      }
    }

    .details-body-offer {
      display: flex;
      height: 35%;
      width: 85%;
      padding: 10px;
      border-radius: 5px;
      background-color: white;

      .details-body-offer-wrap {
        flex: 1;
        border: 2px dotted orange;
        border-radius: 5px;
        padding: 10px 0;

        .offer-wrap {
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;
          height: 50%;
          font-size: 20px;

          .offer-icon {
            width: 2vw;
            height: 2vh;
          }

          .offer-number {
            margin: 0 4px;
            font-weight: bold;
          }
        }
      }
    }

    .details-body-small {
      display: flex;
      align-items: center;
      height: 35%;
      width: 85%;
      padding: 10px;
      font-size: 12px;
      text-align: center;
      line-height: 16px;
    }
  }

  .create-wrap {
    .create-text {
      font-size: 12px;
      margin: 10vh 10%;
    }
  }
`

export default PaymentDetails
