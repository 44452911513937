import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get } from 'lodash'

import Api from '../api/call'
import { InfoTable, formatDate, Loading, ConfirmControls } from '../common/'
import { USERS, USERS_DESTROY, SELECT_USER, SELECT_USER_DESTROY } from '../root/action-types'
import ModalWindow from '../layout/ModalWindow'

const UsersTable = () => {
  const { id: currentId } = useSelector(r => r.users.current)
  const { users, loaded, loading } = useSelector(r => r.users.index)

  const data = get(users, 'data', []).filter(u => u.id !== currentId)

  useEffect(() => {
    if (loaded !== true) Api({ request: USERS })
  }, [])

  return (
    <Style className="panel">
      <h2>Users</h2>
      {loading === true ? (
        <Loading />
      ) : (
        <InfoTable
          attributes={ATTRIBUTES}
          data={data}
        />
      )}
      <Destroy />
    </Style>
  )
}

const Destroy = () => {
  const { destroying } = useSelector(r => r.users.selected)
  const { users } = useSelector(r => r.users.index)
  const dispatch = useDispatch()
  const cancel = () => dispatch({ type: SELECT_USER_DESTROY, id: null })
  const confirm = () => Api({ request: USERS_DESTROY, id: destroying })
  const victim = users[destroying] || {}

  return (
    <ModalWindow isOpen={!!destroying} closeModal={cancel}>
      <h4>Are you sure you want to delete {victim.firstName} {victim.lastName}?</h4>
      <ConfirmControls confirm={confirm} cancel={cancel} />
    </ModalWindow>
  )
}

const Controls = ({ value }) => {
  const dispatch = useDispatch()
  const edit = () => dispatch({ type: SELECT_USER, id: value })
  const destroy = () => dispatch({ type: SELECT_USER_DESTROY, id: value })

  return (
    <React.Fragment>
      <FontAwesomeIcon icon="pencil-alt" onClick={edit}/>
      <FontAwesomeIcon icon="times" onClick={destroy} />
    </React.Fragment>
  )
}

const ATTRIBUTES = {
  email: { name: 'Email', className: "email" },
  firstName: { name: 'First Name' },
  lastName: { name: 'Last Name' },
  createdAt: { name: 'Date added', format: formatDate },
  id: { name: ' ', className: 'controls', Component: Controls }
}

const Style = styled.div`
  border-top: 1rem solid #F5F6F9;
  h2 {
    padding: 1rem 2.5rem;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  td, th {
    padding-left: 2.5rem;
  }
  .email {
    color: ${p => p.theme.primaryColor};
  }
  .controls {
    svg {
      padding-left: 2rem;
      cursor: pointer;
    }
  }
`

export default UsersTable
