import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import styled from 'styled-components/macro'

import { Modal } from './Groups'
import { useForm, Field, Conditional } from '../common/'
import Api from '../api/call'
import { TAGS_CREATE, TAGS_DESTROY, TAG_SELECT } from '../root/action-types'

const Tags = () => {
  const { tags } = useSelector(r => r.fleet)
  const [creating, setCreating] = useState(false)
  const [destroying, setDestroying] = useState(null)

  const dispatch = useDispatch()
  const select = id => dispatch({ type: TAG_SELECT, id })
  const deselect = () => dispatch({ type: TAG_SELECT, id: '' })

  return (
    <Style>
      <div className="tags-header">
        <div>Tags</div>
        <button onClick={() => setCreating(true)}>Create Tag</button>
      </div>
      <div className="tags-body">
        {Object.values(tags).map(t => (
          <Tag key={t.id} tag={t} select={select} setDestroying={setDestroying} />
        ))}
        <Conditional if={Object.values(tags).length == 0}>
          <span className="no-tags">You have no Tags yet.</span>
        </Conditional>
      </div>
      <CreateModal
        isOpen={creating}
        closeModal={() => setCreating(false)}
        className="tags-modal"
      />
      <DestroyModal
        isOpen={destroying}
        closeModal={() => setDestroying(null)}
        className="tags-modal"
        deselect={deselect}
      />
    </Style>
  )
}

const Tag = ({ tag, select, setDestroying }) => {
  const { tagSelected } = useSelector(r => r.fleet)
  const selected = tag.id === tagSelected ? ' selected' : ''

  const destroy = e => {
    e.stopPropagation()
    setDestroying(tag.id)
  }

  return (
    <div className={`tag${selected}`} onClick={() => select(tag.id)}>
      {tag.name}
      <div className="delete-tag" title="Delete" onClick={destroy}></div>
    </div>
  )
}

const useModal = () => {
  const [submitted, setSubmitted] = useState(false)
  const { tags, loading, success, errors } = useSelector(r => r.fleet)

  const isComplete = submitted && !loading && success

  return { tags, submitted, setSubmitted, isComplete, errors }
}

const CreateModal = (props) => {
  const { form, inputProps, reset } = useForm({ name: '' })
  const { isComplete, setSubmitted, errors, ...state } = useModal()
  const submit = () => {
    Api({
      request: TAGS_CREATE,
      data: { name: form.name.trim() }
    })
    setSubmitted(true)
  }
  const close = () => {
    reset()
    setSubmitted(false)
    setTimeout(() => props.closeModal(), 50)
  }
  if (isComplete) {
    reset()
    setSubmitted(false)
    setTimeout(() => props.closeModal(), 50)
  }

  // Acceptance criteria
  const regex = /is already taken/
  const message = 'Tag already exists'
  const errorMsgs = (errors || []).map(e => (
    e.match(regex) ? message : e
  ))

  return (
    <Modal
      title="Create Tag"
      submit={submit}
      errors={errorMsgs}
      {...state}
      {...props}
      closeModal={close}
    >
      <Field label="Name">
        <input {...inputProps('name')} disabled={state.loading}/>
      </Field>
    </Modal>
  )
}

const DestroyModal = (props) => {
  const [name, setName] = useState('')
  const { tags, setSubmitted, isComplete, ...state } = useModal()

  useEffect(() => {
    setName(get(tags[props.isOpen], 'name'), '')
  }, [props.isOpen])

  const submit = () => {
    Api({ request: TAGS_DESTROY, id: props.isOpen })
    setSubmitted(true)
  }
  const close = () => {
    props.deselect()
    setSubmitted(false)
    setTimeout(() => props.closeModal(), 50)
  }
  if (isComplete) {
    close()
  }

  return (
    <Modal
      title="Confirm"
      submit={submit}
      {...props}
      {...state}
      closeModal={close}
    >
      <p>Are you sure you want to destroy {name}?</p>
      <p>The tag will be removed from any associated vehicle</p>
      <br />
    </Modal>
  )
}

const Style = styled.div`
  background: #FAFBFC;
  height: 40%;
  width: 18rem;
  font-size: 0.9rem;

  .tags-header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.125rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-top: 1px solid #EFF1F6;
    border-bottom: 1px solid #EFF1F6;

    > button {
      padding: 0.375rem 1rem;
      background: white;
      color: #B9B9B9;
      border: 1px solid #EAEAEA;
      border-radius: 3px;
      cursor: pointer;
      transition: all .3s ease-in-out;

      &:hover {
        border-color: ${p => p.theme.primaryColor};
        color: ${p => p.theme.primaryColor};
      }
    }
  }

  .no-tags {
    font-style: italic;
    color: #B9B9B9;
  }

  .tags-body {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 1rem 0 0.5rem 1rem;
    height: calc(100% - 3.125rem);
    overflow-y: auto;

    // This works around padding bottom not showing on FF with overflow auto
    // https://stackoverflow.com/a/29987552
    @-moz-document url-prefix() {
      &::after {
        content: '';
        height: 0.5em;
        width: 100%;
        display: block;
      }
    }

    .tag {
      position: relative;
      flex: 0 max-content;
      height: max-content;
      background: #EFF1F6;
      padding: 0.5rem 1rem;
      border-radius: 3px;
      cursor: pointer;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;

      &.selected {
        color: white;
        background: ${p => p.theme.primaryColor};
      }

      &:hover .delete-tag {
        position: absolute;
        width: 1rem;
        height: 1rem;
        top: -0.5rem;
        right: -0.5rem;
        border: 2px solid ${p => p.theme.primaryColor};
        border-radius: 100%;
        background-color: white;

        &::after {
          content: 'x';
          color: ${p => p.theme.primaryColor};
          font-size: 0.96rem;
          display: block;
          position: absolute;
          right: 4px;
          top: -2px;
        }
      }
    }
  }
`

export default Tags
