import React, { Component } from 'react'
import { get } from 'lodash'

import PagingControl from './PagingControl'

class PagingRow extends Component {
  renderPagingInfo = () => {
    const { page, data, count, total } = this.props
    const pageSize = get(this.props.pageSize, 'value', null)
    const start = (count == 0 ? 0 : (page * pageSize) - pageSize + 1)
    const end = (count == 0 ? 0 : start + data.length - 1)
    return (
      <span className='info'>
        Showing { start } to { end } of { count } ({ total } total) entries
      </span>
    )
  }

  render = () => (
    <div className='table pagingRow'>
      { this.renderPagingInfo() }
      <PagingControl {...this.props} />
    </div>
  )
}

export default PagingRow
