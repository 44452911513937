import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

import { store } from './root/store'
import history from './root/browser-history'
import stripe from './root/stripe'

import * as serviceWorker from './serviceWorker'
import App from './root/App'

import './index.css'

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Elements stripe={stripe}>
        <DndProvider backend={HTML5Backend}>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </DndProvider>
      </Elements>
    </Router>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
