import React, { useState } from 'react'
import styled from 'styled-components/macro'

import ModalWindow from '../layout/ModalWindow'

export const ConfirmPromptButton = ({ onClick, text, children, className }) => {
  const [open, setOpen] = useState(false)
  const close = () => setOpen(false)

  return (
    <React.Fragment>
      <span className={className} onClick={() => setOpen(true)}>
        {children}
      </span>
      <ModalWindow
        isOpen={open}
        closeModal={close}
      >
        <h4>{text}</h4>
        <ConfirmControls confirm={onClick} cancel={close} />
      </ModalWindow>
    </React.Fragment>
  )
}

export const ConfirmControls = ({ confirm, cancel }) => (
  <Controls>
    <button onClick={confirm} className="primary">Yes</button>
    <button onClick={cancel} className="back">Cancel</button>
  </Controls>
)

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 14rem;

  button {
    width: 8rem !important;
  }
  .primary {
    background: ${p => p.theme.primaryColor};
  }
  .back {
    background: white;
    border: 0.2rem solid ${p => p.theme.primaryColor} !important;
    color: ${p => p.theme.primaryColor} !important;
  }
`
