import React from 'react'
import { useSelector } from 'react-redux'

import { TabBodyContainer } from '../Show'
import { InfoTable, formatDate } from '../../common'

export const Audit = () => {
  const { auditLog } = useSelector(r => r.vehicles.show.data)

  const data = auditLog.sort((a, b) => new Date(b.date) - new Date(a.date))

  return (
    <TabBodyContainer>
      <InfoTable
        attributes={ATTRIBUTES}
        data={data}
      />
    </TabBodyContainer>
  )
}

const ATTRIBUTES = {
  date: { format: formatDate },
  action: { name: 'Action' },
  triggerUser: { name: 'User' }
}
