import { useState, useEffect, useRef } from 'react'
import Papa from 'papaparse'
import { omit } from 'lodash'

const useDownloadCSV = (data, OMIT = []) => {
  const link = useRef(null)
  const [url, setUrl] = useState(null)

  useEffect(() => {
    if (url !== null) {
      link.current.click()
      setUrl(null)
    }
  }, [url])

  const buildFile = () => {
    const selected = omit(data, OMIT)
    const selectedData = Papa.unparse({
      fields: Object.keys(selected),
      data: Object.values(selected)
    })
    const csv = new Blob(["\ufeff", ...selectedData])
    setUrl(URL.createObjectURL(csv))
  }

  return { buildFile, link, url }
}

export default useDownloadCSV
