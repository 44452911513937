import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import propTypes from 'prop-types'

import { useForm, Field, Status, Conditional, Loading } from '../common/'
import Api from '../api/call'
import { DRIVERS_INVITE, DRIVERS_INVITE_RESET_STORE } from '../root/action-types'

const BLANK = {
  message: "",
  email1: "",
  email2: "",
  email3: "",
  email4: ""
}

const Email = ({ error, ...props }) => (
  <Field error={error}>
    <input {...props} type="email" id={props.name} />
    <Icon icon="user" />
  </Field>
)
Email.propTypes = { error: propTypes.bool }

const apiSubmit = (form) => {
  Api({
    request: DRIVERS_INVITE,
    data: {
      message: form.message,
      emails: [1, 2, 3, 4].map(n => form[`email${n}`])
    }
  })
}

const Invite = () => {
  const [submitted, setSubmitted] = useState(false)
  const { inputProps, form, reset } = useForm(BLANK)
  const { loading, error, success, indices } = useSelector(r => r.drivers.invite)
  const dispatch = useDispatch()

  useEffect(() => {
    return () => dispatch({ type: DRIVERS_INVITE_RESET_STORE })
  }, [])

  let messages
  if (error) { messages = [error] }
  if (success) { messages = ["Drivers have been emailed registration instructions"] }

  const submit = (form) => {
    setSubmitted(true)
    apiSubmit(form)
  }
  if (submitted && success) {
    reset()
    setSubmitted(false)
  }

  return (
    <Styling className="item">
      <h4>Invite drivers</h4>
      <span className="summary">
        <Status isError={!!error} messages={messages} />
        <Conditional if={!messages && !loading}>
          <div className="summary-message">
            You can invite drivers within your fleet to complete registration themselves.
            Simply enter their email address below and they will receive an online form to
            complete. If the driver’s information is correct, they will be added to your fleet.
          </div>
        </Conditional>
        <Conditional if={loading}>
          <Loading />
        </Conditional>
      </span>
      <label>Type emails</label>
      {[1, 2, 3, 4].map(i => <Email key={i} error={(indices || []).includes(i - 1)} {...inputProps(`email${i}`)} />)}
      <br />
      <Field label="Add message">
        <textarea maxLength="1000" {...inputProps('message')} />
      </Field>
      <Button onClick={() => submit(form)}>Send invite</Button>
    </Styling>
  )
}

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 1.5rem;
  left: 1rem;
  opacity: 0.5;
`

const Styling = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #282828;
  background: white;
  border: 2px solid white;

  h4 {
    font-size: 14px;
    font-weight: bold;
    color: black;
  }
  .summary {
    line-height: 150%;
    margin-bottom: 2rem;
    height: 4rem;
  }
  .field {
    margin-bottom: 0;&.error { background: none; }
  }
  input {
    padding-left: 2.5rem;
  }
  textarea {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    height: 5rem;
    border-radius: 5px;
    padding: 1rem;
    font-family: 'Ubuntu';
    font-size: 14px;
    resize: none;

    &:focus {
      border-color: ${props => props.theme.primaryColor};
      outline: none;
    }
  }
`

const Button = styled.button`
  padding: 0.6rem 1rem;
  width: 8rem;
  font-family: ${props => props.theme.font};
  color: white;
  background-color: ${props => props.theme.primaryColor};
  border: 1px solid ${props => props.theme.primaryColor};
  border-radius: 5px;
  cursor: pointer;
  flex-shrink: 0;
  transition: all .15s ease-in-out;
  margin-top: auto;

  &:disabled {
    background-color: #B9B9B9;
    border-color: #B9B9B9;
    cursor: default;
  }
`

export default Invite
