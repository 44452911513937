import React from 'react'
import styled from 'styled-components/macro'

const Declaration = ({ agreed, toggle, bgColor = 'white', txtColor = '#767676' }) => {
  return (
    <DeclarationContainer bgColor={bgColor} txtColor={txtColor}>
      <h1>Declaration</h1>
      <div className="declaration-text">
        I am the person referred to in the Vehelix Driver Information Request Email.
        I understand Vehelix will ask DVLA for my driver record information, as and when
        they require, for the purpose set out in the Vehelix Driver Information Request Email.
        I understand DVLA will disclose to Vehelix all relevant information held in the
        computerised register of drivers maintained by DVLA. This includes personal
        details, driving entitlements, valid endorsements and disqualifications (if
        relevant), photo images, Certificate of Professional Competence (CPC) and
        Digital Tachograph Card details (where appropriate). Medical information will
        not be provided. This declaration will expire when I cease driving in connection
        with the company or in any case, three years from the date of my signature.
      </div>
      <label>
        <input
          type="checkbox"
          checked={agreed}
          onClick={toggle}
        />
        <span>I have read and agree</span>
      </label>
    </DeclarationContainer>
  )
}

const DeclarationContainer = styled.div`
  > h1 {
    color: #282828;
    font-weight: normal;
    font-size: .9rem;
  }

  > div.declaration-text {
    font-size: .8rem;
    overflow-y: auto;
    padding: 1rem;
    max-height: 5rem;
    margin-bottom: 1.2em;

    color: ${({ txtColor }) => (txtColor)};
    background: ${({ bgColor }) => (bgColor)};

    // This works around padding bottom not showing on FF with overflow auto
    // https://stackoverflow.com/a/29987552
    @-moz-document url-prefix() {
      &::after {
        content: '';
        height: 1rem;
        width: 100%;
        display: block;
      }
    }
  }

  > label {
    > input {
      vertical-align: -.15rem;
    }

    > span {
      color: #282828;
      font-weight: normal;
      font-size: .9rem;
      margin-left: .5rem;
    }
  }
`

export default Declaration
