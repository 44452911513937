import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'
import { get, pick, camelCase } from 'lodash'
import styled from 'styled-components/macro'

import { SettingsPanel } from './Settings'
import { Button, Field, PasswordField, Status, useForm, Loading, LoadingAbsolute } from '../common'
import { COMPANY_TYPES, selectStyles } from '../users/accountFormOptions'
import Api from '../api/call'
import { ACCOUNT_UPDATE, LOGOUT } from '../root/action-types'

const Account = () => {
  const { loading: accountLoading, success, errors, errorFields } = useSelector(r => r.users.account)
  const { loading: currentLoading, organisation, ...current } = useSelector(r => r.users.current)
  const [active, setActive] = useState('')
  const [status, setStatus] = useState([false, []])
  const [failingFields, setFailingFields] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    if (errors) setStatus([true, [...errors]])
    if (success) {
      setActive('')
      setStatus([false, ['Account information has been updated']])
      setFailingFields([])
    }
  }, [errors, success])

  useEffect(() => {
    if (errorFields) setFailingFields(errorFields.map(ef => camelCase(ef)))
  }, [errorFields])

  useEffect(() => {
    if (active) {
      setStatus([false, []])
      setFailingFields([])
    }
  }, [active])

  useEffect(() => {
    return () => dispatch({ type: 'ACCOUNT_UPDATE_RESET_STORE' })
  }, [])

  return (
    <SettingsPanel>
      <AccountContainer>
        <h1>Account</h1>
        {currentLoading ? (
          <Loading />
        ) : (
          <>
            {status[1].length ? (
              <Status isError={status[0]} messages={status[1]} />
            ) : null}
            {accountLoading && (
              <LoadingAbsolute />
            )}
            <UpdateAccountFields
              user={current}
              organisation={organisation}
              active={active === 'account'}
              setActive={setActive}
              failingFields={failingFields}
              setFailingFields={setFailingFields}
              setStatus={setStatus}
            />
            <UpdatePasswordFields
              user={current}
              active={active === 'password'}
              setActive={setActive}
              failingFields={failingFields}
              setFailingFields={setFailingFields}
              setStatus={setStatus}
            />
          </>
        )}
      </AccountContainer>
    </SettingsPanel>
  )
}

const UpdateAccountFields = ({ user, organisation, active, setActive, failingFields, setFailingFields, setStatus }) => {
  const { data, success, loading } = useSelector(r => r.users.account)
  const { emailChanged } = data || {}
  const dispatch = useDispatch()

  const { companyName, companyType } = organisation || {}
  const initial = {
    ...pick(user, ['firstName', 'lastName', 'email']),
    companyName,
    companyType: COMPANY_TYPES.find(ct => ct.value === companyType)
  }
  const { form, updateForm, inputOnChange } = useForm(initial)

  const cancel = () => {
    setStatus([false, []])
    setFailingFields([])
    updateForm(initial)
    setActive('')
  }

  const submit = () => {
    setStatus([false, []])
    const data = {
      ...form,
      companyType: get(form.companyType, 'value'),
    }
    Api({ request: ACCOUNT_UPDATE, id: user.id, data })
  }

  useEffect(() => {
    if (success && emailChanged) dispatch({ type: LOGOUT })
  }, [emailChanged, success])

  return (
    <>
      <AccountFieldsContainer className="account-form-fields">
        <Field
          label="First Name"
          error={failingFields.includes('firstName')}
          className={`${active ? '' : 'disabled'}`}
        >
          <input
            type="text"
            name="firstName"
            onChange={inputOnChange}
            value={form.firstName}
            disabled={!active || loading}
          />
        </Field>
        <Field
          label="Last Name"
          error={failingFields.includes('lastName')}
          className={`${active ? '' : 'disabled'}`}
        >
          <input
            type="text"
            name="lastName"
            onChange={inputOnChange}
            value={form.lastName}
            disabled={!active || loading}
          />
        </Field>
        <Field
          label="Company Name"
          error={failingFields.includes('companyName')}
          className={`${active ? '' : 'disabled'}`}
        >
          <input
            type="text"
            name="companyName"
            onChange={inputOnChange}
            value={form.companyName}
            disabled={!active || loading}
          />
        </Field>
        <Field label="Company Type" className={`company-type${active ? '' : ' disabled'}`}>
          <Select
            onChange={companyType => updateForm({ companyType })}
            styles={selectStyles}
            options={COMPANY_TYPES}
            value={form.companyType}
            isDisabled={!active || loading}
          />
        </Field>
        <Field
          label="Email"
          error={failingFields.includes('email')}
          className={`${active ? '' : 'disabled'}`}
        >
          <input
            type="email"
            name="email"
            onChange={inputOnChange}
            value={form.email}
            disabled={!active || loading}
          />
        </Field>
        <Field label="" className="block account-btns">
          <Button
            className={`edit-account-btn ${active ? 'active' : ''}`}
            onClick={active ? submit : () => setActive('account')}
            disabled={loading}
          >
            {active ? 'Save' : 'Edit'}
          </Button>
          {active ? (
            <Button
              className="edit-account-cancel-btn"
              onClick={cancel}
              disabled={loading}
            >
              Cancel
            </Button>
          ) : null}
        </Field>
      </AccountFieldsContainer>
    </>
  )
}

const AccountContainer = styled.div`
  position: relative;
`

const AccountFieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  &.account-form-fields::after {
    content: '';
    display: block;
    width: calc(100% + 5rem);
    height: 1px;
    background: #00000015;
    margin: 1rem -2.5rem 2rem -2.5rem;
  }

  > * { flex: 1 1 44% }

  > *:nth-child(odd) { margin-right: 2rem }

  .account-btns {
    flex-direction: row;
    align-items: center;

    > button {
      margin: auto 0 10px 0;
      height: 51px;
    }
  }

  .edit-account-btn {
    width: 50%;
    align-self: flex-start;
    background: #B9B9B9;
    border: 1px solid #B9B9B9;

    &.active {
      background: ${p => p.theme.primaryColor};
      border: 1px solid ${p => p.theme.primaryColor};
    }
  }

  .edit-account-cancel-btn {
    width: 30%;
    background: white !important;
    border: none !important;
    color: #AAA;
  }

  .company-type-select {
    div[class$="-ValueContainer"] { height: 49px }
    div[class$="-placeholder"] { top: 47% }
  }
`

const INITIAL_PASSWORD_FIELDS = {
  currentPassword: '',
  password: '',
  confirmPassword: ''
}

const UpdatePasswordFields = ({ user, active, setActive, failingFields, setFailingFields, setStatus }) => {
  const { data, success, loading } = useSelector(r => r.users.account)
  const { passwordChanged } = data || {}
  const { form, updateForm, inputOnChange } = useForm(INITIAL_PASSWORD_FIELDS)

  const cancel = () => {
    setStatus([false, []])
    setFailingFields([])
    setActive('')
  }

  const submit = () => {
    setStatus([false, []])
    Api({ request: ACCOUNT_UPDATE, id: user.id, data: { ...form, passwordForm: true } })
  }

  useEffect(() => {
    if (success && passwordChanged) {
      // TODO: unsure of preferred flow here at this juncture
      // dispatch({ type: LOGOUT })
      updateForm(INITIAL_PASSWORD_FIELDS)
    }
  }, [passwordChanged, success])

  return (
    <>
      {loading && (
        <LoadingAbsolute />
      )}
      <AccountFieldsContainer className="password-form-fields">
        {active ? (
          <>
            <PasswordField
              label="Current Password"
              error={failingFields.includes('currentPassword')}
              className={`${active ? '' : 'disabled'}`}
              name="currentPassword"
              onChange={inputOnChange}
              value={form.currentPassword}
              disabled={!active || loading}
            />
            <PasswordField
              label="New Password"
              error={failingFields.includes('password')}
              className={`${active ? '' : 'disabled'}`}
              name="password"
              onChange={inputOnChange}
              value={form.password}
              disabled={!active || loading}
            />
            <PasswordField
              label="Confirm New Password"
              error={failingFields.includes('confirmPassword')}
              className={`${active ? '' : 'disabled'}`}
              name="confirmPassword"
              onChange={inputOnChange}
              value={form.confirmPassword}
              disabled={!active || loading}
            />
          </>
        ) : (
          <Field
            label="Password"
            className={`${active ? '' : 'disabled'}`}
          >
            <input
              type="password"
              name="placeholderPassword"
              placeholder="***************"
              disabled={!active || loading}
            />
          </Field>
        )}
        <Field label="" className="block account-btns">
          <Button
            className={`edit-account-btn ${active ? 'active' : ''}`}
            onClick={active ? submit : () => setActive('password')}
            disabled={loading}
          >
            {active ? 'Save' : 'Edit Password'}
          </Button>
          {active ? (
            <Button
              className="edit-account-cancel-btn"
              onClick={cancel}
              disabled={loading}
            >
              Cancel
            </Button>
          ) : null}
        </Field>
      </AccountFieldsContainer>
    </>
  )
}

export default Account
