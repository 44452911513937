import React, { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Conditional } from './'
import { IconButton } from './Buttons'

import './dropdown-button.scss'

export const DropdownOption = ({ icon, svg, label, ...props }) => (
  <li {...props}>
    {icon ? <FontAwesomeIcon icon={icon} /> : null}
    {svg ? <img src={svg} alt="icon" /> : null}
    <label>{label}</label>
  </li>
)

export const DropdownOptionButton = ({ icon, label, ...props }) => (
  <button {...props}>
    <FontAwesomeIcon icon={icon} /><label>{label}</label>
  </button >
)

export const useHoverPanel = (closeOnClick) => {
  const [active, setActive] = useState(false)
  const ref = useRef(null)

  const activate = () => {
    setActive(!active)
  }
  const clickOutside = ({ target }) => {
    if (ref.current && !ref.current.contains(target)) {
      setActive(false)
    }
  }
  const clickInside = ({ target }) => {
    if (ref.current && ref.current.contains(target)) {
      setActive(false)
    }
  }

  useEffect(() => {
    if (!active) { return }
    document.addEventListener('click', clickOutside)
    closeOnClick && document.addEventListener('click', clickInside)
    return () => {
      document.removeEventListener('click', clickOutside)
      document.removeEventListener('click', clickInside)
    }
  }, [active])

  return { active, ref, activate }
}

export const DropdownButton = ({ children, dropUp, ...props }) => {
  const { active, ref, activate } = useHoverPanel(true)

  return (
    <div className={`dropdown-button ${dropUp ? 'dropup' : null}`}>
      <IconButton
        {...props}
        onClick={activate}
        icon={active ? 'caret-down' : 'ellipsis-h'}
        className={`menu-icon ${active ? 'active' : ''}`}
      >
        <Conditional if={props.caret}>
          <i>{active ? '▲' : '▼'}</i>
        </Conditional>
      </IconButton>
      <div className={`dropdown-content ${active ? 'visible' : ''}`} ref={ref}>
        {children}
      </div>
    </div>
  )
}
