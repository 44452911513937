export const standardForCollections = (action_name) => (
  (state = { data: [], total: 0, count: 0 }, action) => {
    switch (action.type) {
      case `${action_name}_START`:
        return {
          ...state,
          loading: true,
        }
      case `${action_name}_SUCCESS`: {
        return {
          ...state,
          loading: false,
          ...action.response.data,
        }
      }
      case `${action_name}_FAILURE`:
        return {
          ...state,
          loading: false,
          errors: action.response.data.errors,
        }
      default:
        return state
    }
  }
)

export const INITIAL_SEARCH = { total: 0, count: 0, results: [], labels: {} }

export const standardSearch = (action_name) => (
  (state = INITIAL_SEARCH, action) => {
    switch (action.type) {
      case `${action_name}_LABEL_START`:
      case `${action_name}_SEARCH_START`:
        return {
          ...state,
          loading: true,
        }
      case `${action_name}_LABEL_FAILURE`:
      case `${action_name}_SEARCH_FAILURE`:
        return {
          ...state,
          loading: false,
        }
      case `${action_name}_LABEL_SUCCESS`: {
        return {
          ...state,
          labels: { ...state.labels, ...action.response.data },
          loading: false,
        }
      }
      case `${action_name}_SEARCH_SUCCESS`: {
        const { results, count, total } = action.response.data
        const labels = { ...state.labels }
        results.forEach(r => { labels[r.id] = r.label })
        return {
          ...state,
          loading: false,
          results,
          labels,
          count,
          total,
        }
      }
      default:
        return state
    }
  }
)

export const standardShow = (action_name) => (
  (state = {}, action) => {
    switch (action.type) {
      case `${action_name}_START`:
        return {
          ...state,
          loading: true,
        }
      case `${action_name}_SUCCESS`:
        return {
          ...state,
          loading: false,
          errors: null,
          data: action.response.data,
        }
      case `${action_name}_FAILURE`:
        return {
          ...state,
          loading: false,
          errors: action.response.data.errors,
        }
      default:
        return state
    }
  }
)

export const standardModelCreateUpdateDestroy = (model_name, action_name) => {
  const DEFAULT_STATE = {
    [`${model_name}Created`]: false,
    [`${model_name}HasUpdated`]: false,
    [`${model_name}Destroyed`]: false,
  }

  return (
    (state = DEFAULT_STATE, action) => {
      switch (action.type) {
        case `${action_name}_CREATE_START`:
        case `${action_name}_UPDATE_START`:
        case `${action_name}_DESTROY_START`:
          return {
            ...state,
            loading: true,
            success: false,
            [`${model_name}Created`]: false,
            [`${model_name}HasUpdated`]: false,
            [`${model_name}Destroyed`]: false,
          }
        case `${action_name}_CREATE_SUCCESS`:
          return {
            ...state,
            loading: false,
            success: true,
            [`${model_name}Created`]: true,
            errors: action.response.data.errors,
            errorFields: action.response.data.errorFields,
            data: action.response.data,
          }
        case `${action_name}_UPDATE_SUCCESS`:
          return {
            ...state,
            loading: false,
            success: true,
            [`${model_name}HasUpdated`]: true,
            errors: action.response.data.errors,
            errorFields: action.response.data.errorFields,
            data: action.response.data,
          }
        case `${action_name}_DESTROY_SUCCESS`:
          return {
            ...state,
            loading: false,
            success: true,
            [`${model_name}Destroyed`]: true,
            errors: action.response.data.errors,
            errorFields: action.response.data.errorFields,
            data: action.response.data,
          }
        case `${action_name}_CREATE_FAILURE`:
        case `${action_name}_UPDATE_FAILURE`:
        case `${action_name}_DESTROY_FAILURE`:
          return {
            ...state,
            loading: false,
            errors: action.response.data.errors,
            errorFields: action.response.data.errorFields,
          }
        case `${action_name}_CREATE_RESET_STORE`:
        case `${action_name}_UPDATE_RESET_STORE`:
        case `${action_name}_DESTROY_RESET_STORE`:
          return DEFAULT_STATE
        default: {
          return state
        }
      }
    }
  )
}
