import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'

import useCardDetails from './useCardDetails'
import CardDetailsFields from './CardDetailsFields'
import DirectDebitForm from './DirectDebitForm'
import PaymentDetailsTabs from './PaymentDetailsTabs'
import { Button, Status, Conditional, Loading } from '../common'

const UPDATE_SUCCESS_PATH = '/settings'
const BILLING_HASH = '#billing'

const UpdatePaymentDetails = () => {
  const { id } = useSelector(r => r.users.current)

  return (
    <UpdateDetailsContainer>
      <h2>Enter Payment Details</h2>
      <PaymentDetailsTabs
        cardForm={
          <CardDetailsForm id={id} />
        }
        directDebitForm={
          <DirectDebitForm id={id} path={UPDATE_SUCCESS_PATH} hash={BILLING_HASH} />
        }
      />
    </UpdateDetailsContainer>
  )
}

const CardDetailsForm = ({ id }) => {
  const [name, setName] = useState('')
  const [error, setError] = useState(null)
  const { stripe, stripeLoading, handleSubmit } = useCardDetails(id, name, setError)

  return (
    <div>
      <Status isError={!!error}>
        {error}
      </Status>
      <Conditional if={stripeLoading}>
        <Loading />
      </Conditional>
      <CardDetailsFields name={name} setName={setName} />
      <Button
        disabled={!stripe || stripeLoading}
        onClick={handleSubmit}
      >
        Confirm
      </Button>
    </div>
  )
}

const UpdateDetailsContainer = styled.div`
  width: 100%;

  button {
    max-width: 100% !important;
    margin-top: 2rem !important;
  }

  .direct-debit-message {
    margin: 1rem 0 2rem 0;
    background: #F5F6F9;
  }
`

export default UpdatePaymentDetails
