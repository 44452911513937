import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import history from '../root/browser-history'

import { Conditional, LoadingAbsolute } from '../common'
import { warning, download, upload } from '../assets'
import RegisterVehicle, { Button } from './RegisterVehicle'
import ModalWindow from '../layout/ModalWindow'
import Api from '../api/call'
import { VEHICLES_CONFIRM } from '../root/action-types'
import { subscribe, unsubscribe } from './subscription'

import DragNDrop, { FileSelector } from './DragNDrop'
import Table, { TemplateLink } from './Table'
import Errors from './Errors'

import './new-vehicles.scss'

const NewVehiclesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
  background-color: #F5F6F9;
`
const Chevron = styled(FontAwesomeIcon)`
  padding-right: 0.5rem;
`
const ButtonRow = styled.div`
  display: flex;
  box-sizing: border-box;
  flex: 0 0 100%;
  padding: .75rem .75rem 1.5rem .75rem;
  align-items: center;
  .back {
    font-weight: bold;
    line-height: 2rem;
    margin-left: -6px;
    margin-right: auto;
    color: ${p => p.theme.primaryColor};
    cursor: pointer;
  }
`
const Column = styled.div`
  box-sizing: border-box;
  height: 80vh;
  padding: 0 0.75rem;

  @media screen and (min-width: 768px) and (max-width: 1250px) {
    height: auto;
  }
`
const LeftColumn = styled(Column)`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) and (max-width: 1250px) {
    flex-direction: row;
    flex: 0 0 100%;
    margin-bottom: 2rem;
  }
`
const RightColumn = styled(Column)`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 0.75rem;
  padding-left: 0;
  padding-right: 0;
  background-color: white;
  box-shadow: 0px 3px 6px #00000008;
  > h1 {
    color: black;
    font-size: 1.35rem;
    font-weight: normal;
    margin: 1.5rem 2rem;

    @media screen and (max-width: 1919px) {
      margin: 1.5rem 1.1rem;
    }
  }
`
const RightColumnRow = styled.div`
  display: flex;
  width: 100%;
  align-self: flex-end;

  > span {
    display: inline-flex;
    align-items: center;
    margin: 1.5rem 2rem;
    margin-right: auto;
    font-size: .9rem;

    @media screen and (max-width: 1919px) {
      margin: 1.5rem 1.1rem;
      margin-right: auto;
    }
  }

  > button {
    margin: 1rem;
    padding: 0.7rem 1rem;
    align-self: flex-end;
    outline: none;
    transition: opacity .15s ease-in-out;
    &:last-of-type {
      margin-right: 2rem;

      @media screen and (max-width: 1919px) {
        margin-right: 1.1rem;
      }
    }
    &:hover { opacity: .7; }
  }
`
const RightBtn = styled.button`
  align-self: flex-end;
  margin-left: 1rem;
  color: white;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border: 1px solid ${props => props.theme.primaryColor};
  background: ${props => props.theme.primaryColor};
  font-family: 'Ubuntu', Sans-serif;
  display: flex;
  justify-content; center;
  align-items: center;
  transition: opacity .15s ease-in-out;
  outline: none;

  > img, svg {
    height: 1.25rem;
    width: 1.25rem;
    padding-right: 0.5rem;
  }
  &:hover { opacity: .7; }
`
const MutedBtn = styled(RightBtn)`
  background: none;
  border: none;
  color: #B9B9B9;
`
const RightBtnWide = styled(RightBtn)`
  padding: 0.4rem 1.75rem;

  &:disabled {
    cursor: initial;
    opacity: .7;
  }
`
const MyModal = styled(ModalWindow)`
  .close-modal { display: none }
`
const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  > img { height: 3rem }
  > h1 { font-weight: normal; font-size: 1.7rem; }
  button { width: 50% }
`

function NewVehicles () {
  const [error, setError] = useState(null)
  const table = useSelector(store => store.vehicles.table)
  const numVehicles = Object.values(table.data).length

  useEffect(() => {
    if (table.error) setError(table.error)
  }, [table.error])

  useEffect(() => {
    subscribe()

    return () => unsubscribe()
  }, [])

  const saveVehicles = () => {
    const registrations = Object.keys(table.data).filter(reg => {
      const v = table.data[reg]
      return !v.pending && !v.errors && !v.confirmed
    })
    Api({ request: VEHICLES_CONFIRM, data: { registrations } })
  }

  return (
    <>
      <MyModal isOpen={!!error} closeModal={() => setError(null)}>
        <ModalContent>
          <img src={warning} alt="" />
          <h1>{error}</h1>
          <Button onClick={() => setError(null)}>OK</Button>
        </ModalContent>
      </MyModal>
      <NewVehiclesContainer>
        <Conditional if={table.loading}>
          <LoadingAbsolute />
        </Conditional>
        <ButtonRow>
          <div className="back" onClick={() => history.goBack()}>
            <Chevron icon={ ['fas', 'chevron-left'] } fixedWidth />
            <span>Back</span>
          </div>
          <Errors>
            <RightBtn>
              <FontAwesomeIcon icon="exclamation-triangle" /><span>Errors</span>
            </RightBtn>
          </Errors>
          <FileSelector>
            <RightBtn>
              <img src={upload} alt="Upload .csv"/><span>Upload CSV</span>
            </RightBtn>
          </FileSelector>
          <TemplateLink>
            <RightBtn>
              <img src={download} alt="Download template"/><span>Download template</span>
            </RightBtn>
          </TemplateLink>
        </ButtonRow>
        <LeftColumn>
          <RegisterVehicle />
          <DragNDrop />
        </LeftColumn>
        <RightColumn>
          <h1>Vehicle Confirmation</h1>
          <Table />
          <RightColumnRow>
            <span>{numVehicles} Vehicle{numVehicles === 1 ? "" : "s"}</span>
            <MutedBtn>Close</MutedBtn>
            <RightBtnWide disabled={!numVehicles} onClick={saveVehicles}>Confirm</RightBtnWide>
          </RightColumnRow>
        </RightColumn>
      </NewVehiclesContainer>
    </>
  )
}

export default NewVehicles
