import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { startCase } from 'lodash'

import { DASHBOARD_ADD_DRIVER_FILTER } from '../root/action-types'

import { Styling, COLORS, Row, Chart } from './DonutChart'

const breakdown = (drivers, riskAssessor) => {
  const result = []
  const total = drivers.length
  const byRisk = {}
  Object.keys(riskAssessor.bands).forEach(r => byRisk[r] = [])

  drivers.forEach(d => {
    byRisk[riskAssessor.driver(d)].push(d)
  })

  Object.keys(byRisk).forEach((risk, i) => {
    result.push({
      name: startCase(risk),
      number: byRisk[risk].length,
      percent: (byRisk[risk].length / total) * 100,
      color: COLORS[i]
    })
  })

  return result.filter(r => r.number > 0)
}

const LicenceRisk = () => {
  const { matchedDrivers, riskAssessor } = useSelector(r => r.dashboard)
  const dispatch = useDispatch()
  const data = breakdown(matchedDrivers, riskAssessor)
  const addFilter = (name) => {
    dispatch({ type: DASHBOARD_ADD_DRIVER_FILTER, filter: { risk: name.toLowerCase() } })
  }

  return (
    <Styling>
      <h2>Licence Risk Chart</h2>
      <div className="content">
        <div className="list">
          {data.map((d, i) =>
            <Row key={i} {...d} suffix="Driver" onClick={() => addFilter(d.name)} />
          )}
        </div>
        <Chart data={data} addFilter={addFilter} />
      </div>
    </Styling>
  )
}

export default LicenceRisk
