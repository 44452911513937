import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select'
import { Link } from 'react-router-dom'

import ColumnSelect from './ColumnSelect'
import DateSelect from '../DateSelect'

const Search = ({ handleSearchChange, search, model }) => {
  if (!handleSearchChange) return null
  return (
    <div className="search">
      <FontAwesomeIcon icon={ ['fas', 'search'] } fixedWidth />
      <input
        type="text"
        placeholder={model ? `Search ${model}...` : "Search..."}
        value={search}
        onChange={handleSearchChange}
      />
    </div>
  )
}

const Sort = ({ handleSortChange, handleSortDirectionChange, sort, sortDirection, sortOptions }) => {
  if (!(handleSortChange && handleSortDirectionChange && sortOptions)) return null
  return (
    <div className="sort">
      <label>Sort by:</label>
      <Select
        classNamePrefix="select"
        className="sortSelect"
        value={sort} onChange={handleSortChange} options={sortOptions}
      />
      <span className="sortDirection" onClick={handleSortDirectionChange}>
        <FontAwesomeIcon icon={sortDirection ? ['fas', 'sort-alpha-up'] : ['fas', 'sort-alpha-down']} fixedWidth />
      </span>
    </div>
  )
}

const PageSize = ({ pageSize, handleSizeChange, pageSizeOptions }) => (
  <div className="pageSize">
    <label>Per page:</label>
    <Select
      classNamePrefix="select"
      className="pageSelect"
      value={pageSize} onChange={handleSizeChange} options={pageSizeOptions}
    />
  </div>
)

const Category = ({ category, handleCategoryChange, categoryOptions }) => {
  if (!(handleCategoryChange && categoryOptions)) return null
  return (
    <div className="category">
      <label>Category:</label>
      <Select
        classNamePrefix="select"
        className="categorySelect"
        value={category} onChange={handleCategoryChange} options={categoryOptions} isMulti={true}
      />
    </div>
  )
}

const ColumnSelector = ({ columnOptions, currentColumns, setColumns, defaultColumns }) => {
  const [active, setActive] = useState(false)
  const toggle = () => setActive(!active)
  if (!setColumns) { return null }

  return (
    <div>
      <button className="columns" onClick={toggle} title="Change columns">
        <FontAwesomeIcon icon="columns" />
      </button>
      <ColumnSelect
        open={active}
        close={toggle}
        options={columnOptions}
        value={currentColumns}
        setColumns={setColumns}
        defaultColumns={defaultColumns}
      />
    </div>
  )
}

const New = ({ newText, newLink }) => {
  if (!newLink) { return null }
  return (
    <div className="new-link">
      <Link to={newLink}>
        <button><FontAwesomeIcon icon="plus" />{newText}</button>
      </Link>
    </div>
  )
}
const Header = ({ header }) => {
  if (!header) { return null }
  return <div><h2>{header}</h2></div>
}

const Date = ({ dateRange, onDateChange }) => {
  if (!onDateChange) { return null }

  return (
    <DateSelect
      {...dateRange}
      onChange={onDateChange}
    />
  )
}

const ControlRow = (props) => (
  <React.Fragment>
    <div className='controlRow'>
      <Header {...props} />
      <div className="leftSide">
        <Search {...props} />
        <Date {...props} />
        <Sort {...props} />
        <ColumnSelector {...props} />
      </div>
      <div className="rightSide">
        <PageSize {...props} />
        <Category {...props} />
        <New {...props} />
      </div>
    </div>
    <hr />
  </React.Fragment>
)

export default ControlRow
