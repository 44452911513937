import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faCalendarWeek,
  faCarAlt,
  faCarCrash,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretSquareLeft,
  faCaretSquareRight,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faCircle,
  faCloudUploadAlt,
  faColumns,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFile,
  faFileUpload,
  faGreaterThan,
  faIdCard,
  faMinus,
  faPaperclip,
  faPencilAlt,
  faPlus,
  faRedo,
  faSearch,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortDown,
  faSortUp,
  faSquare,
  faTimes,
  faTimesCircle,
  faTrash,
  faUser
} from '@fortawesome/free-solid-svg-icons'

import {
  faCheckCircle as faCheckCircleSolid,
  faTimesCircle as faTimesCircleSolid,
} from '@fortawesome/free-regular-svg-icons'

library.add(
  // fas
  faCalendarWeek,
  faCarAlt,
  faCarCrash,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretSquareLeft,
  faCaretSquareRight,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faCircle,
  faCloudUploadAlt,
  faColumns,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFile,
  faFileUpload,
  faGreaterThan,
  faIdCard,
  faMinus,
  faPaperclip,
  faPencilAlt,
  faPlus,
  faRedo,
  faSearch,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortDown,
  faSortUp,
  faSquare,
  faTimes,
  faTimesCircle,
  faTrash,
  faUser,

  // far
  faCheckCircleSolid,
  faTimesCircleSolid,
)
