import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get } from 'lodash'

class PagingControl extends Component {
  checkForData = (data, page) => {
    if ((data.length > 0) || (page == 1)) return
    this.backPage()
  }

  backPage = () => {
    if (this.props.page == 1) return
    this.props.handlePageChange(this.props.page - 1)
  }

  forwardPage = () => {
    const { page, data, count } = this.props
    const pageSize = get(this.props.pageSize, 'value', null)
    const start = (page * pageSize) - pageSize + 1
    const end = start + data.length - 1
    if (end >= count) return
    this.props.handlePageChange(page + 1)
  }

  goToPage = toPage => {
    if (toPage == this.props.page) return
    this.props.handlePageChange(toPage)
  }

  renderPagingButtons = () => {
    const { page, count } = this.props
    const pageSize = get(this.props.pageSize, 'value', null)
    const totalPages = Math.ceil(count / pageSize)
    const startPage = Math.max(1, Math.min(page - 2, totalPages - 4))
    const endPage = Math.min(totalPages, Math.max(page + 2, 5))
    const leftSpill = startPage > 1
    const rightSpill = endPage < totalPages
    const pages = []
    for (var i = startPage; i <= endPage; i++) {
      pages.push(i)
    }
    const buttons = []
    if (leftSpill) buttons.push(<span className='page-button' key='leftspillpage'>...</span>)
    buttons.push(...pages.map(thispage => (
      <span className={ (thispage == page) ? 'page' : 'page-button' } key={ thispage + 'page' } onClick={ () => { this.goToPage(thispage) } }>{ thispage }</span>
    )))
    if (rightSpill) buttons.push(<span className='page-button' key='rightspillpage'>...</span>)
    return buttons
  }

  render = () => {
    const { data, page, count } = this.props
    this.checkForData(data, page)
    if (count == 0) return null
    return (<span className='control'>
      <FontAwesomeIcon icon={ ['fas', 'caret-left'] } fixedWidth onClick={ this.backPage } />
      { this.renderPagingButtons() }
      <FontAwesomeIcon icon={ ['fas', 'caret-right'] } fixedWidth onClick={ this.forwardPage } />
    </span>)
  }
}

export default PagingControl
