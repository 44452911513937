import React, { Component } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { startCase } from 'lodash'
import propTypes from 'prop-types'

import { isAuthenticated } from '../api/call'

const setPageTitle = (path) => {
  document.title = startCase(path) || 'Vehelix'
}

export default class ProtectedRoute extends Component {
  render = () => {
    const { component: Component, ...props } = this.props

    setPageTitle(props.path)

    return (
      <Route
        {...props}
        render = {
          (props) => {
            if (isAuthenticated()) {
              return <Component {...props} />
            }
            return <Redirect to='/login' />
          }
        }
      />
    )
  }
}
ProtectedRoute.propTypes = {
  component: propTypes.func
}
