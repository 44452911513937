import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import propTypes from 'prop-types'
import styled from 'styled-components/macro'

import { Field } from '../'

export const PasswordField = ({ label, ...props }) => {
  const [show, setShow] = useState(false)

  return (
    <Field label={label || "Password"} error={props.error}>
      <Eye icon={`eye${show ? '-slash' : ''}`} onClick={() => setShow(!show)} />
      <input
        type={show ? 'text' : 'password'}
        {...props}
      />
    </Field>
  )
}
PasswordField.propTypes = {
  label: propTypes.string,
  value: propTypes.any
}

const Eye = styled(FontAwesomeIcon)`
  position: absolute;
  top: 41px;
  right: 20px;
  cursor: pointer;
  transition: color .15s ease-in-out;

  &:hover { color: #F66C57; }
`
