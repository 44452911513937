import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import propTypes from 'prop-types'

import { logo, dribbble } from '../assets/'
import { Button, Loaded } from '../common'
import Declaration from './Declaration'
import { RegisterForm, useRegisterForm } from './Register'
import Api from '../api/call'
import { DRIVERS_GUEST_NEW } from '../root/action-types'

const SubmitButton = styled(Button)`
  width: 21.875rem;
  &:disabled {
    background-color: #B9B9B9;
    border-color: #B9B9B9;
    cursor: default;
  }
`

const useOrganisationName = (verification) => {
  useEffect(() => {
    Api({ request: DRIVERS_GUEST_NEW, params: verification })
  }, [verification])
  const { guestNew } = useSelector(r => r.drivers)

  return guestNew
}

const Guest = ({ match: { params: verification } }) => {
  const { form, transformChange, setSubmitted, status, errors, loading } = useRegisterForm(verification)
  const [agreed, setAgreed] = useState(false)
  const toggle = () => setAgreed(!agreed)
  const org = useOrganisationName(verification)

  return (
    <>
      <Styling>
        <div className="left">
          <nav>
            <img className="logo" src={logo} alt="Vehelix logo" />
          </nav>
          <div className="background">
          </div>
        </div>
        <div className="main">
          <div className="form">
            <h1 className="form-header">Hello Driver</h1>
            <Loaded loading={org.loading}>
              <p>
                Please enter your details below to register as a driver for
                <span className="org-name"> {org.organisationName}</span>.
                Please ensure all information is correct before submitting.
              </p>
            </Loaded>
            <RegisterForm
              form={form}
              transformChange={transformChange}
              errors={errors}
              status={status}
              loading={loading}
            />
            <Declaration
              agreed={agreed}
              toggle={toggle}
              bgColor='#F5F6F9'
              txtColor='#BABBBD'
            />
            <SubmitButton
              disabled={loading || !agreed}
              onClick={() => setSubmitted(true)}
            >
              Submit
            </SubmitButton>
          </div>
        </div>
      </Styling>
    </>
  )
}
Guest.propTypes = {
  match: propTypes.object, // from react router
  params: propTypes.object
}

const Styling = styled.div`
  display: flex;
  height: 100vh;

  .org-name {
    font-weight: bold;
  }
  .left {
    background: #eee;
    flex-grow: 100;
    height: 100vh;
    .logo {
      height: 3rem;
      padding: 2rem;
    }
    .background {
      background-image: url(${dribbble});
      background-repeat: no-repeat;
      height: 85vh;
    }
  }
  .main {
    width: 900px;
    box-shadow: 0px 3px 6px #00000008;

    nav {
      height: 5rem;
      padding: 2rem;
      display: flex;
      justify-content: flex-end;

      span:first-child {
        color: ${props => props.theme.primaryColor};
      }
      span, a {
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-left: 1rem;
        height: 1rem;
      }
      a {
        color: black;
        border: 2px solid #282828;
      }
    }
    .form {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 38rem;
      margin: auto;
      h1.form-header {
        font-weight: normal;
        color: black;
        margin: auto;
        padding: 3rem;
      }
      p {
        margin: auto;
        text-align: center;
        height: 5.25rem;
        font-size: 0.8rem;

        &.terms-text {
          height: auto;
          margin-top: 5rem;

          > span { color: ${props => props.theme.primaryColor}; cursor: pointer; }
        }
      }
      > div:last-of-type {
        margin-bottom: 2rem;
      }
    }
  }
`

export default Guest
