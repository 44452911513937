import React from 'react'
import styled from 'styled-components/macro'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Register from './Register'
import Invite from './Invite'

const New = () => {
  const history = useHistory()

  return (
    <Styling>
      <div className="back" onClick={() => history.goBack()}>
        <FontAwesomeIcon icon="caret-square-left" />Back
      </div>
      <div className="content">
        <Register />
        <Invite />
      </div>
    </Styling>
  )
}

const Styling = styled.div`
  display: flex;
  flex-direction: column;

  .back {
    color: ${props => props.theme.primaryColor};
    cursor: pointer;
    margin-top: 1rem;
    margin-left: 2rem;
    font-size: 1.3rem;
    svg {
      padding-right: 0.75rem;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .item {
    flex: 1 100%;
    min-height: 70vh;
    box-sizing: border-box;
    min-width: 22rem;
    margin: 2rem;
    padding: 3.125em 4.25em;
  }
  @media only screen and (min-width: 97rem) {
    .content {
      flex-direction: row;
    }
    item {
      width: 50%;
    }
  }
  @media only screen and (max-width: 97rem) {
    .content {
      flex-direction: column;
    }
    item {
      width: 100%;
    }
  }

  .invite {
    background: white;
  }
`

export default New
