import React, { useState } from 'react'
import styled from 'styled-components/macro'
import propTypes from 'prop-types'
import { get } from 'lodash'
import { PieChart } from 'react-minimal-pie-chart'

export const COLORS = [
  '#43386C',
  '#633C76',
  '#95417E',
  '#C44876',
  '#DC536C',
  '#E75A66',
  '#F66C57',
  '#FB8344',
  '#FE9C2C',
  '#FEB711',
  '#B8B8B8'
]

export const Row = ({ name, number, percent, color, onClick, suffix }) => (
  <div className="row">
    <span className="icon"><i style={{ backgroundColor: color }} /></span>
    <span className="name" onClick={onClick}>{name}</span>
    <span className="number">{`${number} ${suffix}${number == 1 ? '' : 's'}`}</span>
    <span className="percent">{`${percent.toFixed(2)}%`}</span>
  </div>
)
Row.propTypes = {
  name: propTypes.string,
  color: propTypes.string,
  number: propTypes.number,
  percent: propTypes.number,
  onClick: propTypes.func
}

const chartData = ({ name, percent, color }) => (
  { title: name, value: percent, color }
)

export const Chart = ({ addFilter, ...props }) => {
  const [selected, setSelected] = useState(0)

  const data = props.data.map(d => chartData(d))
  const lineWidth = 35

  return (
    <div className="chart">
      <PieChart
        data={data}
        startAngle={270}
        radius={PieChart.defaultProps.radius - 6}
        lineWidth={lineWidth}
        segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
        segmentsShift={(index) => (index == selected && data.length > 2 ? 6 : 1)}
        animate
        onClick={(_, i) => { addFilter(data[i].title) }}
        onMouseOver={(_, index) => { setSelected(index) }}
      />
      <Selected>
        <span className="title">{get(data[selected], 'title', '')}</span>
        <span className="value">
          {data[selected] ? `${data[selected].value.toFixed(2)}%` : ''}
        </span>
      </Selected>
    </div>
  )
}
Chart.propTypes = {
  data: propTypes.array,
  addFilter: propTypes.func
}

const Selected = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .value {
    color: #B9B9B9;
  }
`

export const Styling = styled.div`
  background: white;
  padding: 2rem;
  min-width: 44rem;
  height: 22rem;
  box-shadow: 0 3px 6px rgba(0,0,0,0.05);

  @media (max-width: 1900px) {
    min-width: 35.2rem;
    height: 17.6rem;
    font-size: 0.8rem;
    padding: 1.6rem;

  }

  h2 {
    margin-top: 0;
    margin-bottom: 2rem;
    @media (max-width: 1900px) { margin-bottom: 1.6rem; }
  }

  .content {
    display: flex;
    .list {
      flex: 2;
      .row {
        display: flex;
        justify-content: space-around;
        padding-bottom: 0.5rem;
        align-items: center;
        @media (max-width: 1900px) { padding-bottom: 0.4 }
        span {
          display: flex;
          width: 30%;
          color: #B9B9B9;
          font-size: .8rem;
          @media (max-width: 1900px) { font-size: 0.64rem }

          &.icon {
            width: 10%;
          }
          &.name {
            color: black;
            cursor: pointer;
            width: 50%;
          }
        }
        i {
          border-radius: 1rem;
          width: 1rem;
          height: 1rem;
          margin-top: 0.1rem;
          @media (max-width: 1900px) {
            height: 0.8rem;
            width: 0.8rem;
          }
        }
      }
    }
    .chart {
      flex: 1.1;
      position: relative;
      margin-top: -3%;
      max-width: 22rem;
    }
  }
`
