import { all } from 'redux-saga/effects'

import { apiWatcher } from '../api/saga'
import { dropboxWatcher } from '../attachments/dropbox'

export default function * rootSaga () {
  yield all([
    apiWatcher(),
    dropboxWatcher(),
  ])
}
