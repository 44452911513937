import { useState, useEffect, useRef } from 'react'

const SIDEBAR_WIDTH = 300

const getWindowDimensions = () => {
  const { innerWidth, innerHeight: height } = window
  return { width: innerWidth - SIDEBAR_WIDTH, height }
}

const useResize = (handler, delay) => {
  let timer
  const throttled = () => {
    clearTimeout(timer)
    timer = setTimeout(handler, delay)
  }
  useEffect(() => {
    window.addEventListener('resize', throttled)
    return () => window.removeEventListener('resize', throttled)
  }, [])
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  useResize(() => setWindowDimensions(getWindowDimensions()), 100)

  return windowDimensions
}

export const useRefDimensions = () => {
  const ref = useRef(null)
  const getDimensions = () => (
    ref.current ? { width: ref.current.offsetWidth, height: ref.current.offsetHeight } : {}
  )
  const [dimensions, setDimensions] = useState(getDimensions())
  const resize = () => setDimensions(getDimensions())
  useResize(resize, 500)
  useEffect(resize, [ref])

  return { ref, ...dimensions }
}
