import React from 'react'
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js'
import styled from 'styled-components/macro'

const CardDetailsFields = ({ name, setName }) => (
  <CardFields>
    <div className="stripe-field">
      <label>Name on card</label>
      <input
        className="signup-input"
        type="text"
        name="nameOnCard"
        value={name}
        onChange={e => setName(e.target.value)}
      />
    </div>
    <div className="stripe-field">
      <label>Card Number</label>
      <CardNumberElement className="form-stripe-element" />
    </div>
    <div className="stripe-field half-width">
      <label>Expiry</label>
      <CardExpiryElement className="form-stripe-element" />
    </div>
    <div className="stripe-field half-width">
      <label>CVC</label>
      <CardCvcElement className="form-stripe-element" />
    </div>
  </CardFields>
)

const CardFields = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: left;
  display: flex;
  flex-wrap: wrap;

  .stripe-field {
    box-sizing: border-box;
    width: 100%;
    flex: 0 0 100%;

    &.half-width {
      flex: 0 0 48%;
      &:last-of-type { margin-left: auto }
    }

    label { font-size: .9rem; }

    input, .form-stripe-element  {
      box-sizing: border-box;
      width: 100%;
      padding: 1rem;
      margin-top: 7px;
      margin-bottom: 10px;
      font-family: 'Ubuntu';
      border: 1px solid #B9B9B9;
      border-radius: 5px;
      background: white;
      outline: none;

      &:focus, &.StripeElement--focus {
        border-color: ${props => props.theme.primaryColor};
      }
    }
  }
`

export default CardDetailsFields
