import React, { useRef } from 'react'
import { startCase, isEqual } from 'lodash'
import { useDrag, useDrop } from 'react-dnd'
import styled from 'styled-components/macro'

import { DragNDrop } from '../assets/'

const TYPE = 'ORDERABLE_SELECT_VALUE'

const Bucket = ({ name, values, moveItem }) => (
  <div className={`bucket ${name}`}>
    <div className="drop-zone-header">{startCase(name)}</div>
    <div className="drop-zone">
      {values.map(v => <Item key={v} name={v} bucket={name} moveItem={moveItem} />)}
      <Item bucket={name} moveItem={moveItem} ghost />
    </div>
  </div>
)

const Item = ({ moveItem, ghost, ...props }) => {
  const ref = useRef(null)
  const [{ isDragging }, drag] = useDrag({
    item: { type: TYPE, ...props },
    collect: monitor => ({ isDragging: monitor.isDragging() })
  })
  const move = (dragged) => {
    ref.current && dragged.name != props.name && moveItem(dragged, props)
  }
  const [, drop] = useDrop({ accept: TYPE, hover: move, drop: move })
  ghost ? drop(ref) : drag(drop(ref))

  return (
    <span
      ref={ref}
      className={`${ghost ? 'ghost' : 'item'} ${isDragging ? 'dragging' : ''}`}
    >
      {startCase(props.name)}
    </span>
  )
}

const available = (options, value) => (
  options.filter(o => value.indexOf(o) == -1).sort()
)

let throttled = false

// Redrawing every millisecond causes performance issues, and isn't necessary
const throttle = () => {
  if (throttled) { return true }
  throttled = true
  setTimeout(() => throttled = false, 50)
  return false
}

const DragNDropCenter = () => (
  <div className="drag-n-drop">
    <img src={DragNDrop} alt="Drag and Drop Icon" className="dnd-icon" />
    <span className="dnd-text">Drag and Drop</span>
  </div>
)

export const OrderableSelect = ({ options, value, onChange }) => {
  const moveItem = (dragged, replace) => {
    if (throttle()) { return }
    const next = value.filter(v => v != dragged.name)
    if (replace.bucket == 'selected') {
      const i = value.indexOf(replace.name)
      next.splice(i == -1 ? value.length : i, 0, dragged.name)
    }
    if (isEqual(value, next)) { return }
    onChange(next)
  }

  return (
    <Styling>
      <Bucket name="selected" values={value} moveItem={moveItem} />
      <DragNDropCenter />
      <Bucket name="available" values={available(options, value)} moveItem={moveItem} />
    </Styling>
  )
}

const Styling = styled.div`
  color: ${p => p.theme.textBlack};
  width: 100%;
  margin: -1em 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 53vh;

  .drag-n-drop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    .dnd-icon {
      width: 3em;
      filter: grayscale(100%);
    }
    .dnd-text {
      margin-top: 1em;
      font-size: 0.8em;
      color: black;
      font-weight: bold;
    }
  }

  .drop-zone-header {
    font-weight: 500;
    font-size: 1.2em;
    user-select: none;
    margin-top: 1em;

    &:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }

  .drop-zone {
    border: 1px solid #ccc;
    padding: 0.2em;
    width: 18em;
    max-height: 46vh;
    overflow-y: scroll;
    height: 100%;

    display: flex;
    flex-direction: column;
  }

  .available {
    .item {
      opacity: 0.7;
    }
  }

  .item {
    box-sizing: border-box;
    border-radius: 6px;
    background: #fef0ee;
    padding: 0.5em 1em;
    margin: 0.2em;
    cursor: grab;

    &.dragging {
      opacity: 0.25 !important;
    }
  }

  .ghost {
    min-width: 5em;
    min-height: 2em;
    flex-grow: 99;
  }
`
