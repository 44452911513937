export const CATEGORIES = {
  // https://www.gov.uk/penalty-points-endorsements
  AC10: 'Failing to stop after an accident',
  AC20: 'Failing to give particulars or report an accident within 24 hours',
  AC30: 'Undefined accident offences',
  BA10: 'Driving while disqualified by order of court',
  BA30: 'Attempting to drive while disqualified by order of court',
  BA40: 'Causing death by driving while disqualified',
  BA60: 'Causing serious injury by driving while disqualified',
  CD10: 'Driving without due care and attention',
  CD20: 'Driving without reasonable consideration for other road users',
  CD30: 'Driving without due care and attention or without reasonable consideration for other road users',
  CD40: 'Causing death through careless driving when unfit through drink',
  CD50: 'Causing death by careless driving when unfit through drugs',
  CD60: 'Causing death by careless driving with alcohol level above the limit',
  CD70: 'Causing death by careless driving then failing to supply a specimen for alcohol analysis',
  CD80: 'Causing death by careless, or inconsiderate, driving',
  CD90: 'Causing death by driving: unlicensed, disqualified or uninsured drivers',
  CU10: 'Using a vehicle with defective brakes',
  CU20: 'Causing or likely to cause danger by reason of use of unsuitable vehicle or using a vehicle with parts or accessories (excluding brakes, steering or tyres) in a dangerous condition',
  CU30: 'Using a vehicle with defective tyre(s)',
  CU40: 'Using a vehicle with defective steering',
  CU50: 'Causing or likely to cause danger by reason of load or passengers',
  CU80: 'Breach of requirements as to control of the vehicle, such as using a mobile phone',
  DD10: 'Causing serious injury by dangerous driving',
  DD40: 'Dangerous driving',
  DD60: 'Manslaughter or culpable homicide while driving a vehicle',
  DD80: 'Causing death by dangerous driving',
  DD90: 'Furious driving',
  DR10: 'Driving or attempting to drive with alcohol level above limit',
  DR20: 'Driving or attempting to drive while unfit through drink',
  DR30: 'Driving or attempting to drive then failing to supply a specimen for analysis',
  DR31: 'Driving or attempting to drive then refusing to give permission for analysis of a blood sample that was taken without consent due to incapacity',
  DR61: 'Refusing to give permission for analysis of a blood sample that was taken without consent due to incapacity in circumstances other than driving or attempting to drive',
  DR40: 'In charge of a vehicle while alcohol level above limit',
  DR50: 'In charge of a vehicle while unfit through drink',
  DR60: 'Failure to provide a specimen for analysis in circumstances other than driving or attempting to drive',
  DR70: 'Failing to co-operate with a preliminary test',
  DG10: 'Driving or attempting to drive with drug level above the specified limit',
  DG60: 'Causing death by careless driving with drug level above the limit',
  DR80: 'Driving or attempting to drive when unfit through drugs',
  DG40: 'In charge of a vehicle while drug level above specified limit',
  DR90: 'In charge of a vehicle when unfit through drugs',
  IN10: 'Using a vehicle uninsured against third party risks',
  LC20: 'Driving otherwise than in accordance with a licence',
  LC30: 'Driving after making a false declaration about fitness when applying for a licence',
  LC40: 'Driving a vehicle having failed to notify a disability',
  LC50: 'Driving after a licence has been cancelled (revoked) or refused on medical grounds',
  MS10: 'Leaving a vehicle in a dangerous position',
  MS20: 'Unlawful pillion riding',
  MS30: 'Play street offences',
  MS50: 'Motor racing on the highway',
  MS60: 'Offences not covered by other codes (including offences relating to breach of requirements as to control of vehicle)',
  MS70: 'Driving with uncorrected defective eyesight',
  MS80: 'Refusing to submit to an eyesight test',
  MS90: 'Failure to give information as to identity of driver etc',
  MW10: 'Contravention of special roads regulations (excluding speed limits)',
  PC10: 'Undefined contravention of pedestrian crossing regulations',
  PC20: 'Contravention of pedestrian crossing regulations with moving vehicle',
  PC30: 'Contravention of pedestrian crossing regulations with stationary vehicle',
  SP10: 'Exceeding goods vehicle speed limits',
  SP20: 'Exceeding speed limit for type of vehicle (excluding goods or passenger vehicles)',
  SP30: 'Exceeding statutory speed limit on a public road',
  SP40: 'Exceeding passenger vehicle speed limit',
  SP50: 'Exceeding speed limit on a motorway',
  TS10: 'Failing to comply with traffic light signals',
  TS20: 'Failing to comply with double white lines',
  TS30: 'Failing to comply with ‘stop’ sign',
  TS40: 'Failing to comply with direction of a constable/warden',
  TS50: 'Failing to comply with traffic sign (excluding ‘stop’ signs, traffic lights or double white lines)',
  TS60: 'Failing to comply with a school crossing patrol sign',
  TS70: 'Undefined failure to comply with a traffic direction sign',
  UT50: 'Aggravated taking of a vehicle',
  MR09: 'Reckless or dangerous driving (whether or not resulting in death, injury or serious risk)',
  MR19: 'Wilful failure to carry out the obligation placed on driver after being involved in a road accident (hit or run)',
  MR29: 'Driving a vehicle while under the influence of alcohol or other substance affecting or diminishing the mental and physical abilities of a driver',
  MR39: 'Driving a vehicle faster than the permitted speed',
  MR49: 'Driving a vehicle whilst disqualified',
  MR59: 'Other conduct constituting an offence for which a driving disqualification has been imposed by the State of Offence',
  // Northern Ireland: https://www.nidirect.gov.uk/articles/endorsements-and-penalty-points
  '50 08 123': 'Drinking or drugs and driving',
  '50 08 124': 'Drinking or drugs and driving',
  '50 08 125': 'Drinking or drugs and driving',
  '50 08 204': 'Causing death by careless driving while under the influence of drink or drugs',
  '50 08 208': 'Causing death by careless driving while under the influence of drink or drugs',
  '50 08 212': 'Causing death by careless driving, then failing to provide a specimen for analysis',
  '50 08 201': 'Dangerous driving',
}
