import React from 'react'
import styled from 'styled-components/macro'
import { startCase } from 'lodash'

import { capitalCase } from '../common/'
import { driverLicence } from '../assets/'

import { EmailSelectWrapper } from './EmailSelect'
import { LinkVehicleField } from './LinkVehicles'

const Overview = (props) => (
  <Styling>
    <div className="image">
      <img alt="placeholder" src={driverLicence} />
    </div>
    <div className="details">
      <Name {...props} />
      <Vehicles {...props} />
      {Object.keys(FIELDS).map(f => <Field name={f} key={f} value={props[f]} />)}
      <Email { ...props} />
    </div>
  </Styling>
)

const Vehicles = ({ id }) => (
  <div className="actionable">
    <div className="left">
      <label>Linked Vehicle</label>
    </div>
    <LinkVehicleField id={id} />
  </div>
)

const Email = ({ email, id }) => (
  <div className="actionable">
    <div className="left">
      <label>Email</label>
    </div>
    <EmailSelectWrapper className="right" value={email} id={id}>
      <span className="value">{email || '<none>'}</span>
      <span className="edit">Edit</span>
    </EmailSelectWrapper>
  </div>
)

const Field = ({ name, value }) => (
  <div>
    <label>{FIELDS[name].label || startCase(name)}</label>
    <span className="value">
      {format(name, value)}
    </span>
  </div>
)

const format = (name, value) => {
  if (!value) { return <span className="not-applicable">N/A</span> }
  if (FIELDS[name].format) { return FIELDS[name].format(value) }
  return value
}

const shortDate = (string) => (
  new Date(string).toLocaleDateString(
    'default',
    { day: 'numeric', month: 'short', year: 'numeric' }
  )
)

const FIELDS = {
  licenceNumber: {},
  issueNumber: {},
  validFrom: { format: shortDate },
  validTo: { format: shortDate },
  dob: { label: 'Date Of Birth', format: shortDate },
  gender: { format: capitalCase },
  address: {},
}

const Name = ({ name }) => (
  <div className="name">
    <span>{name}</span>
  </div>
)

const Styling = styled.div`
  background: white;
  margin-right: 1rem;
  width: 24rem;

  .image {
    display: flex;
  }
  img {
    width: 100%;
    height: auto;
    float: center;
  }
  .top {
    width: 20rem;
    padding-bottom: 1rem;
  }
  .name {
    font-size: 1.5rem;
  }
  .updated-at {
    font-style: italic;
    color: #B9B9B9;
    font-size: .8rem;
  }
  .details {
    > div {
      border-top: 0.1rem solid #00000015;
      padding: 1rem;
      padding-left: 1.5rem;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      .value {
        width: 50%;
      }
    }
  }
  .actionable {
    display: flex;
    flex-direction: row;
    border-left: 0.5rem solid ${p => p.theme.primaryColor};
    justify-content: space-between;
    padding-left: 1rem !important;
    .left {
      width: 50%;
    }
    .right {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 50%;
      cursor: pointer;
    }
    .name, .registration {
      color: ${p => p.theme.primaryColor};
    }
    .double {
      margin-bottom: 1.13rem;
    }
    .edit {
      color: #B9B9B9;
    }
  }
  label {
    color: #B9B9B9;
  }
  .not-applicable {
    opacity: 0.5;
  }
`

export default Overview
