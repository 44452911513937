import React, { Component } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import ProtectedRoute from './ProtectedRoute'

import Signup from '../users/Signup'
import { SignupSuccess } from '../users'
import ConfirmAccount from '../users/ConfirmAccount'
import Login, { PasswordChange } from '../users/Login'
import NewVehicles from '../vehicles/NewVehicles'
import Vehicle from '../vehicles/Show'
import Welcome from '../users/Welcome'
import DriverDashboard from '../drivers/DriverDashboard'
import DriversNew from '../drivers/New'
import DriversGuest from '../drivers/Guest'
import Driver from '../drivers/Show'
import Dashboard, { FleetDashboard } from '../dashboard/Dashboard'
import Settings from '../settings/Settings'

export default class Routes extends Component {
  render = () => {
    return (
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/signup/success" component={SignupSuccess} />
        <Route exact path="/confirm_account" component={ConfirmAccount} />
        <Route exact path="/password_change/:verification" component={PasswordChange} />
        <Route exact path="/user_confirm/:verification" component={PasswordChange} />

        <ProtectedRoute exact path="/welcome" component={Welcome} />
        <ProtectedRoute exact path="/dashboard" component={Dashboard} />
        <ProtectedRoute exact path="/vehicles/new" component={NewVehicles} />
        <ProtectedRoute exact path="/vehicles/:id" component={Vehicle} />
        <ProtectedRoute exact path="/drivers" component={DriverDashboard} />
        <ProtectedRoute exact path="/drivers/new" component={DriversNew} />
        <ProtectedRoute exact path="/drivers/:id" component={Driver} />
        <ProtectedRoute exact path="/fleet" component={FleetDashboard} />
        <ProtectedRoute exact path="/settings" component={Settings} />
        <Route exact path="/drivers/register/:verification" component={DriversGuest} />

        <Route render={() => <Redirect to="/dashboard" />} />
      </Switch>
    )
  }
}
