import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'

import Api from '../api/call'
import { FLEET } from '../root/action-types'

import Groups from './Groups'
import Tags from './Tags'

const Sidebar = () => {
  useEffect(() => Api({ request: FLEET }), [])
  const [expanded, setExpanded] = useState(true)

  if (expanded) {
    return (
      <Styling className="side-table">
        <div className="manage expanded">
          <h2>Manage your fleet</h2>
          <FontAwesomeIcon
            icon="caret-square-left"
            onClick={() => setExpanded(false)}
          />
        </div>
        <Groups />
        <Tags />
      </Styling>
    )
  }
  return (
    <Styling className="side-table">
      <div className="manage collapsed">
        <FontAwesomeIcon
          icon="caret-square-right"
          onClick={() => setExpanded(true)}
        />
        <h2>Manage your fleet</h2>
      </div>
    </Styling>
  )
}

const Styling = styled.div`
  .manage {
    display: flex;
    background: white;
    padding: 1rem;

    h2 {
      color: ${p => p.theme.primaryColor};
      margin: 0;
    }

    svg {
      color: ${p => p.theme.primaryColor};
      transform: scale(1.4);
      padding-top: 0.2rem;
      padding-right: 0.4rem;
      cursor: pointer;
    }
  }

  .manage.expanded {
    justify-content: space-between;
  }
  .manage.collapsed {
    flex-direction: column;
    height: calc(100% - 4rem);
    max-width: 5rem;

    h2 {
      font-size: 0.75rem;
    }
    svg {
      padding-bottom: 0.5rem;
      margin-left: 0.25rem;
    }
  }
`

export default Sidebar
