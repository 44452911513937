import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'

import Api from '../../api/call'
import { DRIVERS_UNASSOCIATE } from '../../root/action-types'
import { SettingsTab, ModalContainer } from '../../vehicles/tabs/Settings'
import { Conditional, Loaded } from '../../common'
import { download } from '../../assets'
import ModalWindow from '../../layout/ModalWindow'
import useDownloadCSV from '../../dashboard/useDownloadCSV'

const OMIT = [
  'id',
  'organisationId',
  'createdAt',
  'updatedAt',
  'disqualifications',
  'penalties'
]

export const Settings = () => {
  const { data, unassociated, loading } = useSelector(r => r.drivers.show)
  const { buildFile, link, url } = useDownloadCSV(data, OMIT)
  const [active, setActive] = useState(false)
  const toggle = () => setActive(!active)
  const history = useHistory()

  const deleteDriver = () => (
    Api({ request: DRIVERS_UNASSOCIATE, id: get(data, 'id') })
  )
  if (unassociated) {
    history.push('/drivers')
  }

  return (
    <SettingsTab>
      <div>General</div>
      <div onClick={buildFile}>
        Export data
        <a
          href={url}
          download={`${data.licenceNumber}.csv`}
          ref={link}
          style={{ display: 'none' }}
        >
        </a>
        <img src={download} alt="Export data"/>
      </div>
      <div onClick={toggle}>Delete Driver</div>
      <Conditional if={active}>
        <ModalWindow isOpen closeModal={toggle} className="modal-zone">
          <ModalContainer>
            <Loaded loading={loading}>
              <h2>Are you sure you want to delete this driver?</h2>
              <div>
                <button onClick={deleteDriver}>Yes</button>
                <button onClick={toggle}>Cancel</button>
              </div>
            </Loaded>
          </ModalContainer>
        </ModalWindow>
      </Conditional>
    </SettingsTab>
  )
}
