import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'

import Api from '../../api/call'
import { Conditional, Loaded } from '../../common'
import { VEHICLES_UNASSOCIATE } from '../../root/action-types'
import { download } from '../../assets'
import ModalWindow from '../../layout/ModalWindow'
import useDownloadCSV from '../../dashboard/useDownloadCSV'

const OMIT = [
  'id',
  'ovid',
  'confirmed',
  'createdAt',
  'updatedAt',
  'motTests',
  'addedAt',
  'drivers',
  'auditLog'
]

export const Settings = () => {
  const { data, unassociated, loading } = useSelector(r => r.vehicles.show)
  const { buildFile, link, url } = useDownloadCSV(data, OMIT)
  const [active, setActive] = useState(false)
  const toggle = () => setActive(!active)
  const history = useHistory()

  const deleteVehicle = () => {
    const id = `${get(data, 'ovid')}/unassociate`
    Api({ request: VEHICLES_UNASSOCIATE, id })
  }
  if (unassociated) {
    history.push('/dashboard')
  }

  return (
    <SettingsTab>
      <div>General</div>
      <div onClick={buildFile}>
        Export data
        <a
          href={url}
          download={`${data.registration}.csv`}
          ref={link}
          style={{ display: 'none' }}
        >
        </a>
        <img src={download} alt="Export data"/>
      </div>
      <div onClick={toggle}>Delete Vehicle</div>
      <Conditional if={active}>
        <ModalWindow isOpen closeModal={toggle} className="modal-zone">
          <ModalContainer>
            <Loaded loading={loading}>
              <h2>Are you sure you want to delete this vehicle?</h2>
              <div>
                <button onClick={deleteVehicle}>Yes</button>
                <button onClick={toggle}>Cancel</button>
              </div>
            </Loaded>
          </ModalContainer>
        </ModalWindow>
      </Conditional>
    </SettingsTab>
  )
}

export const SettingsTab = styled.div`
  padding: 1.9rem;
  width: 50%;

  > div {
    padding: 1rem 0;
    border-bottom: 1px solid #00000015;
    cursor: pointer;

    > img {
      width: 1.5rem;
      height: 1.5rem;
      filter: invert();
      vertical-align: middle;
    }
  }
  > div:first-child {
    font-weight: 700;
    cursor: initial;
  }
`

export const ModalContainer = styled.div`
  > div {
    display: flex;

    > button:first-child {
      margin-left: auto;
      background: ${props => props.theme.primaryColor};
    }
    > button:last-child {
      margin-left: 1rem;
      color: black;
    }
  }
`
