import React from 'react'
import ReactToggle from 'react-toggle'
import styled from 'styled-components/macro'

import './react-toggle.scss'

export const Toggle = ({ label, ...props }) => (
  <Style className="toggle">
    <ReactToggle icons={false} {...props} />
    <span>{label}</span>
  </Style>
)

const Style = styled.label`
  span {
    padding-left: 2em;
    position: relative;
    bottom: 0.45rem;
    font-size: 0.9rem;
  }
}

`
