import React, { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DayPicker, { DateUtils } from 'react-day-picker'

import { Conditional } from './'

import 'react-day-picker/lib/style.css'
import './date-select.scss'

export const DateSelectWrapper = ({ children, ...props }) => {
  const [active, setActive] = useState(false)
  const toggle = () => setActive(!active)

  return (
    <div className="date-select" onClick={toggle}>
      {children}
      <Conditional if={active}>
        <Active {...props} hide={toggle} />
      </Conditional>
    </div>
  )
}

const DateSelectInput = (props) => (
  <DateSelectWrapper {...props}>
    <FontAwesomeIcon icon="calendar-week" />
    <input value={prettyRange(props)} readOnly />
  </DateSelectWrapper>
)

const prettyRange = ({ from, to }) => {
  const a = format(from)
  const b = format(to)
  if (a && b) return `${a} - ${b}`
  if (a) return `from ${a}`
  if (b) return `to ${b}`
  return 'Always'
}

const format = (date) => (
  date && date.toLocaleString('default', { day: 'numeric', month: 'numeric', year: 'numeric' })
)

const DAY = 86400000

// The from and to dates get mutated, so they need to be unique objects
const today = () => {
  const from = new Date()
  return { from, to: new Date(from) }
}
const yesterday = () => {
  const from = new Date(Date.now() - DAY)
  return { from, to: new Date(from) }
}
const last7Days = () => (
  { from: new Date(Date.now() - 7 * DAY), to: new Date() }
)
const last30Days = () => (
  { from: new Date(Date.now() - 30 * DAY), to: new Date() }
)
const thisMonth = () => {
  const today = new Date()
  return { from: new Date(today.getFullYear(), today.getMonth(), 1), to: today }
}
const lastMonth = () => {
  const today = new Date()
  const month = today.getMonth()
  const year = today.getFullYear()
  const to = new Date(year, month, 0)
  if (month == 0) {
    return { from: new Date(year - 1, 11, 1), to }
  }
  return { from: new Date(year, month - 1, 1), to }
}

const Active = ({ from, to, onChange, hide }) => {
  const ref = useRef(null)
  useEffect(() => {
    const clickOutside = (e) => {
      ref.current && !ref.current.contains(e.target) && hide()
    }
    document.addEventListener("mousedown", clickOutside)
    return () => { document.removeEventListener("mousedown", clickOutside) }
  }, [ref])
  const update = (range) => {
    if (range.from) { range.from.setHours(0, 0, 0, 0) }
    if (range.to) { range.to.setHours(23, 59, 59, 999) }
    onChange(range)
  }
  const dayClick = (day) => {
    const range = DateUtils.addDayToRange(day, { from, to })
    update(range)
  }

  return (
    <div className="active" ref={ref} onClick={e => e.stopPropagation()}>
      <div className="tip" />
      <div className="main">
        <DayPicker
          className="date-range-picker"
          numberOfMonths={2}
          selectedDays={[from, { from, to }]}
          modifers={{ start: from, end: to }}
          onDayClick={dayClick}
        />
        <div className="controls">
          <button onClick={() => update(today())}>Today</button>
          <button onClick={() => update(yesterday())}>Yesterday</button>
          <button onClick={() => update(last7Days())}>Last 7 Days</button>
          <button onClick={() => update(last30Days())}>Last 30 Days</button>
          <button onClick={() => update(thisMonth())}>This Month</button>
          <button onClick={() => update(lastMonth())}>Last Month</button>
          <button className="always" onClick={() => update({})}>Always</button>
        </div>
      </div>
    </div>
  )
}

export default DateSelectInput
