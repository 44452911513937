export const RiskAssessor = ({ riskBand1, riskBand2 }) => {
  const bands = {
    low: parseFloat(riskBand1) || 4,
    medium: parseFloat(riskBand2) || 7,
    high: Infinity
  }

  const driver = ({ penaltyPoints }) => {
    const points = parseFloat(penaltyPoints) || 0
    return Object.keys(bands).find(r => points < bands[r])
  }

  return { bands, driver }
}
