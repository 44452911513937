import React from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import styled from 'styled-components/macro'

import { Loading } from '../common'
import { ReactComponent as BankIcon } from '../assets/bank.svg'
import * as cardIcons from '../assets/cardicons'

const PaymentDetails = ({ toggleUpdate, toggleCancel }) => {
  const { paymentDetails: paymentMethod, loading } = useSelector(r => r.users.billing)

  const { Top, leftData, rightData } = paymentMethodDetails(paymentMethod)
  const altText = paymentMethod.id ? 'Update' : 'Add'

  return (
    <PaymentDetailsContainer>
      <h2>Payment Details</h2>
      <div className="payment-details-elements">
        {loading === false ? (
          <PaymentMethod
            Top={Top}
            leftData={leftData}
            rightData={rightData}
          />
        ) : (
          <Loading />
        )}
        <button className="update-btn" onClick={toggleUpdate}>{altText} Payment Details</button>
        <button className="cancel-btn" onClick={toggleCancel}>Cancel Details</button>
      </div>
    </PaymentDetailsContainer>
  )
}

const paymentMethodDetails = paymentMethod => {
  let Top = null
  let leftData = ''
  let rightData = ''

  if (paymentMethod.paymentType === 'card') {
    const { last4, expMonth, expYear, brand } = paymentMethod

    Top = () => Card({ brand })
    leftData = `**** **** **** ${last4}`
    rightData = `Expires: ${expMonth}/${expYear}`
  }

  if (paymentMethod.paymentType === 'bacs_debit') {
    const { last4, sortCode, country } = paymentMethod

    Top = () => DirectDebit({ country })
    leftData = `Sort code: ${sortCode.match(/.{1,2}/g).join('-')}`
    rightData = `Account no: ****${last4}`
  }

  return { Top, leftData, rightData }
}

const Card = ({ brand }) => (
  <div className="top card">
    <img src={get(cardIcons, brand, cardIcons.elseCard)} alt="" />
    <div className="brand">{brand}</div>
  </div>
)

const DirectDebit = ({ country }) => (
  <div className="top direct-debit">
    <BankIcon />
    <div>Direct Debit</div>
    <div className="country">{country}</div>
  </div>
)

const PaymentMethod = ({ Top, leftData, rightData }) => (
  <PaymentMethodContainer>
    {Top ? (<Top />) : (<div className="top"></div>)}
    <div className="bottom">
      <div className="left">{leftData}</div>
      <div className="right">{rightData}</div>
    </div>
  </PaymentMethodContainer>
)

const PaymentDetailsContainer = styled.div`
  flex: 1 auto;
  margin: 0;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  max-width: 370px;

  @media screen and (max-width: 1750px) {
    flex-basis: 100%;
    max-width: unset;
  }

  .payment-details-elements {
    display: flex;
    flex-direction: column;
    flex: 1;

    @media screen and (max-width: 1750px) {
      flex-wrap: wrap;
    }

    > div {
      flex: 50%;
      border: 1px solid lightgrey;
      border-radius: 5px;
      @media screen and (max-width: 1750px) {
        flex: 100%;
        height: 100%;
        margin-right: 0.5rem;
      }
    }

    > button {
      flex: 25%;
      cursor: pointer;
    }

    .update-btn {
      background: #B9B9B9;
      color: white;
      border: none;
      border-radius: 5px;
      @media screen and (min-width: 1750px) {
        margin-top: 0.5rem;
      }
    }

    .cancel-btn {
      background: white;
      color: #B9B9B9;
      border: 1px solid #B9B9B9;
      border-radius: 5px;
      margin-top: 0.5rem;
    }
  }
`

const PaymentMethodContainer = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #68697B;

  .top {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid lightgrey;
    font-weight: bold;
    display: flex;
    align-items: center;

    > *:first-child {
      margin-right: .5rem;
    }

    &.direct-debit {
      div:last-child {
        margin-left: auto;
        padding: .3rem .95rem;
        border: 1px solid lightgrey;
        border-radius: 3px;
      }

      > svg {
        height: 1rem;
        fill: white;
        background: #B1AFB1;
        padding: .3rem .95rem;
        border-radius: 3px;
      }
    }

    &.card {
      > img { height: 2rem }
    }

    .brand {
      text-transform: capitalize;
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;

    > * { flex: 1 1 auto }

    .left {
      border-right: 1px solid lightgrey;
      text-align: left;
    }

    .right {
      text-align: right;
    }
  }
`

export default PaymentDetails
