import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { pick } from 'lodash'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Conditional, Loading } from '../common'
import Api from '../api/call'
import { SEARCH, SEARCH_RESET_STORE } from '../root/action-types'

const VEHICLE = ['make', 'model', 'colour', 'fuelType']

const infoString = obj => Object.values(pick(obj, VEHICLE)).join(', ')

const Searchbar = () => {
  const ref = useRef(null)
  const [active, setActive] = useState(false)
  const { loading, drivers, vehicles } = useSelector(r => r.layout.search)
  const dispatch = useDispatch()
  const [query, setQuery] = useState('')
  // https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
  const [dbq, setDbq] = useState(query)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDbq(query)
    }, 1000)

    return () => {
      clearTimeout(handler)
    }
  }, [query])

  useEffect(() => {
    if (dbq) {
      setActive(true)
      Api({ request: SEARCH, id: `?query=${dbq}` })
    } else {
      setActive(false)
      dispatch({ type: SEARCH_RESET_STORE })
    }
  }, [dbq])

  useEffect(() => {
    const clickOutside = (e) => {
      ref.current && !ref.current.contains(e.target) && setActive(false)
    }
    document.addEventListener("mousedown", clickOutside)
    return () => { document.removeEventListener("mousedown", clickOutside) }
  }, [ref])

  const resetResults = () => {
    setActive(false)
    setQuery('')
  }

  return (
    <Search ref={ref}>
      <InputWrapper>
        <FontAwesomeIcon icon="search" />
        <input value={query} onChange={e => setQuery(e.target.value)} onFocus={() => dbq && setActive(true)} />
      </InputWrapper>
      <Conditional if={active}>
        <ResultsContainer>
          {(loading !== false) ? (
            <Loading />
          ) : (
            <>
              <ResultList
                type="vehicles"
                data={vehicles}
                linkAccessor="registration"
                textFn={infoString}
                resetFn={resetResults}
              />
              <ResultList
                type="drivers"
                data={drivers}
                linkAccessor="licenceNumber"
                textFn={d => d.name}
                resetFn={resetResults}
              />
              <Conditional if={dbq && (drivers.length + vehicles.length === 0)}>
                <div className="no-results">No results found</div>
              </Conditional>
            </>
          )}
        </ResultsContainer>
      </Conditional>
    </Search>
  )
}

const ResultList = ({ type, data, linkAccessor, textFn, resetFn }) => (
  <Conditional if={data.length}>
    <div className="header">{type}</div>
    {data.map(d => (
      <Result key={d.id}>
        <Link to={`/${type}/${d.id}`} onClick={resetFn}>
          {d[linkAccessor]}
        </Link>
        <div>{textFn(d)}</div>
      </Result>
    ))}
  </Conditional>
)

const Search = styled.div`
  flex: 0 50%;
  position: relative;
  margin-left: 2.5rem;

  * { box-sizing: border-box }
`
const ResultsContainer = styled.div`
  position: absolute;
  background: white;
  z-index: 10;
  width: 100%;
  box-shadow: 0 3px 6px rgba(0,0,0,0.05);

  .header {
    color: #CACACA;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #F6F7FA;
    font-size: 0.9rem;
    text-transform: capitalize;
  }

  .no-results {
    color: #CACACA;
    padding: 1rem;
  }
`
const Result = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 1rem 1.5rem;
  width: 100%;
  min-width: 0;
  max-width: 40rem;

  > a {
    color: black;
    font-weight: 700;
    flex: 1;
    margin-right: 1rem;
  }

  > div {
    flex: 4;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`
const InputWrapper = styled.div`
  width: 100%;
  position: relative;

  > input {
    border: none;
    outline: none;
    border-bottom: 1px solid gray;
    width: 100%;
    padding-left: 1.5rem;
    padding-bottom: 0.5rem;
  }

  svg {
    position: absolute;
    color: grey;
    top: .1rem;
  }
`

export default Searchbar
