import { combineReducers } from 'redux'

import { standardModelCreateUpdateDestroy } from '../api/standard-reducers'
import { setAuthToken, setUser, redirectToHome } from '../api/call'
import {
  ACCOUNT,
  ACCOUNT_UPDATE,
  CURRENT_USER,
  DRIVERS_CREATE,
  LOGIN,
  SIGN_UP,
  ORGANISATIONS_SHOW,
  ORGANISATIONS_UPDATE,
  PASSWORD_RESET,
  PASSWORD_CHANGE,
  STRIPE_CANCEL_DETAILS,
  STRIPE_CONFIRM_CARD,
  STRIPE_CONFIRM_DIRECT_DEBIT,
  STRIPE_CREATE_CHECKOUT_SESSION,
  STRIPE_FETCH_PRICES,
  STRIPE_CREATE_SETUP_INTENT,
  BILLING_INDEX,
  USERS,
  USERS_CREATE,
  USERS_UPDATE,
  USERS_DESTROY,
  USERS_SAVE_COLUMNS,
  SELECT_USER,
  SELECT_USER_DESTROY,
  VEHICLES_CONFIRM,
} from '../root/action-types'

const DEFAULT_STATE = {
  loading: false,
  errors: [],
  details: {},
}

const INITIAL_CURRENT = {}

export const current = (state = INITIAL_CURRENT, action) => {
  switch (action.type) {
    case `${CURRENT_USER}_START`: {
      return { ...INITIAL_CURRENT, loading: true }
    }
    case `${STRIPE_CANCEL_DETAILS}_SUCCESS`:
    case `${ACCOUNT_UPDATE}_SUCCESS`:
    case `${CURRENT_USER}_SUCCESS`: {
      return {
        ...state,
        loading: false,
        organisation: action.response.data.organisation,
        lastUpdate: action.response.data.lastUpdate,
        ...action.response.data.user,
      }
    }
    case `${ORGANISATIONS_SHOW}_START`:
    case `${ORGANISATIONS_UPDATE}_START`: {
      return { ...state, loading: true }
    }
    case `${ORGANISATIONS_SHOW}_SUCCESS`:
    case `${ORGANISATIONS_UPDATE}_SUCCESS`: {
      return {
        ...state, loading: false, organisation: action.response.data
      }
    }
    case `${DRIVERS_CREATE}_SUCCESS`:
    case `${VEHICLES_CONFIRM}_SUCCESS`:
    case `${STRIPE_CONFIRM_CARD}_SUCCESS`: {
      return {
        ...state,
        changes: (state.changes || 0) + 1
      }
    }
    case `${CURRENT_USER}_FAILURE`: {
      return { ...state, loading: false, error: action.response.data.error }
    }
    case `${CURRENT_USER}_FORBIDDEN`: {
      redirectToHome()
      return {
        ...state,
        loading: false,
        error: action.response.data.error
      }
    }
    case `${USERS_SAVE_COLUMNS}_SUCCESS`: {
      const { id, columns } = action.response.data.params
      return {
        ...state,
        columns: { ...state.column, [id]: columns }
      }
    }
    default: {
      return state
    }
  }
}

export const login = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case `${LOGIN}_START`:
    case `${PASSWORD_RESET}_START`:
      return { ...state, loading: true, errors: null }
    case `${SIGN_UP}_SUCCESS`:
    case `${LOGIN}_SUCCESS`: {
      setAuthToken(action.response.data.authToken)
      setUser(action.response.data.user)
      return {
        ...state,
        loading: false,
        errors: null,
      }
    }
    case `${PASSWORD_RESET}_SUCCESS`:
      return {
        ...state,
        loading: false,
        reset: true
      }
    case `${LOGIN}_FAILURE`:
    case `${PASSWORD_RESET}_FAILURE`:
      return { ...state, loading: false, reset: false, errors: action.response.data.errors }
    default:
      return state
  }
}

const change = (state = {}, action) => {
  switch (action.type) {
    case `${PASSWORD_CHANGE}_START`:
      return { loading: true, errors: [] }
    case `${PASSWORD_CHANGE}_SUCCESS`:
      return { loading: false, success: true, errors: [] }
    case `${PASSWORD_CHANGE}_FAILURE`:
      return { loading: false, errors: action.response.data.errors }
    default:
      return state
  }
}

const DEFAULT_SIGNUP_STATE = {
  user: {}, organisation: {}, session: {}
}

const signup = (action_name) => (
  (state = DEFAULT_SIGNUP_STATE, action) => {
    switch (action.type) {
      case `${action_name}_START`:
        return {
          ...state,
          loading: true,
        }
      case `${action_name}_SUCCESS`:
        return {
          ...state,
          loading: false,
          errors: null,
          ...action.response.data,
        }
      case `${action_name}_FAILURE`:
        return {
          ...state,
          loading: false,
          errors: action.response.data,
        }
      case `${action_name}_RESET_STORE`:
        return DEFAULT_SIGNUP_STATE
      default:
        return state
    }
  }
)

const DEFAULT_SETTINGS_STATE = {
  paymentDetails: {}
}

const billing = (state = DEFAULT_SETTINGS_STATE, action) => {
  switch (action.type) {
    case `${BILLING_INDEX}_START`:
      return {
        ...state,
        loading: true,
      }
    case `${BILLING_INDEX}_SUCCESS`:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: null,
        ...action.response.data
      }
    case `${BILLING_INDEX}_FAILURE`:
      return {
        ...state,
        loading: false,
        errors: action.response.data,
      }
    default:
      return state
  }
}

const INDEX = { users: {}, errors: [] }

const index = (state = INDEX, action) => {
  switch (action.type) {
    case `${USERS}_START`: {
      return { ...INDEX, loading: true }
    }
    case `${USERS_DESTROY}_SUCCESS`:
    case `${USERS}_SUCCESS`: {
      return { ...INDEX, users: action.response.data, loading: false, loaded: true }
    }
    case `${USERS_CREATE}_SUCCESS`: {
      const data = [...state.users.data, action.response.data]
      return { ...INDEX, users: { data } }
    }
    case `${USERS_UPDATE}_SUCCESS`: {
      const id = action.response.data.id
      const index = state.users.data.indexOf(state.users.data.find(u => u.id === id))
      const data = Object.assign(state.users.data.slice(), { [index]: action.response.data })
      return { ...INDEX, users: { data } }
    }
    case `${USERS}_FAILURE`: {
      return { ...INDEX, errors: action.response.data }
    }
    default: {
      return state
    }
  }
}

const selected = (state = {}, action) => {
  switch (action.type) {
    case SELECT_USER: {
      return { ...state, id: action.id }
    }
    case SELECT_USER_DESTROY: {
      return { ...state, destroying: action.id }
    }
    case `${USERS_DESTROY}_SUCCESS`: {
      return { ...state, destroying: null }
    }
    default: {
      return state
    }
  }
}

const prices = (state = {}, action) => {
  switch (action.type) {
    case `${STRIPE_FETCH_PRICES}_START`: {
      return { ...state, loaded: false }
    }
    case `${STRIPE_FETCH_PRICES}_SUCCESS`: {
      return { ...state, loaded: true, prices: action.response.data }
    }
    default: {
      return state
    }
  }
}

const cancelDetails = (state = {}, action) => {
  switch (action.type) {
    case `${STRIPE_CANCEL_DETAILS}_START`: {
      return { ...state, loading: true }
    }
    case `${STRIPE_CANCEL_DETAILS}_SUCCESS`: {
      return { ...state, ...action.response.data, loading: false }
    }
    default: {
      return state
    }
  }
}

export default combineReducers({
  login,
  current,
  change,
  billing,
  index,
  selected,
  prices,
  cancelDetails,
  signup: signup(SIGN_UP),
  confirmCard: signup(STRIPE_CONFIRM_CARD),
  confirmDirectDebit: signup(STRIPE_CONFIRM_DIRECT_DEBIT),
  signupCheckoutSession: signup(STRIPE_CREATE_CHECKOUT_SESSION),
  stripeSetupIntent: signup(STRIPE_CREATE_SETUP_INTENT),
  account: standardModelCreateUpdateDestroy('Account', ACCOUNT),
  crud: standardModelCreateUpdateDestroy('user', USERS),
})
