import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'

export const formatAmount = (currency, amount) => (
  `${CURRENCIES[currency]}${parseFloat(amount / 100).toFixed(2)}`
)

export const CURRENCIES = { gbp: '£' }
const ROW_ORDER = ['vehicle', 'licence']

const NextPayment = () => {
  const { invoice } = useSelector(r => r.users.billing)
  const { amountDue, dueDate, currency, lines } = invoice || {}

  const formatDueDate = (date) => {
    if (!date) return

    const [day, month, year] = new Date(date).toLocaleDateString('en-GB', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    }).split(' ')

    return `${month} ${day}, ${year}`
  }

  const formatRowName = (liKey) => {
    if (liKey.match(/vehicle/i)) return 'Vehicles'
    if (liKey.match(/licence/i)) return 'Driving Licence'
  }

  if (!invoice) return null

  return (
    <NextPaymentContainer>
      <h2>Next Payment</h2>
      <div className="next-payment">
        <div className="next-payment-due">
          <div className="due-amount">
            {formatAmount(currency, amountDue)}
          </div>
          <div className="due-date">
            {formatDueDate(dueDate * 1000)}
          </div>
        </div>
        <div className="next-payment-breakdown">
          <table>
            <thead>
              <tr>
                <th>Description</th>
                <th>Qty</th>
                <th>Price</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {ROW_ORDER.map((r, i) => (
                <tr key={`${r}_${i}`}>
                  <td>{formatRowName(r)}</td>
                  <td>{lines[r].quantity}</td>
                  <td>{formatAmount(currency, lines[r].price)}</td>
                  <td>{formatAmount(currency, lines[r].total)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td></td>
                <td>Amount due</td>
                <td>{formatAmount(currency, amountDue)}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </NextPaymentContainer>
  )
}

const NextPaymentContainer = styled.div`
  flex: 1 55%;
  margin: 0;
  padding: 0 2rem 1rem 0;

  @media screen and (max-width: 1750px) {
    padding-right: 0;
  }

  .next-payment {
    display: flex;
    text-align: center;
    border: 1px solid lightgrey;
    border-radius: 3px;

    > div {
      padding: 2rem;
      align-self: center;
    }
  }

  .next-payment-due {
    flex: 25%;
  }

  .next-payment-breakdown {
    background: #F5F6F9;
    text-align: left;
    flex: 75%;

    table {
      border-collapse: collapse;
      width: 100%;
      font-size: .9rem;
    }
    td, th {
      min-width: max-content;
      padding: .5rem 1rem .5rem 0;
    }
    tr { border-bottom: 1px solid #00000015 }
    tfoot {
      font-weight: bold;
      tr { border-bottom: unset }
    }
  }

  .due-amount {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: .75rem;
  }

  .due-date {
    font-weight: bold;
    min-width: max-content;

    &::before {
      content: 'Due on';
      display: block;
      color: lightgrey;
      font-weight: normal;
      margin-bottom: .25rem;
    }
  }
`

export default NextPayment
