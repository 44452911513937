import users from '../users/reducers'
import vehicles from '../vehicles/reducers'
import drivers from '../drivers/reducers'
import dashboard from '../dashboard/reducer'
import attachments from '../attachments/reducers'
import fleet from '../fleet/reducer'
import layout from '../layout/reducer'
import { notifications } from '../notifications/reducer'

export const reducers = {
  attachments,
  dashboard,
  drivers,
  fleet,
  notifications,
  users,
  vehicles,
  layout,
}
