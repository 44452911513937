import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import propTypes from 'prop-types'

import Api from '../api/call'
import { DRIVERS_CREATE, DRIVERS_CREATE_RESET_STORE, DRIVERS_GUEST } from '../root/action-types'
import { Button } from '../common'
import { Field, useForm, Status, LoadingAbsolute, Conditional } from '../common/'
import Declaration from './Declaration'

// Images:
import { licence } from '../assets/'

const VALID_LICENCE_NO = /^([A-Z]{2}[9]{3}|[A-Z]{3}[9]{2}|[A-Z]{4}[9]{1}|[A-Z]{5})[0-9]{6}([A-Z]{1}[9]{1}|[A-Z]{2})[A-Z0-9]{3}[0-9]{0,2}$/

const BLANK_FORM = { licence: '' }
const INVALID_MSG = 'Details entered are not valid, please check and re-enter'

const FormFields = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${Field}.full { flex: 1 100%; }
  ${Field}.half {
    flex: 0 48.5%;
    &:last-of-type { margin-left: auto; }
  }
`
const RegisterButton = styled(Button)`
  padding: 0.6rem 1rem;
  width: 8rem;
  margin: auto 0 0 0;
  font-size: 0.875em;
  align-self: flex-start;
  justify-self: flex-end;

  &:disabled {
    background-color: #B9B9B9;
    border-color: #B9B9B9;
    cursor: default;
  }
`
export const useRegisterForm = (verification) => {
  const [submitted, setSubmitted] = useState(false)
  const [status, setStatus] = useState({ message: '', error: false })
  const [errors, setErrors] = useState(BLANK_FORM)
  const { loading, success, errors: vErrors } = useSelector(store => store.drivers.driverStatus)
  const firstRender = useRef(true)
  const dispatch = useDispatch()

  const { form, updateForm } = useForm(BLANK_FORM)
  const transformChange = ({ currentTarget: { name, value } }) => updateForm({ [name]: value.toUpperCase() })

  const checkFieldValid = (field, regex) => {
    if (form[field] === '') {
      setErrors(prev => ({ ...prev, [field]: 'Please complete all fields' }))
    } else if (!form[field].match(regex)) {
      setErrors(prev => ({ ...prev, [field]: INVALID_MSG }))
    } else {
      setErrors(prev => ({ ...prev, [field]: '' }))
    }
  }

  useEffect(() => {
    if (submitted) {
      checkFieldValid('licence', VALID_LICENCE_NO)
    }
  }, [submitted])

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    const errorMessages = Object.values(errors)
    if (errorMessages.every(v => v === '')) {
      Api({ request: verification ? DRIVERS_GUEST : DRIVERS_CREATE, data: { ...form, ...verification } })
    } else {
      const message = errorMessages.find(e => e !== '')
      setStatus({ message, error: true })
    }
    setSubmitted(false)
  }, [errors])

  useEffect(() => {
    const regex = /is already taken/
    const message = 'Driver details already exist'
    if (((vErrors || []).some(e => e.match(regex)))) {
      setStatus({ message, error: true })
    } else if (vErrors) {
      const message = vErrors.join(', ')
      setStatus({ message, error: true })
    }
    dispatch({ type: DRIVERS_CREATE_RESET_STORE })
    setSubmitted(false)
  }, [vErrors])

  useEffect(() => {
    if (success) {
      const message = 'Thank you, new driver details have been added'
      setStatus({ message, error: false })
      updateForm(BLANK_FORM)
    }
  }, [success])

  useEffect(() => {
    return () => dispatch({ type: DRIVERS_CREATE_RESET_STORE })
  }, [])

  return { form, transformChange, setSubmitted, status, errors, loading }
}

export const RegisterForm = ({ form, transformChange, errors, status, loading }) => (
  <>
    <Status isError={status.error}>
      {status.message}
    </Status>
    <Conditional if={loading}>
      <LoadingAbsolute />
    </Conditional>
    <FormFields>
      <Field label="Driving licence number" className="full" error={!!errors.licence}>
        <input
          type="text"
          onChange={transformChange}
          name="licence"
          value={form.licence}
        />
      </Field>
    </FormFields>
  </>
)
RegisterForm.propTypes = {
  form: propTypes.object,
  transformChange: propTypes.func,
  errors: propTypes.object,
  status: propTypes.object,
  loading: propTypes.bool
}

const Container = styled.div`
  position: relative;
  background-color: #F5F6F9;
  border: 2px solid ${props => props.theme.primaryColor};
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > div.register-driver-text {
    font-size: 0.875em;
    margin: 2rem 0 auto 0;
    line-height: 150%;
  }
`
const Header = styled.div`
  display: flex;
  align-items: center;

  > img { margin-right: 1em; }
  > h1 {
    color: black;
    font-weight: normal;
    font-size: 1.2em;
    margin: 0;
  }
`
const Register = () => {
  const { form, transformChange, setSubmitted, status, errors, loading } = useRegisterForm()
  const [agreed, setAgreed] = useState(false)
  const toggle = () => setAgreed(!agreed)

  return (
    <Container className="register-driver-container item">
      <Header>
        <img src={licence} alt="Register driver icon" />
        <h1>Register a driver</h1>
      </Header>
      <div className="register-driver-text">
        To register a driver within your fleet, please add the following personal information and click ‘Register’.
        If the information you have provided matches our records, the driver will be automatically added.
      </div>
      <RegisterForm form={form} transformChange={transformChange} errors={errors} status={status} loading={loading} />
      <Declaration agreed={agreed} toggle={toggle} />
      <RegisterButton disabled={loading || !agreed} onClick={() => setSubmitted(true)}>Register</RegisterButton>
    </Container>
  )
}

export default Register
