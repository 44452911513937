import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { capitalize } from 'lodash'

import { Button } from '../../vehicles/RegisterVehicle'
import { TabBodyContainer } from '../Show'
import { InfoTable, formatDate, prettyNumber } from '../../common'
import { useLinkVehicles, LinkVehicleWrapper } from '../LinkVehicles'

export const LinkedVehicles = ({ id }) => {
  const { value: vehicles } = useLinkVehicles(id)

  const data = vehicles.map(v => ({
    ...v.details,
    link: [v.details.registration, `/vehicles/${v.details.id}`]
  }))

  return (
    <>
      <Container>
        <div>Linked Vehicles</div>
        <div>
          Below are all the vehicles in your fleet that are registered with this driver.
          If there are any issues, please contact your Transport Manager.
        </div>
        <div className="controls">
          <LinkVehicleWrapper id={id}>
            <EditButton>Edit</EditButton>
          </LinkVehicleWrapper>
          <Link to="/vehicles/new">
            <NewButton>Add New Vehicle</NewButton>
          </Link>
        </div>
      </Container>
      <TabBodyContainer>
        <InfoTable
          attributes={ATTRIBUTES}
          data={data}
        />
      </TabBodyContainer>
    </>
  )
}

const EditButton = styled(Button)`
  width: 9rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
`
const NewButton = styled(Button)`
  width: 9rem;
  margin-bottom: 1rem;
  background: white;
  color: black;
`
const Container = styled.div`
  padding: 1.1rem;

  > div:first-child { font-weight: 700 }

  > * + * { margin-top: 1rem }

  .controls {
    display: flex;
  }
`

const linkTo = d => (
  <Link to={d[1]}>
    {d[0]}
  </Link>
)

const ATTRIBUTES = {
  link: { name: 'Registration', format: linkTo },
  make: { format: capitalize },
  model: { format: capitalize },
  colour: { format: capitalize },
  mileage: { format: prettyNumber },
  fuelType: { format: capitalize },
  transmission: { format: capitalize },
  createdAt: { name: 'Added', format: formatDate }
}
