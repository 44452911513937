import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { startCase } from 'lodash'
import propTypes from 'prop-types'

import { formatDate } from '../common'
import BasicDataTable from '../common/DataTable/BasicTable'
import { DashboardTableContainer, useColumnSave } from '../dashboard/Table'
import { LinkVehicleWrapper } from './LinkVehicles'

const Table = () => {
  const { matchedDrivers } = useSelector(r => r.dashboard)
  const data = Object.values(matchedDrivers).map(d => (
    {
      ...d,
      validFrom: dateString(d.validFrom),
      validTo: dateString(d.validTo)
    }
  ))

  const columns = useColumns()

  return (
    <DashboardTableContainer>
      <BasicDataTable
        data={data}
        {...columns}
        newLink="/drivers/new"
        newText="Add licence"
      />
    </DashboardTableContainer>
  )
}

// Using this means the BasicTable component can correctly
// sort the dates by converting them to YYYY-MM-DD format.
const dateString = d => {
  if (!d) return ''

  return new Date(d).toISOString()
}

const useColumns = () => {
  const all = {}
  buildColumnOptions().forEach(o => { all[o.label || startCase(o.accessor)] = o })
  const columnOptions = Object.keys(all)
  const defaultColumns = columnOptions
  const [currentColumns, setColumns] = useState(defaultColumns)
  const columns = currentColumns.map(c => all[c])
  const { setSavedColumns } = useColumnSave("drivers", columnOptions, setColumns)

  return { columns, columnOptions, currentColumns, defaultColumns, setColumns: setSavedColumns }
}

const yesNo = bool => (
  bool ? 'Yes' : 'No'
)

const buildColumnOptions = () => ([
  { accessor: 'licenceNumber', linkString: '/drivers', flex: '2' },
  { accessor: 'name', flex: '2' },
  { accessor: 'issueNumber' },
  { accessor: 'validFrom', format: formatDate },
  { accessor: 'validTo', format: formatDate },
  { accessor: 'id', label: 'Linked Vehicle', Component: VehicleCell },
  { accessor: 'endorsements', label: 'Offences', format: p => p.length },
  { accessor: 'penaltyPoints' },
  { accessor: 'disqualified', label: 'Disqualified', format: yesNo },
  { accessor: 'addVehicle', sortable: false, Component: AddVehicleCell },
])

const AddVehicleCell = ({ row: { id } }) => (
  <LinkVehicleWrapper id={id} className="link-vehicle-add-cell">
    <span>+ Add</span>
  </LinkVehicleWrapper>
)
AddVehicleCell.propTypes = {
  id: propTypes.string
}

const vehicleText = (vehicles, regs, driverId) => {
  if (!regs || regs.length < 1) return '-'
  if (regs.length === 1) {
    const id = vehicles.find(v => v.registration === regs[0]).id
    return (
      <Link to={`/vehicles/${id}`}>
        {regs[0]}
      </Link>
    )
  }
  return (
    <Link to={{ pathname: `/drivers/${driverId}`, state: { tabIndex: 3 } }}>
      {regs.length} Vehicles
    </Link>
  )
}
vehicleText.propTypes = {
  vehicles: propTypes.array,
  regs: propTypes.array,
}

const VehicleCell = ({ value }) => {
  const { vehicles } = useSelector(r => r.dashboard)
  const linkedRegs = vehicles.filter(v => (
    v.drivers.includes(value)
  )).map(v => v.registration)

  return (
    <span className="flex-1 cell">
      {vehicleText(vehicles, linkedRegs, value)}
    </span>
  )
}
VehicleCell.propTypes = {
  value: propTypes.string
}

export default Table
