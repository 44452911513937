import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components/macro'

import Signup from './Signup'
import Api from '../api/call'
import { CURRENT_USER, LOGOUT } from '../root/action-types'

function ConfirmAccount () {
  const { id, loading, active } = useSelector(store => store.users.current)
  const history = useHistory()
  const dispatch = useDispatch()

  const smallprint = 'Please confirm your details before using Vehelix.'

  useEffect(() => {
    if (!id) Api({ request: CURRENT_USER })
  }, [])

  useEffect(() => {
    if (
      loading === false &&
      active === true
    ) history.push('/welcome')
  }, [loading, active])

  return (
    <ConfirmAccountContainer>
      <div className="logout" onClick={() => dispatch({ type: LOGOUT })}>Logout</div>
      <Signup id={id} initialStage='PAYMENT_DETAILS' smallprint={smallprint} />
    </ConfirmAccountContainer>
  )
}

const ConfirmAccountContainer = styled.div`
  text-align: center;
  position: relative;

  > .logout {
    position: absolute;
    top: 1rem;
    right: 5%;
    background: #F5F6F9;
    border: none;
    cursor: pointer;
    color: ${p => p.theme.primaryColor};

    &:hover {
      text-decoration: underline;
    }
  }
`
export default ConfirmAccount
