import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import propTypes from 'prop-types'

import ModalWindow from '../layout/ModalWindow'
import { Conditional, VerticalSelect, Loading } from '../common/'
import Api from '../api/call'
import { DASHBOARD_ASSIGN_DRIVERS, VEHICLES_ASSIGN_DRIVERS } from '../root/action-types'

const driverText = (drivers, value) => {
  if (!value || value.length < 1) { return "Add Driver" }
  if (value.length == 1) {
    return get(drivers[value[0]], 'licenceNumber', '1 Driver')
  }
  return `${value.length} Drivers`
}

const DriverCell = ({ value, row: { ovid } }) => {
  const { drivers } = useSelector(r => r.dashboard)
  const [active, setActive] = useState(false)
  const toggle = () => setActive(!active)
  const update = (drivers) => {
    Api({ request: DASHBOARD_ASSIGN_DRIVERS, data: { ovid, drivers } })
  }

  return (
    <React.Fragment>
      <span
        className={`flex-1 cell driver ${(!value || value.length == 0) && 'blank'}`}
        onClick={toggle}
      >
        {driverText(drivers, value)}
      </span>
      <Conditional if={active}>
        <DriverModal
          value={value}
          toggle={toggle}
          drivers={drivers}
          update={update}
        />
      </Conditional>
    </React.Fragment>
  )
}

export const DriverSelectWrapper = ({ children, className, value, ovid }) => {
  const [active, setActive] = useState(false)
  const toggle = () => setActive(!active)

  return (
    <React.Fragment>
      <div className={className || ''} onClick={toggle}>{children}</div>
      <Conditional if={active}>
        <Fetcher ovid={ovid} toggle={toggle} value={value} />
      </Conditional>
    </React.Fragment>
  )
}
DriverSelectWrapper.propTypes = {
  children: propTypes.node,
  value: propTypes.array, // array of string ids
  ovid: propTypes.string,
  className: propTypes.string
}

const Fetcher = ({ ovid, ...props }) => {
  const { drivers, loading } = useSelector(r => r.drivers.index)
  const update = (drivers) => {
    Api({ request: VEHICLES_ASSIGN_DRIVERS, data: { ovid, drivers } })
  }

  if (loading) { return <Loading /> }
  return <DriverModal {...props} update={update} drivers={drivers} />
}

const option = ({ id, licenceNumber, name }) => ({
  value: id,
  label: licenceNumber,
  description: name
})

const DriverModal = ({ value, toggle, drivers, update }) => (
  <ModalWindow isOpen closeModal={toggle} className="driver-modal">
    <ModalContainer>
      <h2>Link Drivers</h2>
      <VerticalSelect
        value={value.map(id => option(drivers[id] || {}))}
        options={Object.values(drivers).map(option)}
        onChange={update}
      />
    </ModalContainer>
  </ModalWindow>
)

export const ModalContainer = styled.div`
  width: 40rem;
  max-height: 75vh;
  overflow: hidden;

  h2 {
    margin-top: 0;
    margin-left: 2rem;
    font-size: 22px;
    color: #633C76;
  }
`

export default DriverCell
