import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'

import { TabBodyContainer } from '../Show'
import { InfoTable, formatDate } from '../../common'
import { DESCRIPTIONS } from '../categoryDescriptions'
import * as categories from '../../assets/categories'

export const Categories = () => {
  const { vehicleCategories } = useSelector(r => r.drivers.show.data)
  const data = vehicleCategories.map(vc => ({ ...vc, vehicleType: DESCRIPTIONS[vc.category] }))

  return (
    <TabBodyContainer>
      <InfoTable
        attributes={ATTRIBUTES}
        data={data}
      />
    </TabBodyContainer>
  )
}

const category = c => (
  <div>
    <span>{c}</span>
    <Img src={categories[`category_${c.toLowerCase()}`]} alt="" />
  </div>
)
const Img = styled.img`
  height: 1.5rem;
  vertical-align: middle;
  padding-left: 1rem;
`

const ATTRIBUTES = {
  category: { format: category },
  vehicleType: {},
  startDate: { format: formatDate },
  endDate: { format: formatDate },
  type: {},
  restrictions: {}
}
