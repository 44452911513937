import React from 'react'

export const TermsAndConditions = () => {
  return (
    <div>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras molestie leo in eros sagittis ultricies.
      Aliquam erat volutpat. Nunc bibendum arcu orci, vitae volutpat nibh porta eget. Curabitur aliquet tristique
      sapien, nec venenatis orci dignissim sit amet. Quisque ullamcorper erat ligula, a maximus elit cursus eget.
      Sed quis laoreet nunc, sit amet mattis sapien. Nulla eros felis, mattis eget ultrices vel, iaculis a tellus.
    </div>
  )
}

export default TermsAndConditions
