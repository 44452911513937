import { combineReducers } from 'redux'
import { pick } from 'lodash'

import {
  VEHICLES_LOOKUP,
  VEHICLES_LOOKUP_ASYNC,
  VEHICLES_CONFIRM,
  VEHICLES_INVALID,
  VEHICLES_SHOW,
  VEHICLES_TABLE_REMOVE,
  VEHICLES_ASSIGN_DRIVERS,
  VEHICLES_IMAGE,
  VEHICLES_UNASSOCIATE,
  VEHICLES_REFRESH,
} from '../root/action-types'

const TEST_VEHICLES = {}
/* {
  AB1: {
    colour: "GREY",
    fuelType: "PETROL",
    make: "ROLLS ROYCE",
    mileage: "24839",
    model: "WRAITH",
    mot: true,
    registration: "AB1",
    taxed: true,
    transmission: null,
  },
  DH02SX4: {
    colour: "Cyan",
    fuelType: "diesel",
    make: "vauxhall",
    mileage: 166431,
    model: "corsa",
    mot: false,
    registration: "DH02SX4",
    taxed: false,
    transmission: 'manual',
    confirmed: true
  },
  AC2: {
    colour: "GREY",
    fuelType: "PETROL",
    make: "PORSCHE",
    mileage: null,
    model: "CAYENNE",
    mot: false,
    registration: "AC2",
    taxed: true,
    transmission: null,
  },
  BADONE: {
    registration: 'BADONE',
    error: '404: Record For Vehicle Not Found'
  },
  SLOWONE: {
    registration: 'SLOWONE',
    pending: true
  }
}
*/

const INITIAL_TABLE = {
  data: TEST_VEHICLES,
  loading: false,
  invalid: []
}

const format = (reg) => (
  String(reg).toUpperCase().replace(/\s/g, '')
)

const formatObject = (obj) => {
  const valid = {}
  Object.keys(obj).forEach(k => { valid[format(k)] = obj[k] })
  return valid
}

// Need to be careful about reg formats to prevent duplicate row
// confusion, especially with snake_case/camelCase API magic.
// EG: 'KY57SXO' should match 'ky57 sxo'
const table = (state = INITIAL_TABLE, action) => {
  switch (action.type) {
    case VEHICLES_INVALID: {
      return { ...state, invalid: action.invalid }
    }
    case VEHICLES_TABLE_REMOVE: {
      const data = { ...state.data }
      delete data[format(action.registration)]
      return { ...state, data }
    }
    case `${VEHICLES_CONFIRM}_START`:
    case `${VEHICLES_LOOKUP}_START`: {
      return { ...state, loading: true }
    }
    case `${VEHICLES_CONFIRM}_FAILURE`:
    case `${VEHICLES_LOOKUP}_FAILURE`: {
      return { ...state, loading: false }
    }
    case `${VEHICLES_CONFIRM}_SUCCESS`:
    case `${VEHICLES_LOOKUP}_SUCCESS`: {
      const data = {
        ...state.data,
        ...formatObject(action.response.data)
      }
      return { ...state, loading: false, data }
    }
    case VEHICLES_LOOKUP_ASYNC: {
      const data = { ...state.data, [format(action.registration)]: action.vehicle }
      return { ...state, data }
    }
    default: {
      return state
    }
  }
}

const INITIAL_SHOW = { errors: [], data: {} }

const show = (state = INITIAL_SHOW, action) => {
  switch (action.type) {
    case `${VEHICLES_UNASSOCIATE}_START`:
    case `${VEHICLES_SHOW}_START`: {
      return { ...state, loading: true }
    }
    case `${VEHICLES_UNASSOCIATE}_FAILURE`:
    case `${VEHICLES_SHOW}_FAILURE`: {
      return { ...state, loading: false, errors: action.response.data }
    }
    case `${VEHICLES_SHOW}_SUCCESS`: {
      return { ...state, loading: false, data: action.response.data }
    }
    case `${VEHICLES_ASSIGN_DRIVERS}_SUCCESS`: {
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...pick(action.response.data, ['drivers', 'auditLog']) }
      }
    }
    case `${VEHICLES_UNASSOCIATE}_SUCCESS`: {
      return {
        ...state,
        loading: false,
        unassociated: true
      }
    }
    case `${VEHICLES_REFRESH}_SUCCESS`: {
      return { ...state, refreshing: true, refreshed: false }
    }
    case VEHICLES_LOOKUP_ASYNC: {
      if (action.registration == state.data.registration) {
        return {
          ...state,
          refreshing: false,
          refreshed: true,
          apisBreakdown: action.apisBreakdown
        }
      }
      return state
    }
    case `${VEHICLES_SHOW}_RESET_STORE`: {
      return INITIAL_SHOW
    }
    default: {
      return state
    }
  }
}

const INITIAL_IMAGE = { data: {}, error: null }

const images = (state = INITIAL_IMAGE, action) => {
  switch (action.type) {
    case `${VEHICLES_IMAGE}_START`: {
      return { ...state, loading: true, error: null }
    }
    case `${VEHICLES_IMAGE}_FAILURE`: {
      return { ...state, loading: false, error: action.response.data.error }
    }
    case `${VEHICLES_IMAGE}_SUCCESS`: {
      const data = { ...state.data }
      Object.keys(action.response.data).forEach(k => data[k.toUpperCase()] = action.response.data[k])
      return { ...state, loading: false, error: null, data }
    }
    default: {
      return state
    }
  }
}

export default combineReducers({
  table,
  show,
  images
})
