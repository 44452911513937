import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { get, capitalize } from 'lodash'

import { formatUKDatetime } from '../common'
import { dashboard, fleet, drivers, settings } from '../assets'
import TrialStatus from './TrialStatus'

const Dates = ({ lastUpdate }) => {
  const date = new Date()
  const dateOpts = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }

  return (
    <DatesContainer>
      <div>{date.toLocaleString('en-GB', dateOpts)}</div>
      <div>Latest update: {lastUpdate ? formatUKDatetime(lastUpdate) : '--'}</div>
    </DatesContainer>
  )
}
const DatesContainer = styled.div`
  font-size: 0.75rem;

  @media (max-width: 1900px) {
    font-size: 0.64rem;
  }

  > div:first-child {
    color: white;
    margin-bottom: 0.5em;
  }

  > div:last-child {
    color: #686868;
    font-style: italic;
  }
`

const NavItem = ({ path, text, icon, location }) => {
  const isActive = `/${path}` === get(location, 'pathname')

  return (
    <Link to={`/${path}`} className={isActive ? "active" : ""}>
      <img src={icon} alt={`${text} icon`} />
      {text || capitalize(path)}
      {isActive && <Triangle />}
    </Link>
  )
}

const Sidebar = ({ location }) => {
  const { lastUpdate } = useSelector(r => r.users.current)

  return (
    <Bar>
      <Dates lastUpdate={lastUpdate} />
      <NavItem path="dashboard" icon={dashboard} location={location} />
      <NavItem path="fleet" icon={fleet} location={location} />
      <NavItem path="drivers" icon={drivers} location={location} />
      <NavItem path="settings" icon={settings} location={location} />
      <TrialStatus />
      <div>© Copyright 2020 Vehelix</div>
    </Bar>
  )
}

const Bar = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: #282828;
  border-top: 2px solid #353535;
  width: 300px;
  flex: 0 0 300px;

  > div, a {
    padding: 1rem 2.5rem;
  }

  > div:last-child {
    font-size: 0.75rem;
    color: #686868;
    margin-top: auto;
    font-style: italic;
  }

  > a {
    position: relative;
    color: white;
    font-weight: bold;

    > img {
      height: 18px;
      width: 18px;
      vertical-align: -3.2px;
      padding-right: 1rem;
    }

    &.active {
      background: ${props => props.theme.primaryColor};
    }

    &:hover {
      text-decoration: unset;
    }

    &:not(.active):hover {
      background: #505050;
    }
  }

  @media (max-width: 1900px) {
    font-size: 0.8rem;
    width: 240px;
    flex: 0 0 240px;

    > div:last-child {
      font-size: 0.64rem;
    }
    > a {
      > img {
        height: 14.8px;
        width: 14.8px;
      }
    }
  }
`

const Triangle = styled.div`
  position: absolute;
  right: 0;
  top: calc(50% - 9px);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 13.9px 8px 0;
  border-color: transparent #F5F6F9 transparent transparent;
`

export default Sidebar
