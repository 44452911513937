import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import styled from 'styled-components/macro'

const PaymentDetailsTabs = ({ cardForm, directDebitForm }) => (
  <StyledTabs>
    <StyledTabList>
      <StyledTab>Debit or credit card</StyledTab>
      <StyledTab>Direct debit</StyledTab>
    </StyledTabList>
    <TabPanel>
      {cardForm}
    </TabPanel>
    <TabPanel>
      {directDebitForm}
    </TabPanel>
  </StyledTabs>
)

const StyledTabs = styled(Tabs)`
  flex: 1;
`

const StyledTabList = styled(TabList)`
  width: 100%;
  margin: 0 auto;
  padding: 0 0 1.4rem 0;
  text-align: left;
`

const StyledTab = styled(Tab)`
  display: inline-block;
  list-style: none;
  max-width: max-content;
  padding-bottom: 0.75rem;
  margin-right: 1rem;
  cursor: pointer;
  border-bottom: 4px solid transparent;
  background: inherit;
  transition: border-color .15s ease-in-out;

  &:focus { outline: none; }

  &.react-tabs__tab--selected {
    border-bottom: 4px solid ${props => props.theme.primaryColor};
    background: none;
  }
  &:last-of-type { margin-left: 1rem }
`

export default PaymentDetailsTabs
