import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { startCase } from 'lodash'

import { StyledField } from './RegisterVehicle'
import { BigCarGoBrummm } from '../assets/'
import { DriverSelectWrapper } from '../dashboard/DriverSelect'
import { DRIVERS, VEHICLES_IMAGE } from '../root/action-types'
import { Loaded, capitalCase, modelCase, formatDatetime } from '../common/'
import Api from '../api/call'

const Image = ({ registration }) => {
  const { data, loading, error } = useSelector(r => r.vehicles.images)
  useEffect(() => {
    if (!data[registration]) {
      Api({ request: VEHICLES_IMAGE, params: { registration } })
    }
  }, [registration])

  const src = `data:image/jpg;base64, ${data[registration]}`
  return (
    <Loaded loading={loading}>
      <img src={error ? BigCarGoBrummm : src} alt="car" title={error} />
    </Loaded>
  )
}

const Overview = (props) => (
  <Styling>
    <Image registration={props.registration} />
    <Registration {...props} />
    <div className="details">
      <Driver {...props} />
      {Object.keys(FIELDS).map(f => <Field name={f} key={f} value={props[f]} />)}
    </div>
  </Styling>
)

const Field = ({ name, value }) => (
  <div>
    <label>{FIELDS[name].label || startCase(name)}</label>
    <span className="value">
      {format(name, value)}
    </span>
  </div>
)

const format = (name, value) => {
  if (!value) { return <span className="not-applicable">N/A</span> }
  if (FIELDS[name].format) { return FIELDS[name].format(value) }
  return value
}

const FIELDS = {
  make: { format: capitalCase },
  model: { format: modelCase },
  colour: { format: capitalCase },
  fuelType: { format: capitalCase },
  transmission: { format: capitalCase },
  vin: { label: 'VIN' },
  engineCapacity: { label: 'CC' },
  mileage: {},
  co2Emissions: { label: 'CO2 emissions' },
  addedAt: { label: 'Added on Vehelix', format: formatDatetime }
}

const Registration = ({ registration, updatedAt, refreshed }) => (
  <StyledField className="top">
    <input className="registration" readOnly value={registration} />
    <span className={`updated-at ${refreshed ? 'refreshed' : ''}`}>
      Last update: {formatDatetime(updatedAt)}
    </span>
  </StyledField>
)

const Driver = ({ drivers: driverIds, ovid }) => {
  useEffect(() => Api({ request: DRIVERS }), [ovid])
  const { drivers, loading } = useSelector(r => r.drivers.index)

  return (
    <div className="driver">
      <div className="left">
        <label>Driver</label>
      </div>
      <Loaded loading={loading}>
        <DriverSelectWrapper className="right" value={driverIds} ovid={ovid}>
          <DriverDetails drivers={(driverIds || []).map(id => drivers[id])} />
          <span className="edit">Edit</span>
        </DriverSelectWrapper>
      </Loaded>
    </div>
  )
}

const DriverDetails = ({ drivers }) => {
  if (drivers.length == 0) {
    return <span className="name double">No drivers assigned</span>
  }
  if (drivers[0] == undefined) {
    return 'Loading'
  }
  if (drivers.length == 1) {
    return (
      <React.Fragment>
        <span className="name">{drivers[0].name}</span>
        <span className="number">{drivers[0].licenceNumber}</span>
      </React.Fragment>
    )
  }
  if (drivers.length > 1) {
    return (
      <span className="name double">{`${drivers.length} Drivers`}</span>
    )
  }
}

const Styling = styled.div`
  background: white;
  margin-right: 1rem;
  width: 24rem;

  img {
    padding: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    max-width: 20rem;
    max-height: 14rem;
    width: auto;
    height: auto;
    margin: 0 auto;
    display: block;
  }
  .top {
    width: 20rem;
    padding-bottom: 1rem;
  }
  .registration {
    font-size: 51px;
  }
  .updated-at {
    font-style: italic;
    color: #B9B9B9;
    font-size: .8rem;
  }
  .refreshed {
    animation: glow 2s ease-in-out 4 alternate;
  }
  @keyframes glow {
    from {
      text-shadow: none;
      color: #B9B9B9;
    }
    to {
      text-shadow: 0 0 20px yellow, 0 0 30px yellow, 0 0 40px yellow;
      color: black;
    }
  }
  .details {
    > div {
      border-top: 0.1rem solid #00000015;
      padding: 1rem;
      padding-left: 1.5rem;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      .value {
        width: 50%;
      }
    }
  }
  .driver {
    display: flex;
    flex-direction: row;
    border-left: 0.5rem solid ${p => p.theme.primaryColor};
    justify-content: space-between;
    padding-left: 1rem !important;
    .left {
      width: 50%;
    }
    .right {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 50%;
      cursor: pointer;
      gap: .5rem;
    }
    .name {
      color: ${p => p.theme.primaryColor};
      text-transform: uppercase;
    }
    .double {
      margin-bottom: 1.13rem;
    }
    .edit {
      color: #B9B9B9;
    }
  }
  label {
    color: #B9B9B9;
  }
  .not-applicable {
    opacity: 0.5;
  }
`

export default Overview
