import React from 'react'
import { invoke, startCase } from 'lodash'
import styled from 'styled-components/macro'

// Non-interactive data table for displaying information:
// `attributes` should be an object mapping { column name: { options } }.
// The options object can contain the following keys:
//   `format`: a function that can be used to transform the data for the column,
//   `name`: a string that can be used as the column name.
// `data` should be an array of objects used to construct rows for the table.
// See src/vehicles/tabs/Audit for example usage.
export const InfoTable = ({ attributes, data }) => {
  const columns = Object.keys(attributes).map(c => attributes[c].name ?? startCase(c))

  const formatValue = (column, val) => {
    if (attributes[column].Component) {
      const Component = attributes[column].Component
      return <Component value={val} />
    }
    return invoke(attributes[column], 'format', val) || val
  }

  return (
    <StyledTable>
      <thead>
        <tr>
          {columns.map(c => <th key={c}>{c}</th>)}
        </tr>
      </thead>
      <tbody>
        {data.map((entry, i) => {
          return (
            <tr key={i}>
              {Object.keys(attributes).map((k, j) =>
                <td
                  key={`${i}_${j}`}
                  className={attributes[k].className || ''}
                >{formatValue(k, entry[k])}</td>
              )}
            </tr>
          )
        })}
      </tbody>
    </StyledTable>
  )
}

export const StyledTable = styled.table`
  width: 100%;
  text-align: center;
  border-collapse: collapse;

  th {
    position: sticky;
    top: 0;
    background: white;
  }

  th, td {
    padding-top: 1em;
    padding-bottom: 1em;
    padding: 1rem 1.5rem;
    font-size: .9rem;
    text-align: left;
    font-weight: 400;

    @media screen and (max-width: 1919px) {
      padding: 1rem 1.1rem;
    }
  }

  td {
    border-top: 1px solid #00000015;
    max-width: 30ch;
    vertical-align: top;
  }

  th {
    color: #B9B9B9;
  }

  .yes-no {
    svg {
      padding-right: 0.5em;
    }
    &.yes {
      color: #53B761;
    }
    &.no {
      color: crimson;
    }
  }

  td.control svg {
    cursor: pointer;
    transition: opacity .15s ease-in-out;
    opacity: .5;
    &:hover {
      opacity: 1;
    }
  }

  .pending {
    td {
      background: beige;
      opacity: 0.5;
    }
  }
  .failure {
    td {
      background: lightcoral;
      color: white;
    }
    svg {
      padding-right: 0.5em;
    }
  }
  .confirmed {
    td {
      background: #f56c5820;
    }
  }

`
