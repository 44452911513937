import React from 'react'
import { withRouter } from 'react-router-dom'
import propTypes from 'prop-types'
import { ThemeProvider, createGlobalStyle } from 'styled-components/macro'

import Navbar from './Navbar'
import Sidebar from './Sidebar'
import { Conditional } from '../common/'

import CreditModal from './CreditModal'
import './layout.scss' // FIXME

const theme = {
  grey: "#474747",
  gray: "#474747",
  lightGrey: "#C6C8CA",
  error: "#FF3A64",
  danger: "crimson",
  mildperil: "thistle",
  success: "#00C0B4",
  black: "#212121",
  black10: "lighten(#212121, 80%)",
  textBlack: "#212121",
  white: "#FFFFFF",
  ghostWhite: "#F8F9FD",
  primaryColor: "#F66C57",
  secondaryColor: "#E7E7E7",
  menuHighlight: "#E5F0FE",
  fadedWhite: "#F5F5F5",
  lightBorder: "#EFF4F5",
  inputBorder: "#D6D6D6",
  font: "Ubuntu, sans-serif"
}
const GlobalStyle = createGlobalStyle`
  a {
    color: ${props => props.theme.primaryColor};
    text-decoration: none;
    &:hover { text-decoration: underline; }
  }
`

const hideBarsOnThesePaths = [
  '/login',
  '/signup',
  '/password_change',
  '/welcome',
  '/drivers/register',
  '/confirm_account',
  '/user_confirm',
]

const hideBars = (urlPath = window.location.pathname) => (
  hideBarsOnThesePaths.find(p => String(urlPath).startsWith(p))
)

const Layout = ({ location, children }) => {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Conditional if={!hideBars()}>
          <Navbar location={location} />
        </Conditional>
        <CreditModal />
        <div className="layout-under-topbar">
          <Conditional if={!hideBars()}>
            <Sidebar location={location}/>
          </Conditional>
          <div className={hideBars() ? "layout-barless-page" : "layout-content-pane"}>
            {children}
          </div>
        </div>
      </ThemeProvider>
    </React.Fragment>
  )
}
Layout.propTypes = {
  location: propTypes.object,
  children: propTypes.node
}

export default withRouter(Layout)
