import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import propTypes from 'prop-types'

import ModalWindow, { Form, Controls } from '../layout/ModalWindow'
import { Field, Status, Conditional } from '../common/'
import Api from '../api/call'
import { DRIVERS_UPDATE } from '../root/action-types'

export const EmailSelectWrapper = ({ className, children, value, id }) => {
  const [active, setActive] = useState(false)
  const [email, setEmail] = useState(value || '')
  const [updating, setUpdating] = useState(false)
  const close = () => {
    setActive(false)
    setUpdating(false)
  }
  const { loading, updated, errors } = useSelector(r => r.drivers.show)
  const submit = () => {
    Api({ request: DRIVERS_UPDATE, data: { email }, id })
    setUpdating(true)
  }
  if (updating && updated && !loading) { close() }

  return (
    <React.Fragment>
      <div className={className || ''} onClick={() => setActive(true)}>{children}</div>
      <ModalWindow isOpen={active} closeModal={close} className="driver-email-modal">
        <Form>
          <Conditional if={updating}>
            <Status isError={errors && errors.length > 0} messages={errors} />
          </Conditional>
          <Field label="Email">
            <input value={email} onChange={e => setEmail(e.target.value)} disabled={loading} />
          </Field>
        </Form>
        <Controls>
          <button className="primary" onClick={submit} disabled={loading}>Update</button>
          <button className="back" onClick={close} disabled={loading}>Cancel</button>
        </Controls>
      </ModalWindow>
    </React.Fragment>
  )
}
EmailSelectWrapper.propTypes = {
  children: propTypes.node,
  value: propTypes.string,
  driverId: propTypes.string,
  className: propTypes.string
}
