import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import propTypes from 'prop-types'
import { uniq } from 'lodash'

// values and options should be in format:
// [{ value: string, label: string, description: string }, ...]
export const VerticalSelect = ({ value, options, onChange }) => {
  const remove = (victim) => {
    onChange(value.map(o => o.value).filter(o => o != victim))
  }
  const add = (newVal) => {
    onChange(uniq([...value.map(v => v.value), newVal]))
  }
  const [query, setQuery] = useState('')
  const search = ({ target: { value } }) => {
    setQuery(value)
  }
  const available = options.filter(o =>
    !value.find(v => v.value == o.value) && match(query, o)
  )

  return (
    <Styling>
      <div className="values">
        {value.map(v => <Value {...v} key={v.value} remove={remove} />)}
      </div>
      <div className="search">
        <input value={query} onChange={search} placeholder="Search" />
        <FontAwesomeIcon icon="search" />
      </div>
      <div className="available">
        {available.map(o => <Option {...o} key={o.value} onClick={add} />)}
      </div>
    </Styling>
  )
}
VerticalSelect.propTypes = {
  value: propTypes.array,
  options: propTypes.array,
  onChange: propTypes.func
}

const match = (query, option) => {
  if (!query) { return true }
  const reg = new RegExp(query, 'i')
  return !!String(option.label).match(reg) || !!String(option.description).match(reg)
}

const Value = ({ value, label, remove }) => (
  <span className="value">
    {label}
    <FontAwesomeIcon icon="times" onClick={() => remove(value)} />
  </span>
)
Value.propTypes = {
  value: propTypes.string,
  label: propTypes.string,
  remove: propTypes.func
}

const Option = ({ value, label, description, onClick }) => (
  <div className="option">
    <span className="box" onClick={() => onClick(value)}>{label}</span>
    <span className="description">{description}</span>
  </div>
)
Option.propTypes = {
  value: propTypes.string,
  label: propTypes.string,
  description: propTypes.string,
  onClick: propTypes.func
}

const Styling = styled.div`
  width: 100%;
  max-height: 70vh;
  display: flex;
  flex-direction: column;

  .values {
    border-top: 1px solid #B9B9B9;
    border-bottom: 1px solid #B9B9B9;
    min-height: 3rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
  }
  .value {
    background: ${p => p.theme.primaryColor};
    padding: 0.75rem;
    padding-top: 0.1rem;
    padding-right: 0.1rem;
    border-radius: 0.3rem;
    margin: 1rem;
    color: white;

    svg {
      position: relative;
      border: 1px solid ${p => p.theme.primaryColor};
      border-radius: 1rem;
      background: white;
      bottom: 1rem;
      left: 1rem;
      width: 1rem;
      color: black;
      transform: scale(0.75);
      padding: 0.25rem;
      cursor: pointer;
    }
  }
  .search {
    border-bottom: 1px solid #B9B9B9;
    height: 3rem;
    padding-bottom: 0.2rem;

    input {
      width: calc(100% - 3.2rem);
      height: 100%;
      border: none;
      padding-left: 3rem;
      border-radius: 0;
      font-size: 16px;
      outline: none;
    }
    svg {
      position: relative;
      bottom: 2rem;
      left: 1rem;
    }
  }
  .available {
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    overflow: auto;
    height: 60vh;
  }
  .option {
    margin: 1rem;

    .box {
      padding: 0.75rem;
      background: #eee;
      border-radius: 0.25rem;
      cursor: pointer;
    }
    .description {
      padding-left: 1rem;
      font-style: italic;
      opacity: 0.5;
    }
  }
`
