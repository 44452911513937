import React from 'react'
import { useSelector } from 'react-redux'
import { get, omit, join, partition, capitalize } from 'lodash'
import styled from 'styled-components/macro'

import { TabBodyContainer } from '../Show'
import { InfoTable, parseCompletedDate } from '../../common'

const tableData = data => {
  let [passed, failed] = partition(data, t => String(t.testResult).match(/PASS/i))

  // If passed MOT, can simply pull out any comments as advisory comments:
  passed = passed.map(p => {
    const advisoryNotices = join(p.rfrAndComments.map(c => (get(c, 'text'))), '\n') || '--'
    const failureNotices = '--'

    return { ...p, advisoryNotices, failureNotices }
  })

  // Failing MOTs could have a mix of advisory comments and failure comments,
  // filter and return these separately:
  failed = failed.map(p => {
    const [failing, general] = partition(p.rfrAndComments, c => (
      String(c.type).match(/(FAIL|MAJOR|DANGEROUS)/i)
    ))
    const advisoryNotices = join(general.map(c => get(c, 'text')), '\n') || '--'
    const failureNotices = join(failing.map(c => get(c, 'text')), '\n') || '--'

    return { ...p, advisoryNotices, failureNotices }
  })

  return [...passed, ...failed].sort((a, b) => a.completedDate < b.completedDate)
}

export const MOT = () => {
  const { motTests } = useSelector(r => r.vehicles.show.data)

  const data = tableData(motTests).sort((a, b) => (
    new Date(b.completedDate) - new Date(a.completedDate)
  ))

  return (
    <TabBodyContainer>
      <InfoTable
        attributes={filterLocationAttribute(data)}
        data={data}
      />
    </TabBodyContainer>
  )
}

const Result = styled.span`
  &::before {
    content: "";
    display: inline-block;
    width: .75rem;
    height: .75rem;
    border-radius: 100%;
    vertical-align: middle;
    margin-right: 0.5rem;

    ${({ passed }) => passed ? `background: #53B761` : `background: #E75A66`}
  }
`

const testResult = tr => (
  <Result passed={tr.match(/PASS/i)}>
    {capitalize(tr)}
  </Result>
)
const mileage = m => (
  m ? `${parseInt(m).toLocaleString()} miles` : '-'
)

const filterLocationAttribute = (data) => {
  // if there is at least one 'location' field display the location column otherwise hide it
  if (data.some(el => el.location !== undefined)) return ATTRIBUTES
  return omit(ATTRIBUTES, ['location'])
}

const location = (n) => {
  if (n === undefined) {
    return ''
  }
  const addressLines = n.split(',')
  if (addressLines.length >= 2) {
    // put city and post code on the same line to save space
    const postCode = addressLines.pop()
    const city = addressLines.pop()
    addressLines.push(city + ", " + postCode)
  } else {
    return <Notice></Notice>
  }

  return addressLines.map((nn, key) => {
    return <Notice key={key}>{nn}</Notice>
  })
}

const separate = n => (
  n.split('\n').map((nn, i) => (
    <Notice key={i}>{nn}</Notice>
  ))
)

const Notice = styled.div`
  margin-bottom: 0.2rem;
`

const ATTRIBUTES = {
  completedDate: { name: 'Date', format: parseCompletedDate },
  testResult: { name: 'Test Result', format: testResult },
  motTestNumber: { name: 'Test Number' },
  odometerValue: { name: 'Mileage', format: mileage },
  advisoryNotices: { name: 'Advisory Notices', format: separate },
  failureNotices: { name: 'Failure Notices', format: separate },
  location: { name: 'MOT Location', format: location }
}
