import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { startCase } from 'lodash'

import Api from '../api/call'
import { ORGANISATIONS_UPDATE } from '../root/action-types'
import { Toggle } from '../common'

const FLAGS = [
  "MotPass",
  "MotFail",
  "MotExpired",
  "TaxExpired",
  "DriverPenaltyPoints",
  "DriverDisqualified"
]

const Flags = styled.div`
  display: flex;
  flex-wrap: wrap;

  .toggle {
    width: 50%;
    padding-bottom: 1rem;
  }
`

export const Container = styled.div`
  h2 {
    font-size: 0.9rem;
  }
`
const Notifications = () => {
  const { organisationId, organisation } = useSelector(r => r.users.current)

  const update = (data) => {
    Api({
      request: ORGANISATIONS_UPDATE,
      id: organisationId,
      data
    })
  }

  return (
    <Container>
      <h2>Global notification preferences</h2>
      <Flags>
        {FLAGS.map(f => (
          <Toggle
            key={f}
            defaultChecked={!!organisation[`notify${f}`]}
            label={startCase(f)}
            onChange={() => update({ [`notify_${f}`]: !organisation[`notify${f}`] })}
          />
        ))}
      </Flags>
    </Container>
  )
}

export default Notifications
