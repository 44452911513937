import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'

import GlobalSettings from './GlobalSettings'
import Account from './Account'
import Billing from './Billing'
import NewUser from './NewUser'
import UsersTable from './UsersTable'

const Settings = () => {
  const location = useLocation()
  const { hash } = location

  const tabs = {
    "": 0,
    "#billing": 2,
  }

  return (
    <Tabs defaultIndex={tabs[hash]}>
      <TabsWrapper>
        <TabListWrapper>
          <h1>Settings & Administration</h1>
          <TabList>
            <Tab>Account</Tab>
            <Tab>User Management</Tab>
            <Tab>Billing</Tab>
            <Tab>Global Settings</Tab>
          </TabList>
        </TabListWrapper>

        <TabPanelWrapper>
          <TabPanel>
            <Account />
          </TabPanel>
          <TabPanel>
            <NewUser />
            <UsersTable />
          </TabPanel>
          <TabPanel>
            <Billing />
          </TabPanel>
          <TabPanel>
            <GlobalSettings />
          </TabPanel>
        </TabPanelWrapper>
      </TabsWrapper>
    </Tabs>
  )
}

const TabsWrapper = styled.div`
  max-width: 1750px;
  @media screen and (min-width: 1225px) {
    display: flex;
  }
`

const TabListWrapper = styled.div`
  flex: 0 0 300px;
  height: 100%;
  box-shadow: 0px 3px 6px #00000008;

  @media screen and (min-width: 1225px) {
    margin-right: 2rem;
    padding-bottom: 4rem;
    background: white;
  }

  > h1 {
    color: ${p => p.theme.primaryColor};
    font-size: 1.2rem;
    font-weight: normal;
    margin: 1rem;

    @media screen and (max-width: 1225px) {
      display: none;
    }
  }

  ul {
    width: 100%;

    li {
      @media screen and (min-width: 1225px) {
        box-sizing: border-box;
        height: unset;
        width: 100%;
        line-height: unset;
        text-align: left;
        padding: .75rem 1rem;
        border-top: 1px solid #00000015 !important;
        background: white;
        border: none;
        font-size: .9rem;
        transition: all .3s ease-in-out;
      }

      &[class$="--selected"] {
        background: ${p => p.theme.primaryColor};
        color: white;
      }
    }
  }
`

const TabPanelWrapper = styled.div`
  box-shadow: 0px 3px 6px #00000008;
  flex: 2;

  > div {
    height: 100%;
    padding: 0 !important;
  }

  .panel {
    > h1 {
      font-size: 1.2rem;
      font-weight: normal;
    }
    > h2, h3 {
      font-size: 0.9rem;
      font-weight: bold;
    }
    h2.sub {
      padding-top: 1rem;
    }
    > h1, h2 {
      color: black;
      margin-top: 0;

      &::after {
        content: '';
        display: block;
        width: calc(100% + 5rem);
        margin-left: -2.5rem;
        height: 1px;
        background: #00000015;
        margin-top: 1rem;
      }
    }
  }
`

export const SettingsPanel = styled.div`
  padding: 1rem 2.5rem;
  min-height: 100vh;
  height: 100%;

  h1 {
    color: black;
    font-weight: normal;
    margin-top: 0;
    font-size: 1.2rem;
    &::after {
      content: '';
      display: block;
      width: calc(100% + 5rem);
      margin-left: -2.5rem;
      height: 1px;
      background: #00000015;
      margin-top: 1rem;
    }
  }

  h2 {
    font-size: .9rem;
    margin: 0 0 .5rem 0;
  }
`

export default Settings
